<template>
  <div>
    <div
      class="grid grid-cols-5 xl:grid-cols-3 gap-4 xl:gap-x-6 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8"
    >
      <!-- Seção dos campos formulário -->
      <div class="col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5">
        <section
          class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron"
        >
          <h1 class="mb-6 text-lg font-semibold">Informações de usuário</h1>

          <form
            @submit.prevent="createProfile"
            class="grid grid-cols-2 gap-x-4 gap-y-8 text-base font-normal"
          >
            <span class="col-span-2 p-float-label">
              <InputText
                v-model.trim="profileForm.name"
                type="text"
                id="name"
                class="w-full px-3 py-2 rounded-full border border-iron"
              />
              <label for="name">Nome</label>
            </span>
            <span class="col-span-2 p-float-label">
              <InputText
                v-model.trim="profileForm.email"
                type="email"
                id="email"
                class="w-full px-3 py-2 rounded-full border border-iron"
              />
              <label for="email">Email</label>
            </span>
            <span class="col-span-2 lg:col-span-1 w-full p-float-label">
              <InputNumber
                :modelValue="profileForm.phone1"
                :useGrouping="false"
                inputId="phone1"
                :unstyled="true"
                inputClass="w-full px-3 py-2 rounded-full outline-none border border-iron"
              />
              <label for="phone1">Telefone 1</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <InputNumber
                :modelValue="profileForm.phone2"
                :useGrouping="false"
                inputId="phone2"
                :unstyled="true"
                inputClass="w-full flex px-3 py-2 rounded-full outline-none border border-iron"
              />
              <label for="phone2">Telefone 2 <small>(opcional)</small></label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <Password
                v-model="profileForm.password"
                id="password"
                :unstyled="true"
                inputClass="w-full px-3 py-2 rounded-full outline-none border border-iron"
              />
              <label for="password">Nova Senha</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <Password
                v-model="profileForm.confirmPassword"
                :unstyled="true"
                id="confirm-password"
                inputClass="w-full px-3 py-2 rounded-full outline-none border border-iron"
              />
              <label for="confirm-password">Confirmar Nova Senha</label>
            </span>
            <span class="col-span-2 p-float-label">
              <TextArea
                v-model.trim="profileForm.aboutCompany"
                id="description"
                class="w-full px-3 py-2 rounded-xl outline-nonel border border-iron"
              />
              <label for="description">Descrição da empresa</label>
            </span>
            <span class="col-span-2">
              <Button
                type="submit"
                label="Salvar"
                class="py-2 px-4 float-right text-white rounded-md bg-sea-serpent"
              />
            </span>
          </form>
        </section>
      </div>

      <!-- Barra lateral direita -->
      <aside
        class="col-span-3 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
      >
        <Panel
          header="Publicar"
          :collapsed="false"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0 relative">
            <div class="flex flex-wrap gap-y-3 justify-between items-center mb-4">
              <Button
                type="text"
                label="Salvar rascunho"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
              />
              <Button
                type="text"
                label="Preview"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
              />
            </div>
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex items-center gap-2">
                <i class="pi pi-folder"></i>
                <p>Estado:</p>
                <strong>Rascunho</strong>
              </li>
              <li class="flex items-center gap-2">
                <i :class="`pi ${jobStatus == 1 ? 'pi-eye' : 'pi-eye-slash'}`"></i>
                <p>Visibilidade:</p>
                <strong>Público</strong>
              </li>
              <li class="flex items-center gap-2">
                <i class="pi pi-calendar"></i>
                <p>Publicar:</p>
                <strong>imediatamente</strong>
              </li>
            </ul>
          </div>
          <template #footer>
            <div class="flex justify-between items-center pt-3 border-t border-iron">
              <p class="text-red-800 cursor-pointer underline">Mover para lixeira</p>
              <Button
                type="submit"
                label="Publicar"
                class="py-1 px-3 text-white rounded-md bg-sea-serpent"
              />
            </div>
          </template>
        </Panel>
        <Panel
          header="Foto de Capa"
          :collapsed="true"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4">
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  chooseLabel="Enviar arquivo"
                  :maxFileSize="1000000"
                  @upload="onUpload"
                  class="bg-sea-serpent"
                />
              </li>
            </ul>
          </div>
        </Panel>
        <Panel
          header="Foto de Perfil"
          :collapsed="true"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4">
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  chooseLabel="Enviar arquivo"
                  :maxFileSize="1000000"
                  showCancelButton
                  @upload="onUpload"
                  class="bg-sea-serpent"
                />
              </li>
            </ul>
          </div>
        </Panel>
      </aside>
      <!-- Fim barra lateral direita -->
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Panel from "primevue/panel";
import FileUpload from "primevue/fileupload";
import TextArea from "primevue/textarea";
import Password from "primevue/password";

// ############ Chamando componentes e composables ############

import useFetchData from "@/composables/fetchData.js";

// ############ Declaração de variáveis ############

let jobStatus = ref(1);
let profileForm = reactive({
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone1: null,
  phone2: null,
  aboutCompany: "",
});

// ############ Criação de Métodos/Funções ############

async function createProfile() {
  await useFetchData
    .post("/profissional/create", {
      name: profileForm.name,
      email: profileForm.email,
      password: profileForm.password,
    })
    .then((response) => {
      console.log("Passou: ", response);
    })
    .catch((error) => {
      console.log("erro: ", error);
    })
    .finally(async function () {
      //isFetching.value = false;
    });
}

const onUpload = () => {
  // call toast
};

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onMounted(() => {});
</script>
