import axios from 'axios'
axios.defaults.withCredentials = true;

const useFetchData = axios.create({
  baseURL: process.env.VUE_APP_API_URL, 
  headers: { 
    "Access-Control-Allow-Origin": "http://localhost:8080",
    "Content-Type": "application/json",
    "accept": "application/json",
  }
});

export default useFetchData;