<template>
  <div class="card flex justify-center">
    <Toast position="top-center" group="headless" @close="visible = false">
      <template #container="{ message, closeCallback }">
        <section
          class="flex p-3 gap-3 w-full bg-gray-800 shadow-md"
          style="border-radius: 10px"
        >
          <i class="pi pi-cloud-upload text-blue-500 text-2xl"></i>
          <div class="flex flex-col gap-3 w-full">
            <p class="m-0 font-semibold text-base text-white">
              {{ message.summary }}
            </p>
            <p class="m-0 text-base font-medium">{{ message.detail }}</p>
            <div class="flex flex-col gap-2">
              <ProgressBar
                :value="progress"
                :showValue="false"
                :style="{ height: '4px' }"
              ></ProgressBar>
              <label class="text-right text-xs text-white"
                >{{ progress }}% uploaded...</label
              >
            </div>
            <div class="flex gap-3 mb-3">
              <Button
                label="Another Upload?"
                text
                class="p-0"
                @click="closeCallback"
              ></Button>
              <Button
                label="Cancel"
                text
                class="text-white p-0"
                @click="closeCallback"
              ></Button>
            </div>
          </div>
        </section>
      </template>
    </Toast>
    <Button @click="show" label="View" />
  </div>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import ProgressBar from "primevue/progressbar";

let toast = useToast();

const visible = ref(false);
const progress = ref(0);
const interval = ref();

const show = () => {
  if (!visible.value) {
    toast.add({
      severity: "custom",
      summary: "Uploading your files.",
      group: "headless",
    });
    visible.value = true;
    progress.value = 0;

    if (interval.value) {
      clearInterval(interval.value);
    }

    interval.value = setInterval(() => {
      if (progress.value <= 100) {
        progress.value = progress.value + 20;
      }

      if (progress.value >= 100) {
        progress.value = 100;
        clearInterval(interval.value);
      }
    }, 1000);
  }
};

onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>

<style scoped></style>
