<template>
  <LoadingDialog :visible="loadingDialog" />

  <EmailVerificationComponent 
    v-if="emailVerification.show"
    :visibled="true" 
    :title="emailVerification.title" 
    :openCodeMenu="true"
    :email="user.email"
    :redirectOnSuccess="'/login'"
    :isClosable="true"
    :blockEmailEdition="true"
  />

  <ProfileChooseComponent
    v-if="profileChoose.show"
    :action="'register'"
    @profileChosen="handleProfileChosen"
  />

  <div class="px-5 xs:px-10 md:px-12">
    <section class="w-full lg:w-4/5 xl:w-2/3 mx-auto my-32 flex rounded-lg bg-white shadow-lg overflow-hidden">
      <div class="w-full md:w-[60%] xl:w-2/4 p-4 md:p-8">
        <h1 class="text-4xl font-bold text-center mt-5 mb-8 text-oxford-blue">
          {{ translate('create account', 'ucfirst') }}
        </h1>
        <div v-if="isFetching" class="text-xl my-4">{{ translate('processing', 'ucfirst') }}...</div>

        <form id="register-form" @submit.prevent="handleRegister">

          <div class="flex flex-col">
            <label for="name" class="pb-1 text-lg font-semibold">{{ translate('name', 'ucfirst') }}</label>
            <InputText
              v-model="user.name"
              :placeholder="translate('your user name', 'ucfirst')"
              id="name"
              class="w-full py-2 px-3 rounded-lg border border-color-iron"
            />
          </div>

          <div class="flex flex-col mt-5">
            <label for="email" class="pb-1 text-lg font-semibold">{{ translate('email', 'ucfirst') }}</label>
            <InputText
              v-model="user.email"
              :placeholder="translate('your email', 'ucfirst')"
              id="email"
              required
              class="w-full py-2 px-3 rounded-lg border border-color-iron"
            />
          </div>

          <div class="flex flex-col mt-5">
            <label for="password" class="pb-1 text-lg font-semibold">{{ translate('password', 'ucfirst') }}</label>
            <Password
              v-model="user.password"
              :placeholder="translate('password', 'ucfirst')"
              toggleMask
              id="password"
              :promptLabel="translate('enter your password', 'ucfirst')"
              :weakLabel="translate('weak password', 'ucfirst')"
              :mediumLabel="translate('medium complexity', 'ucfirst')"
              :strongLabel="translate('strong password', 'ucfirst')"
              required
              inputClass="w-full py-2 px-3 rounded-lg border border-color-iron"
            >
              <template #footer>
                <Divider />
                <p class="mt-2">{{ translate('suggestions', 'ucfirst') }}</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>{{ translate('at least a lowercase letter', 'ucfirst') }}</li>
                  <li>{{ translate('at least an uppercase letter', 'ucfirst') }}</li>
                  <li>{{ translate('at least a number', 'ucfirst') }}</li>
                  <li>{{ translate('at least a special character', 'ucfirst') }}</li>
                  <li>{{ translate('minimum of 6 characters', 'ucfirst') }}</li>
                </ul>
              </template>
            </Password>
          </div>

          <div class="flex flex-col mt-5">
            <label for="confirm-pass" class="pb-1 text-lg font-semibold">{{ translate('confirm password', 'ucfirst') }}</label>
            <Password
              v-model="user.confirm_password"
              :placeholder="translate('enter your password again', 'ucfirst')"
              toggleMask
              id="confirm-pass"
              :promptLabel="translate('both passwords must be the same!', 'ucfirst')"
              required
              :weakLabel="translate('weak password', 'ucfirst')"
              :mediumLabel="translate('medium complexity', 'ucfirst')"
              :strongLabel="translate('strong password', 'ucfirst')"
              inputClass="w-full py-2 px-3 rounded-lg border border-color-iron"
            />
          </div>

          <div class="py-3 text-center">
            <Button 
              :label="translate('generate password', 'ucfirst')"
              class="py-2 px-3 rounded-lg border border-color-iron"
              icon="pi pi-key" 
              iconPos="right"
              @click="generateUserPassword()"
              :disabled="isLoading"
            />
          </div>

          <div class="py-3">
            <span class="flex gap-2 text-[15px]">
              <Checkbox v-model="user.accept" :binary="user.accept"  @click.prevent="user.accept = !user.accept ? true : false" class="border border-slate-200" />
              <label>{{ translate('i agree i read and accept the', 'ucfirst') }}
                <RouterLink to="/terms" class="font-medium text-sea-serpent">{{ translate('terms and conditions', 'ucfirst') }}</RouterLink>
                {{ translate('of') }} {{ env('APP_NAME') }}.
              </label>
            </span>
          </div>

          <div v-if="error" class="alert alert-danger" style="margin-bottom: 10px">{{ error }}</div>
          <div v-if="success" class="alert alert-success" style="margin-bottom: 10px">{{ success }}</div>

          <Button
            :loading="isLoading"
            type="submit"
            :label="translate('create account', 'ucfirst')"
            class="w-full py-2 px-5 text-white rounded-lg bg-sea-serpent"
          />
          
        </form>

        <Divider class="w-full" align="center" type="solid" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
          <b>{{ translate('or', 'upper') }}</b>
        </Divider>
        <div class="flex justify-center gap-4" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
          <Button :loading="isLoading">
            <span
              class="flex justify-center items-center rounded-full bg-sea-serpent w-9 h-9"
            >
              <i class="pi pi-google" style="font-size: 1.3rem; color: #fff"></i>
            </span>
          </Button>
          <Button :loading="isLoading">
            <span
              class="flex justify-center items-center rounded-full bg-sea-serpent w-9 h-9"
            >
              <i class="pi pi-facebook" style="font-size: 1.3rem; color: #fff"></i>
            </span>
          </Button>
          <Button :loading="isLoading">
            <span
              class="flex justify-center items-center rounded-full bg-sea-serpent w-9 h-9"
            >
              <i class="pi pi-instagram" style="font-size: 1.3rem; color: #fff"></i>
            </span>
          </Button>
        </div>
        <p class="text-center my-5">
          {{ translate('already a member?', 'ucfirst') }}
          <RouterLink to="/login" class="font-medium text-sea-serpent"
            >{{ translate('login here', 'ucfirst') }}</RouterLink
          >
        </p>
      </div>
      <div class="hidden md:flex md:w-2/5 xl:w-2/4 box-register-right-side"></div>
    </section>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { RouterLink } from "vue-router";
import store from '@/store';
import requestHandler from '@/composables/requestHandler';
import authUser from "@/composables/authUser";
import EmailVerificationComponent from "@/components/EmailVerificationComponent.vue";
import { generatePassword } from "@/composables/passwordGenerator";
import { translate } from "@/composables/translate";
import { env } from "@/composables/config";
import { sleep } from "@/composables/sleep";
import Cookies from "js-cookie";
import ProfileChooseComponent from "@/components/ProfileChooseComponent";
import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
import { showToast } from "@/composables/toast";
import { useToast } from "primevue/usetoast";

// ############ Declaração de variáveis ############
let toast = useToast();
let isFetching = ref(false);
let isLoading = ref(false);
let loadingDialog = ref(false);

const user = reactive({
  name: "",
  email: "",
  password: "",
  confirm_password: "",
  accept: false,
});

const emailVerification = reactive({
  show: false,
  title: translate('account created, an email verification code was sent to confirm your email', 'ucfirst')
})

const error = ref(null);
const success = ref(null);

const profileChoose = reactive({
  show: false,
  profileName: null
});

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############
  onMounted(function(){
    if(store.getters.isLoggedIn || Cookies.get('aut_data')){
      requestHandler.redirect('/login');
    }
  });

  async function handleRegister() {
    error.value = null;
    success.value = null;

    if (!user.name || !user.email || !user.password || !user.confirm_password) {
      error.value = translate('all fields are required.');
      return;
    }

    if(!authUser.checkEmail(user.email)){
      error.value = translate('email is invalid', 'ucfirst');
      return;
    }

    let passwordCheck = authUser.checkPassword(user.password);
    if(!passwordCheck.success){
      error.value = passwordCheck.message;
      return;
    }

    if (user.password !== user.confirm_password) {
      error.value = translate('passwords doesn\'t match.', 'ucfirst');
      return;
    }
    
    if (!user.accept) {
      error.value = translate("you must accept the terms and conditions.", 'ucfirst');
      return;
    }
    isLoading.value = true;
    if(profileChoose.profileName === null){
      profileChoose.show = true;
      return;
    }

    loadingDialog.value = true;
    const response = await authUser.register({
      person_username: user.name,
      person_email: user.email,
      person_password: user.password,
      profile_type: profileChoose.profileName
    });
    isLoading.value = false;
    loadingDialog.value = false;
    if(!response.success){
      showToast(toast, translate('a problem occurred, try again later', 'ucfirst'), 'error');
      error.value = response.message;
      return;
    }
    showToast(toast, translate('account created', 'ucfirst'));
    // To call email confirmation send and show modal
    // if(response.success){
    //   await requestHandler.executeRequest('api/requestemailconfirmationcode', {email: user.email});
    //   success.value = response.message;
    //   loading.finished = true;
    //   emailVerification.show = true;
    // }else{
    //   loading.finished = true;
    //   error.value = response.message;
    // }

    success.value = translate('you are going to be redirected in a moment!', 'ucfirst');
    await sleep(3000);
    requestHandler.redirect('/login');
  }

  function generateUserPassword(){
    const newPass = generatePassword(12);
    user.password = newPass;
    user.confirm_password = newPass;
  }

  function handleProfileChosen(profile){
    profileChoose.profileName = profile.profileName.api;
    profileChoose.show = false;
    handleRegister();
  }

</script>

<style scoped>
.box-register-right-side {
  background-image: url("@/assets/images/img-register.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.alert {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
}
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}
.alert-success {
  background-color: #d4edda;
  color: #155724;
}
</style>
