import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const seniorities = {
    async listJobSeniority(category = 'seniority') {
        try {
            let jobSeniorityCombo = store.getters.getOnComboChest(`seniority_${category}`);
            if(jobSeniorityCombo !== null && jobSeniorityCombo !== undefined && !utils.isEmpty(jobSeniorityCombo))
                return jobSeniorityCombo;
            const result = await requestHandler.executeRequest('api/proficiency/index', {category: category}, 'get');
            store.dispatch('saveOnComboChest', {
                key: `seniority_${category}`,
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default seniorities;