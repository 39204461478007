class Translation{
    defaults() {
        return {
            llangue_id: '',
            llangue_name: '',
            llangue_acronyn: '',
            en: '',
            pt: '',
            es: '',
            unofficial_translations: null,
            targetLanguageIsoCode: 'en'
        }
    }

    constructor(data){
        this.llangue_id = data.llangue_id;
        this.llangue_name = data.llangue_name;
        this.llangue_acronyn = data.llangue_acronyn;
        this.en = data.en;
        this.pt = data.pt;
        this.es = data.es;
        this.unofficial_translations = data.unofficial_translations;
    }


    tryToGetUnnoficialTranslation(langIso = ''){
        let unoficialTranslations = JSON.parse(this.unofficial_translations);
        if(unoficialTranslations == null || unoficialTranslations[langIso] == undefined)
            return this.en;
        return unoficialTranslations[langIso];
    }

    setTargetLanguageIso(langIso){
        switch(langIso){
            case 'en':
                this.targetLanguageIsoCode = this.en;
            break;
            case 'pt':
                this.targetLanguageIsoCode = this.pt;
            break;
            case 'es':
                this.targetLanguageIsoCode = this.es;
            break;
            default:
                this.targetLanguageIsoCode = this.tryToGetUnnoficialTranslation(langIso);
            break;
        }
    }

    getObject() {
        return this;
    }
}
export default Translation;