<template>
  <Dialog 
    v-model:visible="showContactInformationDialog" 
    modal 
    :closable="true"
    :header="`${translate('send your curriculum or contact throught one of the following', 'ucfirst')}:`"
    :style="{ width: '35rem' }"
    class="ml-2 me-2"
  >
    <p v-if="contact.contactName" class="mb-2">
      <strong>{{ translate('contact name', 'ucfirst') }}:</strong> 
      <i class="ms-2 pi pi-tag"></i>
      {{ contact.contactName }}
    </p>
    <p v-if="contact.contactEmail" class="mb-2">
      <strong>{{ translate('email', 'ucfirst') }}:</strong> 
      <a :href="`mailto: ${contact.contactEmail}`" target="_blank" class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800"> 
        <i class="ms-2 pi pi-envelope"></i> {{ contact.contactEmail }}
      </a>
    </p>
    <p v-if="contact.contactPhone" class="mb-2">
      <strong>{{ translate('contact number', 'ucfirst') }}:</strong> 
      <a :href="`tel: ${contact.contactPhone}`" target="_blank" class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800"> 
        <i class="ms-2 pi pi-phone"></i> {{ contact.phoneDDI }} {{ contact.contactPhone }}
      </a>
    </p>
    <p v-if="contact.contactWebSite" class="mb-2">
      <strong>{{ translate('contact website', 'ucfirst') }}:</strong> 
      <a :href="contact.contactWebSite" target="_blank" class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800"> 
        <i class="ms-2 pi pi-link"></i> {{ contact.contactWebSite }}
      </a>
    </p>
  </Dialog>

  <LoaderComponent v-if="isLoading" />
  <div v-if="!isLoading && paginationData.total < 1">
    <h1 class="font-semibold">{{ translate('no applications found', 'ucfirst') }}!</h1>
    <p>
      <RouterLink to="/jobs" class="underline font-semibold text-oxford-blue" >
        {{ translate('click here to search for a job', 'ucfirst') }}
      </RouterLink>
    </p>
  </div>
  <section v-if="!isLoading && paginationData.total > 0" class="grid grid-cols-1 md:grid-cols-4 gap-5">
    <div class="col-span-1 md:col-span-4 flex justify-between items-center mb-1" v-if="props.seeMoreLink">
      <h1 class="font-semibold">{{ translate('recent posted', 'ucfirst') }}</h1>
      <RouterLink :to="`/dashboard/professional/${store.getters.getUserSlug}/jobs-application`" class="underline font-semibold text-oxford-blue">
        {{ translate('see more', 'ucfirst') }}
      </RouterLink>
    </div>
    <div
      class="col-span-1 md:col-span-4 py-3 bg-white rounded-md shadow-md overflow-hidden"
    >
      <h1 class="text-2xl font-bold px-5 text-oxford-blue cursor-pointer mb-5">{{ translate('my appliances', 'ucfirst') }}</h1>
      <DataTable :value="appliances" class="w-full">
        <Column :header="translate('job title', 'ucfirst')">
          <template #body="slotProps">
            <RouterLink 
              v-tooltip.top="translate('see job', 'ucfirst')" 
              placeholder="Top"
              :to="`/job/${slotProps.data.job_id}`" 
              class="underline font-semibold text-oxford-blue cursor-pointer"
            >
              {{ ucfirst(slotProps.data.job_title) }}
              <i class="pi pi-external-link"></i>
            </RouterLink>
          </template>
        </Column>
        <Column field="company_name" :header="translate('company', 'ucfirst')"></Column>
        <Column field="countryName" :header="translate('address', 'ucfirst')"> </Column>
        <Column field="applicationDate" :header="translate('date', 'ucfirst')"></Column>
        <Column :header="translate('status', 'ucfirst')" v-if="1 > 1">
          <template #body="slotProps">
            <Tag
              :value="slotProps.data.statusTranslation"
              :severity="getSeverity(slotProps.data.status)"
            />
          </template>
        </Column>
        <Column v-if="props.displayButtons" class="flex justify-between">
          <template #body="slotProps">
            <Button class="text-white rounded-lg py-2 bg-sea-serpent px-2" @click.prevent="showContactInformation(slotProps.data.applied_id)">
              <i class="pi pi-address-book text-lg me-2"></i> {{ translate('contact', 'ucfirst') }}
            </Button>
          </template>
        </Column>
        <template #footer> {{ translate('a total of', 'ucfirst') }} {{ appliances.length }} {{ translate('appliances') }}. </template>
      </DataTable>
      <Paginator 
        v-if="showPagination"
        :rows="paginationData.per_page" 
        :totalRecords="paginationData.total"
        @page="changePage"
        class="c-paginator"
      />
    </div>
  </section>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import { useStore } from "vuex";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import { onMounted, ref } from "vue";
import { showToast } from "@/composables/toast";
import { useToast } from "primevue/usetoast";
import utils from "@/composables/utils";
import { translate } from "@/composables/translate";
import professionalData from "@/composables/professionalData";
import { ucfirst } from "@/composables/textUtils";
import Dialog from "primevue/dialog";
import Paginator from "primevue/paginator";

const props = defineProps({
  displayButtons: {
    type: Boolean,
    required: false,
    default: false
  },
  limit: {
    type: String,
    required: false,
    default: ''
  },
  seeMoreLink: {
    type: Boolean,
    required: false,
    default: false
  }
});

const toast    = useToast();
const store    = useStore();
// let slug       = store.getters.getUserSlug;
// let userType   = store.getters.getLoggedAs
let isLoading  = ref();
let appliances = ref([]);
let showPagination = ref(false);

const getSeverity = (applianceStatus) => {
  switch (applianceStatus) {
    case "accepted":
      return "success";
    case "validating":
      return "warning";
    case "applied":
      return "secondary";
    case "refused":
      return "warning";
    default:
      return null;
  }
};

onMounted(function(){
  getMyAppliances();
})

async function getMyAppliances(){
  isLoading.value = true;
  let appliancesData = [];
  let myAppliances = store.getters.getOnDataChest('my_appliances');
  myAppliances = [];
  if(myAppliances !== null && myAppliances !== undefined && !utils.isEmpty(myAppliances)){
    appliancesData = myAppliances;
    isLoading.value = false;
  }else{
    const response = await professionalData.getMyAppliances();
    isLoading.value = false;
    if(!response.success){
      showToast(toast, response.message, 'error');
      return;
    }
    appliancesData = response.data.data;
    store.dispatch('saveOnDataChest', {
      key: 'my_appliances',
      value: appliancesData
    });
    for(let appliance of appliancesData){
      store.dispatch('saveOnDataChest', {
        key: `save_appliance_${appliance.applied_id}`,
        value: appliance
      });
    }
  }
  if(props.limit){
    let numericLimit = props.limit == '' ? 0 : parseInt(props.limit);
    let filteredAppliances = [];
    for(let i = 0; i < numericLimit; i++){
      if(appliancesData[i] == undefined)
        break;
      filteredAppliances.push(appliancesData[i]);
    }
    appliancesData = filteredAppliances;
  }
  showPagination = true;
  paginateResults(appliancesData);
}

let paginationData = ref({
  total: 0,
  per_page: 10,
  currPage: 0,
  lastPage: 0,
  results: [],
  perPage: {},
  search: {}
})
function paginateResults(appliancesData){
  paginationData.value.total = appliancesData.length; 
  let page = 0;
  let marker = 0;
  let thisPage = [];
  let filterData = {};
  let thisJobTitle = '';
  for(let index = 0; index < paginationData.value.total; index++){
    if(appliancesData[index] != undefined){
      thisJobTitle = appliancesData[index]['job_title'].toLowerCase();
      if(filterData[thisJobTitle] == undefined)
        filterData[thisJobTitle] = [];
      filterData[thisJobTitle].push(appliancesData[index]);
    }
    thisPage.push(appliancesData[index]);
    marker++;
    if(marker == paginationData.value.per_page){
      paginationData.value.perPage[page] = thisPage;
      marker = 0;
      paginationData.value.lastPage = page;
      page++;
      thisPage = [];
    }
  }
  if(paginationData.value.total < paginationData.value.per_page){
    paginationData.value.perPage[0] = thisPage;
    paginationData.value.lastPage = 0;
  }
  paginationData.value.search = filterData;
  changePage(0, true);
}

function changePage(page = null, firstLoad = false){
  page = page.page != undefined ? page.page : page;
  if(page == paginationData.value.currPage && firstLoad == false) 
    return;
  if(page < 0)
    page = 0;
  if(page > paginationData.value.lastPage)
    page = paginationData.value.lastPage;
  if(paginationData.value.perPage[page] != undefined){
    paginationData.value.results = paginationData.value.perPage[page];
    paginationData.value.currPage = page;
  }else{
    paginationData.value.results = paginationData.value.perPage[paginationData.value.lastPage];
    paginationData.value.currPage = paginationData.value.lastPage;
  }
  appliances.value = paginationData.value.results;
}

const showContactInformationDialog = ref(false);
let contact = {
  contactName: null,
  contactEmail: null,
  contactPhone: null,
  contactWebSite: null
};

async function showContactInformation(applianceId){
  let cachedData = store.getters.getOnDataChest(`save_appliance_${applianceId}`);
  if(cachedData !== null && cachedData !== undefined && !utils.isEmpty(cachedData)){
    contact.contactName = cachedData.contact_name;
    contact.contactEmail = cachedData.contact_email;
    contact.contactPhone = cachedData.contact_phone;
    contact.contactWebSite = cachedData.contact_website;
    showContactInformationDialog.value = true;
  }else{
    showToast(toast, translate('no contact information found', 'ucfirst'), 'error');
    showContactInformationDialog.value = false;
  }
}

</script>

<style scoped></style>
