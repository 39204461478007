<template>
  <div>
    <div
      class="grid grid-cols-5 xl:grid-cols-3 gap-4 xl:gap-x-6 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8"
    >
      <!-- Seção dos campos formulário -->
      <div class="col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5">
        <section
          class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron"
        >
          <h1 class="mb-6 text-lg font-semibold">Informações da vaga</h1>

          <form
            class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-6 text-base font-medium"
          >
            <span class="col-span-1 md:col-span-2 p-float-label">
              <InputText
                v-model.trim="jobForm.title"
                type="text"
                id="text"
                class="w-full px-3 py-2 rounded-full border border-iron"
                aria-describedby="text-error"
              />
              <label for="value">Titulo da vaga</label>
            </span>
            <span class="col-span-1 p-float-label">
              <Dropdown
                v-model="jobForm.jobType"
                :options="jobtypes"
                optionLabel="name"
                placeholder="Seleciona um Tipo"
                class="w-full rounded-full border border-iron"
              />
              <label for="value">Tipo da vaga</label>
            </span>

            <span class="p-float-label">
              <InputText
                v-model.trim="jobForm.location"
                type="text"
                id="location"
                class="w-full px-3 py-2 rounded-full border border-iron"
              />
              <label for="location">Localização</label>
            </span>
            <span class="p-float-label">
              <InputNumber
                v-model.trim="jobForm.minSalary"
                inputId="MinSalary"
                mode="currency"
                currency="EUR"
                :min="0"
                placeholder="Escolha"
                inputClass="w-full px-3 py-2 rounded-full border border-iron"
              />
              <label for="MaxSalary">Salário Mínimo</label>
            </span>
            <span class="p-float-label">
              <InputNumber
                v-model.trim="jobForm.maxSalary"
                inputId="MaxSalary"
                mode="currency"
                currency="EUR"
                inputClass="w-full px-3 py-2 rounded-full border border-iron"
              />
              <label for="MaxSalary">Salário Máximo</label>
            </span>
            <span class="col-span-1 md:col-span-3 p-float-label">
              <TextArea
                v-model.trim="jobForm.jobOffer"
                id="offer"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
              <label for="offer">Oferta</label>
            </span>
            <span class="col-span-1 md:col-span-3 p-float-label">
              <TextArea
                v-model.trim="jobForm.requirements"
                id="requirement"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
              <label for="requirement">Requisitos</label>
            </span>
            <span class="col-span-1 md:col-span-3 w-full p-float-label">
              <TextArea
                v-model.trim="jobForm.jobFunction"
                id="function"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
              <label for="function">Funções</label>
            </span>
          </form>
        </section>

        <!-- Seção informações vaga -->
        <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-5">
          <section class="w-full p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-4 text-lg font-semibold">Nível de Senioridade</h1>

            <MultiSelect
              v-model="jobForm.seniority"
              :options="jobtypes"
              optionLabel="name"
              display="chip"
              placeholder="Selecionar Nível"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />
          </section>
          <!-- Seçção -->
          <section class="w-full p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-4 text-lg font-semibold">Idiomas</h1>
            <MultiSelect
              v-model="jobForm.languages"
              :options="jobtypes"
              optionLabel="name"
              display="chip"
              placeholder="Selecionar Idiomas"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />
          </section>
          <!-- Seçção -->
          <section class="w-full p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-4 text-lg font-semibold">Vistos</h1>
            <MultiSelect
              v-model="jobForm.languages"
              :options="jobtypes"
              optionLabel="name"
              display="chip"
              placeholder="Selecionar os Vistos"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />
          </section>
        </div>
      </div>

      <!-- Barra lateral direita -->
      <aside
        class="col-span-3 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
      >
        <Panel
          header="Publicar"
          :collapsed="false"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0 relative">
            <div class="flex flex-wrap gap-y-3 justify-between items-center mb-4">
              <Button
                type="text"
                label="Salvar rascunho"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
              />
              <Button
                type="text"
                label="Preview"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
              />
            </div>
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex items-center gap-2">
                <i class="pi pi-folder"></i>
                <p>Estado:</p>
                <strong>Rascunho</strong>
              </li>
              <li class="flex items-center gap-2">
                <i :class="`pi ${jobStatus == 1 ? 'pi-eye' : 'pi-eye-slash'}`"></i>
                <p>Visibilidade:</p>
                <strong>Público</strong>
              </li>
              <li class="flex items-center gap-2">
                <i class="pi pi-calendar"></i>
                <p>Publicar:</p>
                <strong>imediatamente</strong>
              </li>
            </ul>
          </div>
          <template #footer>
            <div class="flex justify-between items-center pt-3 border-t border-iron">
              <p class="text-red-800 cursor-pointer underline">Mover para lixeira</p>
              <Button
                type="submit"
                label="Publicar"
                class="py-1 px-3 text-white rounded-md bg-sea-serpent"
              />
            </div>
          </template>
        </Panel>
        <Panel
          header="Foto de Capa"
          :collapsed="true"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4">
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  chooseLabel="Enviar arquivo"
                  :maxFileSize="1000000"
                  @upload="onUpload"
                  class="bg-sea-serpent"
                />
              </li>
            </ul>
          </div>
        </Panel>
        <Panel
          header="Foto de Perfil"
          :collapsed="true"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4">
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  chooseLabel="Enviar arquivo"
                  :maxFileSize="1000000"
                  showCancelButton
                  @upload="onUpload"
                  class="bg-sea-serpent"
                />
              </li>
            </ul>
          </div>
        </Panel>
      </aside>
      <!-- Fim barra lateral direita -->
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Panel from "primevue/panel";
import FileUpload from "primevue/fileupload";
import TextArea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";

import { useToast } from "primevue/usetoast";

// ############ Declaração de variáveis ############

let toast = useToast();

let jobtypes = ref([
  { name: "Full Time" },
  { name: "Part Time" },
  { name: "Remoto" },
  { name: "Treinamento" },
]);

let jobStatus = 1;
let jobForm = ref({
  title: "",
  jobFunction: "",
  offer: "",
  requirements: "",
  minSalary: 0,
  maxSalary: 0,
  location: "",
  languages: [],
  seniority: "",
  jobType: "",
});

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############
const onUpload = () => {
  toast.add({
    severity: "info",
    summary: "Success",
    detail: "File Uploaded",
    life: 3000,
  });
};

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onMounted(() => {});
</script>
