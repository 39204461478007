<template>
  <div>
    <div class="flex flex-col pt-9 pb-16 px-5 xs:px-8">
      <!-- Seção -->
      <section
        class="col-span-1 md:col-span-4 flex justify-between items-center p-10 shadow-md rounded-md bg-white"
      >
        <div class="w-full flex flex-col">
          <span class="flex items-center gap-x-5">
            <h1 class="text-3xl font-semibold text-oxford-blue">
              Engenheiro de Software
            </h1>
            <Badge
              value="Ativo"
              severity="success"
              class="text-sm h-auto 5uppercase"
            ></Badge>
          </span>

          <span class="flex items-center text-sm font-normal mt-2 mb-3"
            ><i class="pi pi-clock mr-2" style="font-size: 1.1rem"></i> Publicado há 2
            semanas</span
          >

          <div class="flex gap-x-5 text-[0.958rem] font-normal">
            <h6 class="flex items-center gap-x-2">
              <i class="pi pi-map-marker" style="font-size: 1.2rem"></i>
              Brasília, Brasil
            </h6>
            <h6 class="flex items-center gap-x-2">
              <i class="pi pi-briefcase" style="font-size: 1.2rem"></i>
              Full Time
            </h6>
            <h6 class="flex items-center gap-x-2">
              <i class="pi pi-briefcase" style="font-size: 1.2rem"></i>
              2 Anos Experiência
            </h6>
          </div>
        </div>

        <span>
          <InputGroup
            class="w-full md:w-72 flex items-center pl-1.5 py-0 bg-white rounded-full border border-r-0 border-iron"
          >
            <InputGroupAddon>
              <i class="pi pi-search"></i>
            </InputGroupAddon>
            <InputText
              :unstyled="true"
              v-model.trim="job.title"
              type="text"
              id="text"
              placeholder="Faça sua busca"
              class="w-full px-2 py-1 outline-none"
            />
            <Button
              :unstyled="true"
              label="Buscar"
              class="py-1.5 px-4 font-semibold text-white rounded-r-full bg-sea-serpent"
            />
          </InputGroup>
        </span>
      </section>

      <!-- Seção -->
      <section class="px-4 mt-4 pb-14 bg-white shadow-md rounded-md">
        <TabView>
          <TabPanel>
            <template #header>
              <div class="flex align-items-center gap-2">
                <span class="font-bold">Candidatos</span>
                <Badge value="12.034" class="bg-oxford-blue"></Badge>
              </div>
            </template>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-5 mt-5">
              <JobApplicants :data="dataTable" />
            </div>
          </TabPanel>
          <TabPanel header="Descriçao Vaga">
            <!-- Aba descrição vaga -->
            <div class="w-full pb-14 mt-5">
              <!-- Conteúdo descrição job -->
              <div class="px-6">
                <h3 class="my-3 font-semibold text-oxford-blue">Oferta:</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ratione
                  delectus perspiciatis suscipit amet pariatur necessitatibus aperiam
                  reprehenderit odio vel sunt, repellat aspernatur debitis dolorum
                  repellendus fugit aliquid error non voluptas?
                </p>

                <h3 class="my-3 font-semibold text-oxford-blue">Funções:</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ratione
                  delectus perspiciatis suscipit amet pariatur necessitatibus aperiam
                  reprehenderit odio vel sunt, repellat aspernatur debitis dolorum
                  repellendus fugit aliquid error non voluptas?
                </p>

                <h3 class="my-3 font-semibold text-oxford-blue">Requisitos:</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ratione
                  delectus perspiciatis suscipit amet pariatur necessitatibus aperiam
                  reprehenderit odio vel sunt, repellat aspernatur debitis dolorum
                  repellendus fugit aliquid error non voluptas?
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Reviews">
            <p class="m-0">
              At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
              praesentium voluptatum deleniti atque corrupti quos dolores et quas
              molestias excepturi sint occaecati cupiditate non provident, similique sunt
              in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
              fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero
              tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
          </TabPanel>
        </TabView>
      </section>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Badge from "primevue/badge";
import JobApplicants from "@/components/Dashboard/Company/JobApplicants.vue";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

const job = ref({
  title: "",
});
job;
const dataTable = ref();
dataTable.value = [
  {
    name: "Ravana Silva",
    hability: "Director Esportivo",
    slug: "director-esportivo",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "RJ, Brasil",
    inventoryStatus: "ABERTO",
  },
  {
    name: "Carlos de Oliveira",
    hability: "Engenheiro BDA",
    slug: "engenheiro-bda",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "Porto, Portugal",
    inventoryStatus: "Aberto",
  },
  {
    name: "Lucas Pedro",
    hability: "Engenheiro de Software Backend PHP",
    slug: "engenheiro-de-software-backend-php",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "Madrid, Espanhã",
    inventoryStatus: "Fechado",
  },
  {
    name: "Valter Cristovação",
    hability: "Cozinheiro",
    slug: "cozinheirop",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "Orlando, USA",
    inventoryStatus: "Pendente",
  },
];

onMounted(() => {});
</script>
