<template>
  <div>
    <section class="grid grid-cols-12 gap-5">
      <div class="col-span-12 flex justify-between items-center mb-1">
        <h1 class="font-semibold">Candidatos Recomendados</h1>
        <RouterLink :to="`/applicants`" class="underline font-semibold text-oxford-blue"
          >Ver mais</RouterLink
        >
      </div>

      <Carousel
        :value="applicants"
        :numVisible="1"
        :numScroll="1"
        :autoplayInterval="3000"
        :showNavigators="true"
        :responsiveOptions="responsiveOptions"
        class="col-span-12"
      >
        <template #item="slotProps">
          <div class="p-3">
            <Card class="shadow-lg">
              <template #content>
                <div class="flex flex-col items-center text-center pb-3">
                  <!-- Foto de perfil -->
                  <div
                    class="w-24 h-24 rounded-full mb-3 bg-slate-200 border border-iron profile-photo"
                  ></div>

                  <h1 class="text-lg font-bold text-oxford-blue">
                    {{ slotProps.data.name }}
                  </h1>
                  <p class="text-sm font-semibold mb-1">
                    {{ slotProps.data.profission }}
                  </p>
                  <span class="flex items-center gap-x-2">
                    <i class="pi pi-map-marker text-sm"></i>
                    <p class="text-[0.85rem] font-medium">
                      {{ slotProps.data.location }}
                    </p>
                  </span>

                  <RouterLink
                    :to="`/applicants`"
                    class="w-full mt-4 -mb-6 pt-1.5 font-semibold border-t border-iron"
                  >
                    Ver Perfil</RouterLink
                  >
                </div>
              </template>
            </Card>
          </div>
        </template>
      </Carousel>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { RouterLink } from "vue-router";
import Card from "primevue/card";
import Carousel from "primevue/carousel";

let applicants = ref([
  {
    name: "Ravana Silva",
    profission: "Engenheiro de Software",
    location: "São Paulo, Brasil",
  },
  { name: "Lucas Martinele", profission: "Analista DBA", location: "Barcelona, Espanhã" },
  {
    name: "António francisco",
    profission: "Chefe de Cozinha",
    location: "Angola, Benguela",
  },
  {
    name: "Artur Vasco",
    profission: "Engenheiro Mecânico",
    location: "São Paulo, Brasil",
  },
  { name: "Ana Cristina", profission: "Desginer Motion", location: "Algarve, Portugal" },
  {
    name: "Marcos Augusto",
    profission: "Desenvolvedor React Native",
    location: "Rio de Janeiro, Brasil",
  },
  {
    name: "Jão Pedro",
    profission: "Engenheiro de Software",
    location: "Brasília, Brasil",
  },
]);

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "999px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);
</script>

<style scoped></style>
