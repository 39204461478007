import store from "@/store";
import requestHandler from "./requestHandler";
import utils from "./utils";

export default {
    async getMyAppliances(company_id = null, appliance_status = null) {
        let params = {};
        if(company_id != null)
            params['company_id'] = company_id;
        if(appliance_status != null)
            params['appliance_status'] = appliance_status; 
        return requestHandler.executeRequest("api/job_applied/listappliances", params, 'get');
    },
    
    async applyToJob(job_id = null) {
        let params = {
            job_id: job_id
        };
        return requestHandler.executeRequest("api/job_applied/applyforvacancy", params, 'post');
    },

    async updateMyAppliances(applianceId, applianceData = []) {
        let currentAppliances = store.getters.getOnDataChest('my_appliances');
        currentAppliances = currentAppliances !== null && currentAppliances !== undefined && !utils.isEmpty(currentAppliances) ? currentAppliances : [];
        let updatedAppliances = [];
        let found = false;
        for (let appliance of currentAppliances) {
            if (appliance.applied_id == applianceId) {
                updatedAppliances.push(applianceData);
                found = true;
            } else {
                updatedAppliances.push(appliance);
            }
        }
        if (!found)
            updatedAppliances.push(applianceData);
        store.dispatch('saveOnDataChest', {
            key: 'my_appliances',
            value: updatedAppliances
        });
    },
}