import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const jobModality = {
    async listJobModalities() {
        try {
            let jobModalityCombo = store.getters.getOnComboChest('job_modality');
            if(jobModalityCombo !== null && jobModalityCombo !== undefined && !utils.isEmpty(jobModalityCombo))
                return jobModalityCombo;
            const result = await requestHandler.executeRequest('api/job_modality/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'job_modality',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default jobModality;