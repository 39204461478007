<template>
    <ConfirmationModal 
      :title="confirmationModal.title"
      :text="confirmationModal.text"
      :visible="confirmationModal.visible"
      @emitResponse="handleConfirmationModal"
    />

    <LoadingDialog :visible="actionIsBeingExecuted" />
    
    <aside
      class="col-span-5 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
    > 
        <Panel
          :collapsed="false"
          toggleable
          class="shadow-md border border-iron pannel-wrapper"
        >
          <div class="m-0 relative">
            <div class="flex flex-wrap gap-y-3 justify-between items-center mb-4">
              <Button
                type="text"
                :label="props.actionButtonText"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent w-full md:w-1/3"
                @click.prevent="emitClick"
                v-if="jobStatus != 'hidden'"
              />
            </div>
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex items-center gap-2">
                <i class="pi pi-folder"></i>
                <p>{{ translate('status', 'ucfirst') }}:</p>
                <strong>{{ avaliableJobStatus[jobStatus] }}</strong>
              </li>
              <li class="flex items-center gap-2">
                <i :class="`pi ${jobStatus == 'published' ? 'pi-eye' : 'pi-eye-slash'}`"></i>
                <p>{{ translate('visibility', 'ucfirst') }}:</p>
                <strong>{{ jobStatus == 'published' ? translate('public', 'ucfirst') : translate('hidden', 'ucfirst') }}</strong>
              </li>
              <li class="flex items-center gap-2">
                <i class="pi pi-calendar"></i>
                <p>{{ translate('published', 'ucfirst') }}:</p>
                <strong>{{ job != null ? job.posted_at : translate('not published', 'ucfirst')}}</strong>
              </li>
            </ul>
            <div class="w-full text-right" v-if="1 != 1">
              <Button
                v-if="canPublish()"
                @click="confirmationModal.visible = true"
                :label="translate('publish', 'ucfirst')"
                class="py-1 px-3 text-white rounded-md bg-sea-serpent w-full md:w-1/3 mt-5"
                :style="isScreenSmall() ? {'margin-bottom': '2em'} : ''" 
              />
            </div>
          </div>
        </Panel>
    </aside>
</template>

<script setup>
  import Panel from "primevue/panel";
  import { translate } from "@/composables/translate";
  import Button from "primevue/button";
  import { onMounted, onUnmounted, reactive, ref } from "vue";
  import store from "@/store";
  import { watch } from "vue";
  import utils from "@/composables/utils";
  import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
  import ConfirmationModal from "@/components/Utils/ConfirmationModal.vue";
  import { showToast } from "@/composables/toast";
  import { useToast } from "primevue/usetoast";
  import jobData from "@/composables/jobData";

  const props = defineProps({
    jobId: {
      type: String,
      required: true,
      default: null
    },
    jobStatus: {
      type: String,
      required: false,
      default: 'draft'
    },
    displayButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    actionButtonText: {
      type: String,
      required: false,
      default: translate('save', 'ucfirst')
    }
  });

  const emit = defineEmits({
    click: null,
    emitClick: () => {
      return true;
    },
    // emitPublished: () => {
    //   return true;
    // }
  })

  const emitClick = () => {
    emit('emitClick');
  };
  // const emitPublished = () => {
  //   emit('emitPublished');
  // };

  let jobId = ref(null);
  let jobStatus = ref('');
  let toast = useToast();
  const windowWidth = ref(window.innerWidth);
  const windowHeight = ref(window.innerHeight);
  const breakPoint = 768;
  onMounted(function(){
    jobId.value = props.jobId;
    jobStatus.value = props.jobStatus;
    handlePanel();
    if(props.jobId != null)
      updateJobStatus();
    window.addEventListener('resize', handleResize);
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  })

  watch(() => jobId, (newId) => {
    jobId.value = newId;
    updateJobStatus();
  });

  function updateJobStatus(){
    let jobData = store.getters.getOnDataChest(`save_job_${jobId.value}`);
    if(jobData !== null && jobData !== undefined && !utils.isEmpty(jobData)){
      jobStatus.value = jobData.job_status;
    }else{
      jobStatus.value = 'draft';
    }
  }

  function handlePanel(){
    let panelEl = document.querySelector('.pannel-wrapper');
    if(panelEl != null){
      let headerEl = panelEl.querySelector('.p-panel-header');
      if(headerEl != null && !headerEl.classList.contains('flex')){
        document.querySelector('.pannel-wrapper').children[0].classList.add('flex');
        document.querySelector('.pannel-wrapper').children[0].classList.add('justify-end');
      }
    }
  }

  let avaliableJobStatus = {
    draft: translate('draft', 'ucfirst'),
    published: translate('published', 'ucfirst'),
    validating: translate('validating', 'ucfirst'),
    hidden: translate('hidden', 'ucfirst')
  };

  function handleResize(){
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  }
  function isScreenSmall(){
    return windowWidth.value > breakPoint ? false : true;
  }

  function canPublish(){
    if(!['published','hidden'].includes(jobStatus.value))
      return true;
    return false;
  }

  let actionIsBeingExecuted = ref(false);
  let confirmationModal = reactive({
    visible: false,
    title: translate('are you sure you want to publish this job?', 'ucfirst'),
    text: translate('if yes, this job will be sent to our validation trials before being fully released to professionals', 'ucfirst')
  })

  async function handleConfirmationModal(confirmation){
    confirmationModal.visible = false;
    if(confirmation.confirmation){
      actionIsBeingExecuted.value = true;
      await postJob();
    }
    actionIsBeingExecuted.value = false;
}

  async function postJob() {
    const response = await jobData.postJob(jobId.value);
    if(!response.success){
      showToast(toast, response.message, 'error')
      return;
    }
    showToast(toast, response.message);
    store.dispatch('saveOnDataChest', {
      key: `save_job_${jobId.value}`,
      value: response.data.data
    });
    jobStatus.value = response.data.data.job_status;
    await jobData.updateMyCompanyJobs(jobId.value, response.data.data);
  }
</script>