<template>
    <div>
      <section
        style="z-index: 10000000000;"
        class="fixed w-full md:max-w-6xl p-4 mx-auto shadow-lg bg-white border border-iron dark:bg-gray-800 md:right-12 bottom-16 dark:border-gray-700 rounded-2xl"
      >
        <h2 class="font-semibold text-gray-800 dark:text-white">
          🍪
          {{
            user_language == "en"
              ? "Cookies Notice"
              : user_language == "es"
              ? "Aviso del Cookies"
              : "Aviso de Cookies"
          }}
        </h2>
  
        <div v-for="(cookie, index) in cookies.data" :key="index">
          <p
            v-if="cookie.language == user_language"
            class="mt-4 text-sm text-gray-600 dark:text-gray-300"
          >
            {{ cookie.warn1 }}
          </p>
          <p
            v-if="cookie.language == user_language"
            class="mt-2 text-sm text-gray-600 dark:text-gray-300"
          >
            {{ cookie.warn2 }}
            <button
              @click="visibityDialog = true"
              type="button"
              class="text-blue-500 hover:underline"
            >
              {{
                user_language == "en"
                  ? "Read cookies policies"
                  : user_language == "es"
                  ? "Lee la política del Cookies"
                  : "Leia as políticas de Cookies"
              }}</button
            >.
          </p>
          <p
            v-if="cookie.language == user_language"
            class="mt-4 text-sm text-gray-600 dark:text-gray-300"
          >
            {{ cookie.warn3 }}
          </p>
        </div>
  
        <div class="flex items-center justify-end mt-4 gap-x-4 shrink-0">
          <button
            class="hidden text-xs text-gray-800 underline transition-colors duration-300 dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            Manage your preferences
          </button>
  
          <span>
            <button
              @click="cookiesAccepted(false)"
              class="text-xs mr-4 bg-oxford-blue font-medium rounded-lg hover:bg-blue-900 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
            >
              {{
                user_language == "en"
                  ? "Reject"
                  : user_language == "es"
                  ? "Rechazar"
                  : "Rejeitar"
              }}
            </button>
  
            <button
              @click="cookiesAccepted(true)"
              class="text-xs bg-sea-serpent font-medium rounded-lg hover:bg-green-600 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
            >
              {{
                user_language == "en"
                  ? "Accept"
                  : user_language == "es"
                  ? "Aceptar"
                  : "Aceitar"
              }}
            </button>
          </span>
        </div>
      </section>
  
      <Dialog
        v-model:visible="visibityDialog"
        modal
        header="Cookies"
        class="w-11/12 lg:w-2/3"
        :breakpoints="{ '1199px': '50vw', '575px': '50vw' }"
      >
        <div v-for="(cookie, index) in cookies.data" :key="'co-' + index">
          <div v-if="cookie.language == user_language">
            <span v-for="(content, index) in cookie.content" :key="'cont-' + index">
              <h3 class="text-lg font-semibold mb-3">{{ content.title }}</h3>
              <p class="mb-6">{{ content.description }}</p>
            </span>
          </div>
        </div>
      </Dialog>
    </div>
  </template>
  <script setup>
  import { ref, computed } from "vue";
  import Dialog from "primevue/dialog";
  import cookies from "@/static-content/cookies-policy.json";
  import store from "@/store";
  
  let user_language = computed(() => store.state.user_lang);
  
  const visibityDialog = ref(false);
  const emit = defineEmits(["accept-cookie"]);
  
  function cookiesAccepted(value) {
    emit("accept-cookie", value);
  }
  </script>