<script setup>
import Toast from "primevue/toast";
import { ref } from "vue";
import { RouterView } from "vue-router";

const appKey = ref(0);
function rebuildApplication() {
  appKey.value += 1;
}
</script>

<template>
  <div>
    <Toast position="top-right" style="z-index: 10000" />
    <RouterView @rebuildApplication="rebuildApplication" :appKey="appKey"> </RouterView>
  </div>
</template>
