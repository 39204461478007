<template>
    <!-- eslint-disable-next-line -->
    <!-- eslint-disable  -->
    <div class="flex flex-col items-center text-center pb-3 text-oxford-blue bold text-lg" v-if="professional != null">
        <div
            class="w-24 h-24 rounded-full mb-3 bg-slate-200 border border-iron profile-photo"
            :style="{ backgroundImage: `url(${professional.professional_photo})`}"
        ></div>
        <h1 class="font-bold text-xl">{{ professional.professional_fullname}}</h1>
        <Rating
            v-if="professional.rating != undefined"
            v-model="professional.rating"
            class="mx-auto mt-2.5"
            readonly
            :cancel="false"
        />
        <p v-if="professional.professional_title" class="mt-5 pt-5 mb-1">
            {{ professional.professional_title }}
        </p>
        <div v-if="professional.profession" class="flex mt-3 mb-1 w-full flex-wrap">
            <h2 class="font-bold">{{ translate('profession', 'ucfirst') }}:</h2>
            <p>
                <i class="pi pi-graduation-cap ml-2 mr-1"></i>{{ professional.profession }}
            </p>
        </div>
        <div v-if="professional.professional_email" class="flex mt-3 mb-1 w-full flex-wrap">
            <h2 class="font-bold">{{ translate('email', 'ucfirst') }}:</h2>
            <p>
                <a 
                    class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800" 
                    target="_blank" 
                    :href="`mailto:${professional.professional_email}`"
                >
                    <i class="pi pi-envelope ml-2 mr-1"></i>{{ professional.professional_email }}
                </a>
            </p>
        </div>
        <div v-if="professional.professional_phone" class="flex mt-3 mb-1 w-full flex-wrap">
            <h2 class="font-bold">{{ translate('phone', 'ucfirst') }}:</h2>
            <p>
                <a 
                    class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800" 
                    target="_blank" 
                    :href="`tel:${professional.professional_phone}`"
                >
                    <i class="pi pi-phone ml-2 mr-1"></i>{{ professional.professional_phone }}
                </a>
            </p>
        </div>
        <div v-if="professional.dpgender" class="flex mt-3 mb-1 w-full flex-wrap">
            <h2 class="font-bold">{{ translate('gender', 'ucfirst') }}:</h2>
            <p>
                <i :class="getGenderIcon(professional.dpgender) + ' ml-2 mr-1'"></i>{{ professional.gender }}
            </p>
        </div>
        <p class="flex items-center mt-3 mb-1 w-full">
            <h2 class="font-bold">{{ translate('currently working', 'ucfirst') }}:</h2>
            <p class="ml-2 mr-1">
                {{ getBinaryResponse(professional.currently_working, 'ucfirst') }}
            </p>
        </p>
        <p class="flex items-center mt-3 mb-1 w-full">
            <h2 class="font-bold">{{ translate('avaliable to travel', 'ucfirst') }}:</h2>
            <p class="ml-2 mr-1">
                {{ getBinaryResponse(professional.avaliable_to_travel, 'ucfirst') }}
            </p>
        </p>
        <div v-if="professional.full_location" class="flex mt-3 mb-1 w-full flex-wrap">
            <h2 class="font-bold">{{ translate('location', 'ucfirst') }}:</h2>
            <p>
                <a 
                    class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800" 
                    target="_blank" 
                    :href="`https://www.google.com/maps/search/${encodeURIComponent(professional.full_location)}`"
                >
                    <i class="pi pi-map-marker ml-2 mr-1"></i>{{ professional.full_location }}
                </a>
            </p>
        </div>

        <Button 
            v-if="[undefined, null, 0].includes(env('APP_BETA'))"
            class="mt-5 text-white rounded-lg py-2 bg-sea-serpent px-2 w-full md:w-3/4" 
            :icon="'pi pi-file-export'" 
            :label="translate('export curriculum', 'ucfirst')" 
        />
    </div>
</template>

<script setup>
    import { onMounted, watch, ref } from "vue";
    import store from "@/store";
    import { translate } from "@/composables/translate";
    import Rating from 'primevue/rating';
    import Button from "primevue/button"
    import { env } from "@/composables/config";

    let props = defineProps({
        professional_id: {
            type: Number,
            required: true,
            default: 0
        }
    })

    let professionalId = ref();
    let professional = ref(null);
    onMounted(() => {
        professionalId.value = props.professional_id;
        getProfessional();
    })

    watch(() => props.professional_id, (newId) => {
        professionalId.value = newId;
        getProfessional();
        },
        { deep: true }
    )

    function getProfessional(){
        if(['',null,undefined,0].includes(professionalId.value)){
            professional = null;
            return;
        }
        professional.value = store.getters.getOnDataChest(`professional_${professionalId.value}`);
    }

    function getGenderIcon(gender = ''){
        let iconClass = '';
        switch(gender){
            case 1:
                iconClass = 'pi pi-mars';
            break;
            case 2:
                iconClass = 'pi pi-venus';
            break;
            case 3:
                '';
            break;
        }
        return iconClass;
    }

    function getBinaryResponse(value = 0, textTransform = ''){
        return value ? translate('yes', textTransform) : translate('no', textTransform);
    }

</script>