<template>
  <div
    class="container mx-auto grid grid-cols-1 md:grid-cols-4 px-5 pt-20 2xl:pt-24 pb-32"
  >
    <!-- barra lateral esquerda -->
    <SidebarTerms />

    <section
    class="col-span-4 md:col-span-3 flex flex-col content-start bg-white shadow rounded-md p-8 mb-30 col-description">

    <div v-for="(about, index) in aboutUs.data" :key="index" class="mb-4">
      <span v-if="about.language == user_language">
        <h1 class="base-title pb-2"> 
          {{ about.main_title }} 
        </h1>
        <p class="py-4 font-medium text-oxford-blue">{{ about.welcome }}</p>
        <p>{{ about.about }}</p>
      </span>
    </div>

    <div v-for="(about, index) in aboutUs.data" :key="index" class="mb-5 md:mb-10">
      <article v-if="about.language == user_language">
        <div v-for="(content, index) in about.content" :key="index" class="mb-8">
          <h1 class="font-semibold text-xl text-oxford-blue pb-2">{{ content.title }}</h1>
          <p>{{ content.description }}</p> 
        </div>
      </article>
    </div>
  </section>
    <!-- /.col-span-3 -->
  </div>
</template>

<script setup>
import { computed } from "vue";
import SidebarTerms from "@/components/SidebarTermsComponent";
import aboutUs from "@/static-content/about-us.json";
import store from "@/store";


let user_language = computed(() => store.state.user_lang)
</script>

<style scoped>
.router-link-exact-active {
  color: #53c3c4;
}
</style>
