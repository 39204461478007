<template>
  <div>
    <header
      class="w-full flex flex-wrap md:flex-nowrap px-5 pt-16 lg:px-14 lg:pl-14 lg:pr-20 lg:pt-20 xl:pt-24"
    >
      <div class="text-center md:text-left order-2 md:order-none md:mt-10 lg:mt-12">
        <h1
          class="w-full lg:w-3/4 xl:w-11/12 2xl:w-2/3 text-4xl md:text-6xl font-bold leading-tight text-oxford-blue"
        >
            {{ translate('connecting professionals to', 'ucfirst') }} <br class="hidden md:flex" />
            <span class="text-sea-serpent">
            {{ translate('international companies') }}
            </span
          >
        </h1>
        <p class="w-11/12 md:w-5/6 mt-6 mb-8">
          {{ translate('at Jobifull, you can find the global talent your company needs. Register your job opportunity and hire qualified professionals available to work in your country', 'ucfirst') }}.
        </p>
        <form
          v-if="[undefined, null, 0].includes(env('APP_BETA'))"
          @submit.prevent="searchOffers"
          class="flex flex-col md:flex-row justify-between md:bg-white md:shadow-xl rounded-full gap-y-4 md:gap-0 lg:mr-10 md:pl-4"
        >
          <InputGroup
            class="w-full shadow-md rounded-full overflow-hidden md:shadow-none md:rounded-none"
          >
            <InputGroupAddon>
              <i class="pi pi-search"></i>
            </InputGroupAddon>
            <InputText
              v-model="search.title"
              :placeholder="translate('name of the job', 'ucfirst')"
              class="w-full py-3 px-3 outline-none"
            />
          </InputGroup>
          <InputGroup
            class="w-full shadow-md rounded-full overflow-hidden md:shadow-none md:rounded-none"
          >
            <InputGroupAddon>
              <i class="pi pi-map"></i>
            </InputGroupAddon>
            <InputText
              v-model="search.location"
              :placeholder="translate('location', 'ucfirst')"
              class="w-full py-3 px-3 outline-none"
            />
          </InputGroup>
          <Button
            type="submit"
            :label="translate('search', 'ucfirst')"
            class="py-3 mt-3 md:mt-0 md:py-1 md:pl-4 md:ml-2 md:pr-12 text-white shadow-lg rounded-full md:rounded-none md:rounded-r-full bg-sea-serpent"
          />
        </form>
        <div v-else>
          <RouterLink :to="'/jobs'">
            <Button 
              :label="translate('find a job', 'ucfirst')"
              class="py-3 mt-3 md:w-3/4 w-full text-white shadow-lg rounded-full bg-sea-serpent text-xl uppercase"
            />
          </RouterLink>
        </div>
      </div>

      <div
        class="w-full order-1 md:order-none my-10 xl:my-0 md:flex lg:w-3/4 xl:w-[60rem]"
      >
        <img
          src="../../assets/images/foto-home-hero.png"
          alt="home hero jobifull"
          class="w-full md:h-5/6 lg:w-[95%] lg:h-[95%]"
        />
      </div>
    </header>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { translate } from "@/composables/translate";
import { env } from "@/composables/config";
import { RouterLink } from "vue-router";

// ############ Declaração de variáveis ############
const search = reactive({
  title: "",
  location: "",
});

// ############ Criação de Métodos/Funções ############

function searchOffers() {}

//############ Propriedades Computadas ############

// ############ Ciclos de Vida/Hooks ############
</script>
