<template>
    <Dialog 
        v-model:visible="visible" 
        modal 
        :closable="false"
        :header="props.title" 
        :style="{ width: '25rem' }"
        class="ml-2 me-2"
    >
        <span class="p-text-secondary block mb-5">{{ props.text }}.</span>
        <div class="flex justify-end gap-2">
            <Button 
                v-if="props.displayCancelButton" 
                type="button" 
                :label="props.cancelButtonText" 
                severity="secondary" 
                @click="emitResponse()"
                class="text-gray-900 py-2.5 px-5 me-2 mb-2 bg-white border border-gray-200"
            />
            <Button 
                type="button" 
                severity="success" 
                :label="props.confirmationButtonText" 
                @click="emitResponse(true)"
                class="text-white py-2.5 px-5 me-2 mb-2 bg-sea-serpent"
            />
        </div>
    </Dialog>
</template>

<script setup>
    import { onMounted, watch, ref }  from "vue";
    import { translate } from "@/composables/translate";
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";

    const props = defineProps({
        title: {
            type: String,
            required: true,
            default: '...'
        },
        text: {
            type: String,
            required: false,
            default: ''
        },
        visible: {
            type: Boolean,
            required: false,
            default: true
        },
        displayCancelButton: {
            type: Boolean,
            required: false,
            default: true
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: translate('cancel', 'ucfirst')
        },
        confirmationButtonText: {
            type: String,
            required: false,
            default: translate('confirm', 'ucfirst')
        }
    });

    let visible = ref();
    onMounted(function(){
        visible.value = props.visible;
    });

    watch(() => props.visible, (newValue) => {
        visible.value = newValue;
        if(!visible.value)
            emitResponse(false);
    });

    const emit = defineEmits({
        click: null,
        emitResponse: ({ confirmation }) => {
        return confirmation;
        }
    })

    function emitResponse(confirmation = false){
        emit('emitResponse', { confirmation })
    }
</script>