// export default = {
//   APP_NAME: encrypte(process.env.VUE_APP_NAME),
//   API_URL: encrypte(process.env.VUE_APP_API_URL),
//   APP_RELEASE_DATE: encrypte(process.env.VUE_APP_RELEASE_DATE),
//   VERSION: encrypte(process.env.VUE_APP_VERSION),
//   CRYPTO_KEY: encrypte(process.env.VUE_APP_CRYPTO_KEY),
//   CRYPTO_IV: encrypte(process.env.VUE_APP_CRYPTO_IV),
//   CRYPTO_SALT: encrypte(process.env.VUE_APP_CRYPTO_HASH),
//   TRANSLATIONS_KEY: encrypte(process.env.VUE_APP_TRANSLATIONS_KEY),
//   APP_URL: encrypte(window.location.protocol + '//' + window.location.host + '/')
// };

export function env(confName = ''){
  confName = 'VUE_' + confName;
  if(process.env[confName] === undefined)
      return null;
  return process.env[confName];
}