<template>
  <div>
    <HomeHero />

    <!-- Principais empresas -->
    <LoaderComponent :class="'flex flex-col justify-center pt-12 lg:pt-5 base-section text-center'" v-if="companiesList.loading" />
    <section class="flex flex-col justify-center pt-12 lg:pt-5 base-section" v-if="!companiesList.hideSection && !companiesList.loading">
      <h1 class="base-title text-center mb-7 md:mb-12">
        {{ translate('top companies hiring', 'ucfirst') }}
      </h1>
      <div class="w-full md:px-10 lg:w-11/12 md:mx-auto">
        <Carousel
          :value="companiesList.data"
          :numVisible="5"
          :numScroll="4"
          :autoplayInterval="3000"
          :showNavigators="false"
          :responsiveOptions="responsiveCompaniesLogo"
        >
          <template #item="slotProps">
            <div class="w-40 h-24 px-4">
              <Image :src="slotProps.data.company_logo_img" alt="logo" class="w-full h-full" />
            </div>
          </template>
        </Carousel>
      </div>
    </section>

    <!-- Seção profissionais -->
    <section class="flex flex-col md:container md:mx-auto base-section" v-if="1 != 1">
      <div class="flex justify-between items-center mb-7 xs:px-6 lg:px-8">
        <span>
          <h1 class="base-title">
            {{ translate('explore a global', 'ucfirst') }} <br />
            <span class="text-sea-serpent">{{ translate('network of talents') }}</span>
          </h1>
          <p class="text-lg mt-2">
            {{ translate('more than', 'ucfirst') }} {{ 15.000 }} {{ translate('qualified professionals') }}<br class="flex md:hidden" />
          </p>
        </span>
        <span class="flex items-center">
          <RouterLink to="/applicants" class="underline font-medium mr-1.5"
            >{{ translate('see more', 'ucfirst') }}</RouterLink
          >
          <i class="pi pi-chevron-right"></i>
        </span>
      </div>

      <Carousel
        :value="jobs"
        :numVisible="5"
        :numScroll="2"
        :autoplayInterval="3000"
        :showNavigators="true"
        :responsiveOptions="responsiveOptionsProfissionals"
      >
        <template #item="slotProps">
          <div class="p-2.5 md">
            <Card class="w-full shadow-md text-center overflow-hidden">
              <template #header>
                <img
                  alt="user header"
                  src="@/assets/images/img-cover.png"
                  class="h-24 z-0"
                />
              </template>
              <template #title>
                <div
                  alt="user"
                  :style="{ backgroundImage: `url(@/assets/images/${photo})` }"
                  class="w-28 h-28 z-10 -mt-16 mx-auto rounded-full border-4 border-white profile-photo"
                ></div>

                <h1
                  class="mt-2.5 font-semibold text-lg xl:text-xl leading-6 text-oxford-blue"
                >
                  {{ slotProps.data.title }}
                </h1>
                <h1 class="capitalize font-medium text-sm">Analista de Dados</h1>
              </template>
              <template #footer>
                <div class="w-full pt-2 -mt-3 -mb-3 border-t border-color-iron">
                  <RouterLink
                    to="/applicants/dino-bonner"
                    class="font-semibold text-oxford-blue"
                    >{{ translate('see profile', 'capitalize') }}</RouterLink
                  >
                </div>
              </template>
            </Card>
          </div>
        </template>
      </Carousel>
    </section>

    <!-- Seção vagas recentes -->
    <div class="base-section text-center">
      <LoaderComponent v-if="loadingRecentJobs.loading" />
    </div>
    <section 
      class="bg-white py-12 lg:py-16 lg:px-16 base-section" 
      v-if="!loadingRecentJobs.loading && loadingRecentJobs.showSection && jobs.data.length >= 5"
    >
      <div class="flex justify-between items-center mb-7 xs:px-6 md:mb-14">
        <h1 class="base-title">{{ translate('recent jobs', 'ucfirst') }}</h1>
        <span class="flex items-center">
          <RouterLink to="/jobs" class="underline font-medium mr-1.5"
            >{{ translate('see more', 'ucfirst') }}</RouterLink
          >
          <i class="pi pi-chevron-right"></i>
        </span>
      </div>

      <Carousel
        :value="jobs.data"
        :numVisible="4"
        :numScroll="5"
        :autoplayInterval="8000"
        :showNavigators="true"
        :responsiveOptions="responsiveOptionsNewOffers"
      >
        <template #item="slotProps">
          <Card class="shadow-lg md:mb-3 md:mx-4 border border-color-iron">
            <template #content>
              <div class="flex gap-3">
                <!-- Foto de perfil/logo empresa -->
                <div
                  :style="{ backgroundImage: getBase64(slotProps.data.company_logo) }"
                  class="w-20 h-16 rounded-lg border border-color-iron profile-photo"
                ></div>

                <div>
                  <h2 class="text-base font-medium text-oxford-blue">
                    {{ ucfirst(limitText(slotProps.data.company_name)) }}
                  </h2>

                  <span class="flex items-center gap-x-2">
                    <i class="pi pi-clock"></i>
                    <p class="text-sm">{{ translate('at', 'ucfirst') }} {{ slotProps.data.posted_at }}</p>
                  </span>

                  <span class="flex items-center gap-x-2">
                    <i class="pi pi-map-marker"></i>
                    <p class="job-location-height">{{ slotProps.data.location }}</p>
                  </span>
                </div>
              </div>

              <h1 class="text-lg font-bold mt-2 mb-3 text-oxford-blue" style="height:60px!important;overflow-x:auto;max-height:350px;">
                <RouterLink to="/jobs">{{ ucfirst(limitText(slotProps.data.job_title)) }}</RouterLink>
              </h1>
              
              <div class="flex gap-x-4 mb-2">
                <span
                  class="flex items-center gap-x-2 px-3 rounded-xl border border-color-sea-serpent"
                >
                  <h6 class="font-medium text-sea-serpent">
                    {{ ucfirst(slotProps.data.job_modality) }}
                  </h6>
                </span>
                <span
                  class="flex items-center gap-x-2 px-3 rounded-xl border border-color-oxford-blue"
                >
                  <h6 class="font-medium text-oxford-blue">{{ slotProps.data.exp_required }}</h6>
                </span>
              </div>
            </template>

            <template #footer>
              <div
                class="flex justify-between items-center -mb-3 pt-3 gap-x-5 border-t border-color-iron flex-col"
              >
                <p class="font-semibold text-oxford-blue">
                  <i class="pi pi-money-bill mr-2"></i> {{ slotProps.data.salary }}
                </p>
                <Button
                  @click.prevent="requestHandler.redirect(`/job/${slotProps.data.job_id}`)"
                  :label="translate('apply', 'ucfirst')"
                  class="text-sea-serpent font-medium py-1 px-5 border border-color-sea-serpent mt-3"
                />
              </div>
            </template>
          </Card>
        </template>
      </Carousel>
    </section>

    <!-- Seção categorias -->
    <LoaderComponent :class="'md:container md:mx-auto md:px-12 md:py-4 xl:pt-0 base-section'" v-if="loadingJobsCategories.loading" />
    <section class="md:container md:mx-auto md:px-12 md:py-4 xl:pt-0 base-section" v-if="!loadingJobsCategories.loading && loadingJobsCategories.showSection">
      <h1 class="base-title text-center">
        <span class="text-sea-serpent">{{ translate('categories', 'ucfirst') }}</span>
      </h1>
      <p class="text-center text-lg font-normal mb-7 md:mb-14">
        {{ translate('find jobs by categories', 'ucfirst') }}
      </p>

      <div
        v-if="!loadingJobsCategories.loading"
        class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-9 lg:gap-y-8 lg:gap-x-10 xl:gap-6"
      >

        <article
          v-for="(category, index) in categories"
          :key="index"
          class="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden"
        >
          <img
            :src="category.img"
            alt="logo"
            class="w-full h-40 lg:w-40 lg:h-full transition-transform duration-700 hover:scale-105"
          />
          <div class="w-full font-medium pb-4 px-4 pt-7">
            <h3 class="mb-5 text-2xl text-oxford-blue">
              <RouterLink to="/jobs" v-tooltip.top="ucfirst(category.name)" type="text" placeholder="Top">{{ ucfirst(limitText(category.name, 30)) }}</RouterLink>
            </h3>
            <span class="flex items-center gap-x-3 text-[1.2rem]">
              <i class="pi pi-briefcase" style="font-size: 2rem"></i>
              <p>{{ category.qtd }} {{ translate('offers', 'ucfirst') }}</p>
            </span>
          </div>
        </article>
      </div>
    </section>

    <!-- Seção como se candidatar -->
    <section
      class="md:container md:mx-auto lg:px-32 grid grid-cols-1 md:grid-cols-2 items-center base-section md:mb-36"
    >
      <div class="h-full py-14 md:pr-8">
        <HomePhotoGetStarted class="w-full h-full lg:h-[30rem]" />
      </div>
      <div class="xl:px-14">
        <h1 class="base-title mb-4">{{ translate('how to apply to a job?', 'ucfirst') }}</h1>
        <p>{{ translate('the opportunity that you needed is just a step away', 'ucfirst') }}.</p>
        <div class="mt-8">
          <ul class="w-auto space-y-5">
            <li class="flex items-center gap-x-4 bg-white rounded-lg p-5 shadow-lg">
              <i class="pi pi-user" style="font-size: 2rem; color: #53c3c4"></i>
              <p class="text-xl font-medium text-oxford-blue">{{ translate('create your account', 'ucfirst') }}</p>
            </li>
            <li class="flex items-center gap-x-4 bg-white rounded-lg p-5 shadow-lg" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
              <i class="pi pi-book" style="font-size: 2rem; color: #53c3c4"></i>
              <p class="text-xl font-medium text-oxford-blue">
                {{ translate('create your curriculum or send your own', 'ucfirst') }}
              </p>
            </li>
            <li class="flex items-center gap-x-4 bg-white rounded-lg p-5 shadow-lg">
              <i class="pi pi-search" style="font-size: 2rem; color: #53c3c4"></i>
              <p class="text-xl font-medium text-oxford-blue">{{ translate('search for a job', 'ucfirst') }}</p>
            </li>
            <li class="flex items-center gap-x-4 bg-white rounded-lg p-5 shadow-lg">
              <i class="pi pi-briefcase" style="font-size: 2rem; color: #53c3c4"></i>
              <p class="text-xl font-medium text-oxford-blue">{{ translate('apply to a job', 'ucfirst') }}</p>
            </li>
          </ul>
          <Button
            type="submit"
            :label="translate('start now', 'upper')"
            @click="requestHandler.redirect('/register')"
            class="w-full py-4 px-4 mt-6 uppercase text-white rounded-full bg-sea-serpent"
          />
        </div>
      </div>
    </section>

    <!-- Seção baixar aplicativo -->
    <section
      v-if="[undefined, null, 0].includes(env('APP_BETA'))"
      class="w-full lg:w-3/4 py-16 rounded-r-2xl relative base-section overflow-hidden bg-sea-serpent"
    >
      <div class="md:pl-10 xl:pl-32 text-center md:text-left text-white">
        <h1 class="leading-14 text-2xl md:text-4xl font-medium">
          {{ translate('know it\'s easier', 'ucfirst') }} <br />{{ translate('to find your job or', 'ucfirst') }} <br />{{ translate('international worker', 'ucfirst') }}.
        </h1>
        <p class="text-lg mt-2 mb-9">
          {{ translate('Download our app for Android or Iphone', 'ucfirst') }} <br class="hidden md:flex" />
          {{ translate('and have the best experience', 'ucfirst') }}
        </p>
        <div class="flex justify-center md:justify-normal space-x-4">
          <a
            href="https://google.com"
            class="w-max flex items-center rounded-xl shadow-lg text-sm py-1.5 px-4 bg-oxford-blue"
          >
            <svg
              class="w-10 mr-2"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M14.94,5.19A4.38,4.38,0,0,0,16,2,4.44,4.44,0,0,0,13,3.52,4.17,4.17,0,0,0,12,6.61,3.69,3.69,0,0,0,14.94,5.19Zm2.52,7.44a4.51,4.51,0,0,1,2.16-3.81,4.66,4.66,0,0,0-3.66-2c-1.56-.16-3,.91-3.83.91s-2-.89-3.3-.87A4.92,4.92,0,0,0,4.69,9.39C2.93,12.45,4.24,17,6,19.47,6.8,20.68,7.8,22.05,9.12,22s1.75-.82,3.28-.82,2,.82,3.3.79,2.22-1.24,3.06-2.45a11,11,0,0,0,1.38-2.85A4.41,4.41,0,0,1,17.46,12.63Z"
              />
            </svg>
            <div class="leading-tight">
              <span class="block"> {{ translate('download at', 'ucfirst') }} </span>
              <strong class="font-medium">{{ translate('apple store', 'capitalize') }}</strong>
            </div>
          </a>

          <a
            href="https://google.com"
            class="w-max flex items-center rounded-xl shadow-lg text-sm py-1.5 px-4 border-2 accent-border-color"
          >
            <svg
              class="w-10 mr-2"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M20.91887,10.653c-.31445-.16992-3.01074-1.73438-4.02246-2.32324l-.00293-.002L4.6386,1.25262a1.679,1.679,0,0,0-1.40711-.1593c-.02618.0094-.05078.01886-.07623.03051a1.41835,1.41835,0,0,0-.17291.06922,1.51864,1.51864,0,0,0-.73828,1.36426V21.54266a1.43542,1.43542,0,0,0,.69238,1.2705,1.30761,1.30761,0,0,0,.15479.06373c.02893.01336.05651.025.08636.03552a1.37893,1.37893,0,0,0,.44635.0824,1.67263,1.67263,0,0,0,.83106-.23145c.38867-.22559,12.43847-7.18262,12.43847-7.18262L20.914,13.25945a1.52522,1.52522,0,0,0,.8418-1.334A1.49078,1.49078,0,0,0,20.91887,10.653ZM4.24407,19.839V4.10186l7.94012,7.85907Zm5.018-2.16168,4.34351-4.30957,1.14954,1.13782C13.49047,15.23583,11.35381,16.4696,9.26207,17.67736Zm4.3418-7.125L9.206,6.19933l5.55365,3.2063ZM16.55065,13.469l-1.52539-1.50977L16.553,10.44341c.72027.41828,1.84283,1.06934,2.6159,1.51429Z"
              />
            </svg>
            <div class="leading-tight">
              <span class="block"> {{ translate('download at', 'ucfirst') }} </span>
              <strong class="font-medium">{{ translate('google play', 'capitalize') }}</strong>
            </div>
          </a>
        </div>
        <img
          src="@/assets/images/Mockup-telefone-duplo.png"
          alt="Jobifull mobile app"
          class="w-[63%] h-[68%] hidden md:flex rotate-12 absolute -right-9 lg:-right-10 bottom-0 max-w-80"
        />
      </div>
    </section>

    <!-- Seção confiança -->
    <section class="bg-white py-12 lg:py-16 lg:px-32 base-section" v-if="!companiesList.hideSection">
      <h1
        class="mx-auto xs:w-4/5 md:w-3/4 2xl:w-2/4 mb-7 md:mb-12 base-title text-center"
      >
        {{ translate('more than', 'ucfirst') }} 
        <span class="text-sea-serpent">{{ 1.500 }} {{ translate('companies trust us') }}</span> 
        {{ translate('to find your talents') }}
      </h1>
      <LoaderComponent :class="'flex flex-col justify-center pt-12 lg:pt-5 base-section text-center'" v-if="companiesList.loading" />
      <div class="w-full md:px-10 lg:w-11/12 md:mx-auto" v-if="!companiesList.loading">
        <Carousel
          :value="companiesList.data"
          :numVisible="5"
          :numScroll="4"
          :autoplayInterval="3000"
          :showNavigators="false"
        >
          <template #item="slotProps">
            <div class="w-40 h-24 px-4">
              <Image :src="slotProps.data.company_logo_img" alt="Logotipo" class="w-full h-full" />
            </div>
          </template>
        </Carousel>
      </div>
    </section>

    <!-- Seção newsletter -->
    <section
      v-if="[undefined, null, 0].includes(env('APP_BETA'))"
      class="grid grid-cols-1 md:grid-cols-2 md:container md:mx-auto md:mb-36 lg:px-32 items-center base-section"
    >
      <div class="hidden md:flex">
        <img
          src="@/assets/images/foto-newsletter.png"
          alt="logo"
          class="w-full h-full lg:h-[35rem] m-auto"
          style="max-width: 439px!important; max-height: 456px!important;"
        />
      </div>
      <div class="text-center md:text-left md:pr-8 mb-5">
        <h1 class="base-title mb-3">{{ translate('be notified about our job offers', 'ucfirst') }}</h1>
        <p>{{ translate('subscribe to our newsletter to receive the latest jobs offers and news', 'ucfirst') }}.</p>
        <div class="w-full flex mt-6">
          <InputText
            :placeholder="translate('enter your email', 'ucfirst')"
            class="w-full rounded-l-full shadow-lg h-12 py-2.5 px-4"
            id="email"
          />
          <Button
            type="submit"
            :label="translate('subscribe', 'ucfirst')"
            class="py-1 pl-3 pr-9 text-white rounded-r-full shadow-lg bg-sea-serpent"
          />
        </div>
      </div>
    </section>

    <DonationLinkComponent class="md:mb-36" />
  </div>
</template>

<script setup>
import Button from "primevue/button";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Image from "primevue/image";
import InputText from "primevue/inputtext";
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import { RouterLink } from "vue-router";
import { translate } from "@/composables/translate";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import HomeHero from "@/components/Home/HeroComponent";
import HomePhotoGetStarted from "@/components/Home/PhotoGetStarted";
import jobData from "@/composables/jobData";
import { ucfirst, limitText } from "@/composables/textUtils";
import { getBase64, getOnPublicImagesUrl } from "@/composables/imageChecker";
import store from "@/store";
import companyData from "@/composables/companyData";
import { env } from "@/composables/config";
import requestHandler from "@/composables/requestHandler";
import DonationLinkComponent from "@/components/DonationLinkComponent"
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";

let toast = useToast();
onMounted(()=> {
  if(store.getters.hasSessionManage){
    const sessionMessageData = store.getters.getSessionMessage;
    showToast(toast, sessionMessageData.message, sessionMessageData.type);
    store.dispatch('cleanSessionMessage');
  }
})

// ############ Declaração de variáveis ############

const photo = "img-login.jpg";

let comapanyData = ref([]);
const companiesList = reactive({
  data: [],
  loading: false
});

const responsiveCompaniesLogo = ref([
  {
    breakpoint: "1400px",
    numVisible: 6,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 5,
    numScroll: 2,
  },
  {
    breakpoint: "768px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "440px",
    numVisible: 2,
    numScroll: 1,
  },
]);

const responsiveOptionsNewOffers = ref([
  {
    breakpoint: "1730px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "1400px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);

const responsiveOptionsProfissionals = ref([
  {
    breakpoint: "1400px",
    numVisible: 5,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "999px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "440px",
    numVisible: 1,
    numScroll: 1,
  },
]);

const categories = reactive([]);

let jobs = ref([]);
let loadingRecentJobs = reactive({
  loading: false,
  showSection: true
});

let jobsCategories = ref([]);
let loadingJobsCategories = reactive({
  loading: false,
  showSection: true
});

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############

async function getJobs() {
  loadingRecentJobs.loading = true;
  const response = await jobData.getMostRecentJobs();
  loadingRecentJobs.loading = false;
  if(!response.success){
    console.log("error getting latest jobs!");
    loadingRecentJobs.showSection = false;
    return;
  }
  jobs.value = response.data;
  if(jobs.value.data.length < 1){
    loadingRecentJobs.showSection = false;
  }else{
    loadingRecentJobs.showSection = true;
  }
}

async function getCategories() 
{
  loadingJobsCategories.loading = true;
  const response = await jobData.getRelevantJobCategories(6);

  if(!response.success){
    loadingJobsCategories.showSection = false;
    console.log("error getting categories!");
    return;
  }

  jobsCategories.value = response.data;
  const fetchedCategories = jobsCategories.value.data;

  let categoryImages = {};
  for(let category of fetchedCategories){
    categoryImages[category.en] = await getOnPublicImagesUrl(`data/categories/${category.en}.webp`, 'img/category-placeholder.jpg');
  }
  let userLang = store.getters.getUserLang;
  fetchedCategories.forEach(category => {
    categories.push({
      name: category.userLang == '' || category[userLang] == null ? category.en : category[userLang],
      qtd: category.job_count,
      img: categoryImages[category.en]
    });
  });
  loadingJobsCategories.loading = false;

  if (categories.length < 1) {
    loadingJobsCategories.showSection = false;
  } else {
    loadingJobsCategories.showSection = true;
  }
}

async function getCompanies(){
  companiesList.loading = true;
  const response = await companyData.listCompanies(20);
  companiesList.loading = false;
  if(!response.success){
    console.log("error getting companies!");
    comapanyData.value = [];
    companiesList.hideSection = true;
    return;
  }
  companiesList.hideSection = false;
  comapanyData.value = response.data;
  const fetchedCompanies = comapanyData.value.data;
  let companies = [];
  for(let company of fetchedCompanies){
    let companyData = company;
    companyData['company_logo_img'] = getBase64(company.company_logo, true, 'img/jobifull-retangular.png');
    companies.push(companyData);
  }
  companiesList.data = companies;
}

// function searchOffers() {}

// function filterBySalary() {}

// function filtersTypes() {}

//############ Propriedades Computadas ############

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onBeforeMount(() => {
  getJobs();
  if([undefined, null, 0].includes(env('APP_BETA'))){
    getCategories();
    getCompanies();
  }else{
    loadingJobsCategories.showSection = false;
    companiesList.hideSection = true;
  }
});
</script>

<style scoped>
  .job-location-height{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto !important;
  }
</style>
