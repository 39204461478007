<template>
  <div
    class="w-full overflow-hidden rounded-l-xl flex items-center justify-center"
    style="background: #edf2f7"
  >
    <div
      class="flex w-full sticky top-14 h-[calc(100vh-theme(spacing.2))] overflow-hidden"
    >
      <!-- Sidebar -->
      <div class="w-1/4 bg-white border-r border-gray-300">
        <!-- Sidebar Header -->
        <header
          class="p-4 border-b border-gray-300 flex justify-between items-center bg-oxford-blue text-white"
        >
          <h1 class="text-2xl font-semibold">Chat Web</h1>
          <div class="relative">
            <button id="menuButton" class="focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-100"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path d="M2 10a2 2 0 012-2h12a2 2 0 012 2 2 2 0 01-2 2H4a2 2 0 01-2-2z" />
              </svg>
            </button>
            <!-- Menu Dropdown -->
            <div
              id="menuDropdown"
              class="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg hidden"
            >
              <ul class="py-2 px-3">
                <li>
                  <a href="#" class="block px-4 py-2 text-gray-800 hover:text-gray-400"
                    >Option 1</a
                  >
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 text-gray-800 hover:text-gray-400"
                    >Option 2</a
                  >
                </li>
                <!-- Add more menu options here -->
              </ul>
            </div>
          </div>
        </header>

        <!-- Contact List -->
        <div class="overflow-y-auto h-screen p-3 mb-9 pb-20">
          <div
            v-for="message in props.data"
            :key="message.id"
            class="flex items-center mb-4 cursor-pointer hover:bg-gray-100 p-2 rounded-md"
          >
            <div
              v-if="message.id !== props.myMessages.id"
              class="w-12 h-12 bg-gray-300 rounded-full mr-3"
            >
              <img
                src="@/assets/images/avatar.png"
                alt="User Avatar"
                class="w-12 h-12 rounded-full"
              />
            </div>
            <div v-if="message.id !== props.myMessages.id" class="flex-1">
              <h2 class="text-lg font-semibold">{{ message.name }}</h2>
              <p class="text-gray-600">{{ message.message }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Main Chat Area -->
      <div class="flex-1">
        <!-- Chat Header -->
        <header class="bg-white p-4 text-gray-700">
          <h1 class="text-2xl font-semibold">Alice</h1>
        </header>

        <ConfirmPopup></ConfirmPopup>

        <OverlayPanel ref="op">
          <ul>
            <li>
              <Button type="button" icon="pi pi-copy" label="Copiar" />
            </li>
            <li>
              <Button type="button" icon="pi pi-reply" label="Responder" />
            </li>
            <li>
              <Button
                @click="confirmDelete($event)"
                type="button"
                icon="pi pi-trash"
                label="Eliminar"
              />
            </li>
          </ul>
        </OverlayPanel>

        <!-- Chat Messages -->
        <div class="h-screen overflow-y-auto p-4 pb-36">
          <div v-for="(chat, index) in props.chats" :key="index">
            <!-- Incoming Message -->
            <div
              v-if="loggedUserId != chat.applicant_id"
              class="flex mb-4 cursor-pointer"
            >
              <div class="w-9 h-9 rounded-full flex items-center justify-center mr-2">
                <img
                  src="@/assets/images/avatar.png"
                  alt="User Avatar"
                  class="w-8 h-8 rounded-full"
                />
              </div>
              <div class="flex max-w-96 shadow-md bg-white rounded-lg p-3 gap-3">
                <p v-if="chat.applicantMsgState == 'active'" class="text-gray-700">
                  {{ chat.applicantMessage }}
                </p>
                <p v-else class="font-medium text-gray-900 opacity-40">
                  Anulou o envio de uma mensagem
                </p>
              </div>
            </div>

            <!-- Outgoing Message -->
            <div
              v-if="loggedUserId == chat.company_id"
              class="flex justify-end items-center mb-4 cursor-pointer"
            >
              <i @click="toggle" class="pi pi-ellipsis-h text-xl mr-5"></i>
              <div
                class="flex max-w-96 bg-oxford-blue shadow-md text-white rounded-lg p-3 gap-3"
              >
                <p v-if="chat.companyMsgState == 'active'">{{ chat.companyMessage }}</p>
                <p v-else class="font-medium text-white opacity-40">
                  Anulaste o envio de uma mensagem
                </p>
              </div>
              <div class="w-9 h-9 rounded-full flex items-center justify-center ml-2">
                <img
                  src="@/assets/images/avatar.png"
                  alt="My Avatar"
                  class="w-8 h-8 rounded-full"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Chat Input -->
        <footer class="w-3/4 bg-white border-t border-gray-300 p-4 absolute bottom-0">
          <div class="flex items-center w-full">
            <InputText
              type="text"
              id="text"
              placeholder="Escreva sua mensagem..."
              class="w-full p-2 rounded-md border border-gray-400 focus:outline-none focus:border-blue-500"
            />
            <Button
              @click="sendMessage"
              label="Enviar"
              class="bg-oxford-blue text-white px-4 py-2 rounded-md ml-2"
            />
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script setup>
import Button from "primevue/button";
import ConfirmPopup from "primevue/confirmpopup";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const confirm = useConfirm();
const toast = useToast();

const op = ref();

const toggle = (event) => {
  op.value.toggle(event);
};

const confirmDelete = (event) => {
  confirm.require({
    target: event.currentTarget,
    message: "Do you want to delete this record?",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger p-button-sm",
    accept: () => {
      toast.add({
        severity: "info",
        summary: "Confirmed",
        detail: "Record deleted",
        life: 3000,
      });
    },
    reject: () => {
      toast.add({
        severity: "error",
        summary: "Rejected",
        detail: "You have rejected",
        life: 3000,
      });
    },
  });
};

// ############ Chamando componentes e composables ############
const store = useStore();
const props = defineProps(["data", "myMessages", "chats"]);
const emit = defineEmits(["send-message"]);

// ############ Declaração de variáveis ############

// ############ Criação de Métodos/Funções ############

const sendMessage = () => {
  emit("send-message", "minha mensagem de teste");
};

// ############ Criação de Computed's ############

let loggedUserId = computed(() => store.state.loggedUserId);
</script>
<style scoped></style>
