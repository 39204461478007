<template>
  <div
    class="container mx-auto grid grid-cols-1 md:grid-cols-4 px-5 pt-20 2xl:pt-24 pb-32"
  >
    <!-- barra lateral esquerda -->
    <SidebarTerms />

      <section
      class="col-span-4 md:col-span-3 flex flex-col content-start bg-white shadow rounded-md p-8 mb-30 col-description">

      <div v-for="(policy, index) in policies.data" :key="index" class="mb-4">
        <span v-if="policy.language == user_language">
          <h1 class="base-title pb-2"> 
            {{ policy.main_title }}  
          </h1>
          <p class="text-lg font-medium text-oxford-blue">{{ policy.policy_update }}</p> 
          <p class="pt-4">{{ policy.welcome }}</p>
          <p class="pt-4">{{ policy.privacy }}</p>
        </span>
      </div>

      <div v-for="(policy, index) in policies.data" :key="index" class="mb-5 md:mb-10">
        <article v-if="policy.language == user_language">
          <div v-for="(content, index) in policy.content" :key="index" class="mb-8">
            <h1 class="font-semibold text-xl text-oxford-blue pb-2">{{ content.title }}</h1>
            <p>{{ content.description }}</p> 
          </div>
        </article>
      </div>
    </section>
    <!-- /.col-span-3 -->
  </div>
</template>

<script setup>
import { computed } from "vue"
import SidebarTerms from "@/components/SidebarTermsComponent"; 
import policies from "@/static-content/privacy-policy.json";
import store from "@/store";

let user_language = computed(() => store.state.user_lang)

</script>

<style scoped>
.router-link-exact-active {
  color: #53c3c4;
}
</style>