<template>
  <LoaderComponent v-if="isLoading" />
  <div v-if="!isLoading && jobs.length < 1">
    <h1 class="font-semibold">{{ translate('no jobs found', 'ucfirst') }}!</h1>
    <p>
      {{ translate('click here to create a new one', 'ucfirst') }}:
      <RouterLink :to="`/dashboard/company/${slug}/post-job`" class="underline font-semibold text-oxford-blue" >
        {{ translate('create job', 'ucfirst') }}
      </RouterLink>
    </p>
  </div>
  <section v-if="!isLoading && jobs.length > 0" class="grid grid-cols-1 md:grid-cols-4 gap-5">
    <div class="col-span-1 md:col-span-4 flex justify-between items-center mb-1" v-if="props.seeMoreLink">
      <h1 class="font-semibold">{{ translate('recent posted', 'ucfirst') }}</h1>
      <RouterLink :to="`/dashboard/company/${store.getters.getUserSlug}/jobs`" class="underline font-semibold text-oxford-blue">
        {{ translate('see more', 'ucfirst') }}
      </RouterLink>
    </div>
    <div
      class="col-span-1 md:col-span-4 py-3 bg-white rounded-md shadow-md overflow-hidden"
    >
      <DataTable :value="jobs" class="w-full">
        <template #header>
          <div class="w-full">
            <span>
                <InputGroup
                  class="w-full flex flex-wrap md:flex-nowrap items-center md:pl-1.5 py-0 bg-white md:rounded-full md:border md:border-r-0 border-iron"
                >
                  <InputGroupAddon v-if="!isScreenSmall()">
                    <i class="pi pi-search"></i>
                  </InputGroupAddon>
                  <InputText
                    :unstyled="true"
                    v-model.trim="termToSearch"
                    type="text"
                    id="text"
                    :placeholder="translate('type to search', 'ucfirst')"
                    :class="`w-full px-2 py-1 outline-none ${isScreenSmall() ? 'border iron' : ''}`"
                    @input="checkJobSearch()"
                  />
                  <Button
                    :unstyled="true"
                    @click="searchJobs()"
                    :label="translate('search', 'ucfirst')"
                    class="py-1.5 px-4 font-semibold text-white md:rounded-r-full bg-sea-serpent w-full md:w-auto rounded"
                    :icon="isScreenSmall() ? 'pi pi-search' : ''"
                  />
                </InputGroup>
              </span>
          </div>
        </template>
        <Column :header="translate('title', 'ucfirst')" field="job_title"></Column>
        <Column field="number_of_applications" :header="translate('appliances', 'ucfirst')"></Column>
        <Column field="full_location" :header="translate('address', 'ucfirst')"> </Column>
        <Column field="job_created_at" :header="translate('date', 'ucfirst')"></Column>
        <Column :header="translate('status', 'ucfirst')">
          <template #body="slotProps">
            <Tag
              :value="slotProps.data.job_status_translation"
              :severity="getSeverity(slotProps.data.job_status)"
            />
          </template>
        </Column>
        <Column v-if="props.displayButtons" class="flex justify-around" style="min-width: 200px!important;">
          <template #body="slotProps">
            <RouterLink
              :to="`/dashboard/${userType}/${slug}/post-job/${slotProps.data.job_id}`"
              v-tooltip.top="translate('edit job', 'ucfirst')" type="text" placeholder="Top"
              ><i class="pi pi-pen-to-square"></i></RouterLink
            >
            <RouterLink
              :to="`/dashboard/${userType}/${slug}/jobs/${slotProps.data.job_id}`"
              v-tooltip.top="translate('applications', 'ucfirst')" type="text" placeholder="Top"
              ><i class="pi pi-users"></i></RouterLink
            >
          </template>
        </Column>
        <template #footer> {{ translate('a total of', 'ucfirst') }} {{ jobs.length }} {{ translate('jobs') }}. </template>
      </DataTable>
      <Paginator 
        v-if="showPagination"
        :rows="paginationData.per_page" 
        :totalRecords="paginationData.total"
        @page="changePage"
        class="c-paginator"
      />
    </div>
  </section>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Tag from "primevue/tag";
import { useStore } from "vuex";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import { onMounted, onUnmounted, ref } from "vue";
import requestHandler from "@/composables/requestHandler";
import { showToast } from "@/composables/toast";
import { useToast } from "primevue/usetoast";
import utils from "@/composables/utils";
import { translate } from "@/composables/translate";
import Paginator from 'primevue/paginator';

const props = defineProps({
  displayButtons: {
    type: Boolean,
    required: false,
    default: false
  },
  limit: {
    type: String,
    required: false,
    default: ''
  },
  seeMoreLink: {
    type: Boolean,
    required: false,
    default: false
  }
});

const toast   = useToast();
const store   = useStore();
let slug      = store.getters.getUserSlug;
let userType  = store.getters.getLoggedAs;
let isLoading = ref();
let jobs      = ref([]);

const getSeverity = (jobStatus) => {
  switch (jobStatus) {
    case "published":
      return "success";
    case "validating":
      return "warning";
    case "hidden":
      return "secondary";
    case "draft":
      return "warning";
    default:
      return null;
  }
};

onMounted(function(){
  getCompanyJobs();
  handleResize();
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})

let showPagination = ref(false);
async function getCompanyJobs(refresh = false){
  isLoading.value = true;
  let jobsData = [];
  let companyJobType = ['', null, undefined].includes(props.limit) ? '' : '_highlighted';
  let myCompanyJobs = store.getters.getOnDataChest(`my_company_jobs${companyJobType}`);
  if(refresh) {
    myCompanyJobs = [];
  }
  if(myCompanyJobs !== null && myCompanyJobs !== undefined && !utils.isEmpty(myCompanyJobs)){
    jobsData = myCompanyJobs;
    isLoading.value = false;
  } else {
    let all = false;
    let perPage = 5;
    if(['', null, undefined].includes(props.limit)) {
      all = true;
    }
    const response = await requestHandler.executeRequest('/api/company/jobs', { all: all, per_page: perPage }, 'get');
    isLoading.value = false;
    if(!response.success){
      showToast(toast, response.message, 'error');
      return;
    }
    jobsData = response.data.data;
    store.dispatch('saveOnDataChest', {
      key: `my_company_jobs${companyJobType}`,
      value: jobsData
    });
    for(let job of jobsData){
      store.dispatch('saveOnDataChest', {
        key: `save_job_${job.job_id}`,
        value: job
      });
    }
  }
  if(companyJobType == '_highlighted'){
    jobs.value = jobsData;
    showPagination.value = false;
    return;
  }
  showPagination.value = true;
  paginateResults(jobsData);
}

let paginationData = ref({
  total: 0,
  per_page: 10,
  currPage: 0,
  lastPage: 0,
  results: [],
  perPage: {},
  search: {}
});

function paginateResults(jobsData){
  paginationData.value.total = jobsData.length; 
  if(paginationData.value.total < 1)
    return;
  let page = 0;
  let marker = 0;
  let thisPage = [];
  let filterData = {};
  let thisJobTitle = '';
  for(let index = 0; index < paginationData.value.total; index++){
    if(jobsData[index] != undefined){
      thisJobTitle = jobsData[index]['job_title'].toLowerCase();
      if(filterData[thisJobTitle] == undefined)
        filterData[thisJobTitle] = [];
      filterData[thisJobTitle].push(jobsData[index]);
    }
    thisPage.push(jobsData[index]);
    marker++;
    if(marker == paginationData.value.per_page){
      paginationData.value.perPage[page] = thisPage;
      marker = 0;
      paginationData.value.lastPage = page;
      page++;
      thisPage = [];
    }
  }
  if(paginationData.value.total < paginationData.value.per_page){
    paginationData.value.perPage[0] = thisPage;
    paginationData.value.lastPage = 0;
  }
  paginationData.value.results = [];
  paginationData.value.search = filterData;
  changePage(0, true);
}

function changePage(page = null, firstLoad = false){
  page = page.page != undefined ? page.page : page;
  if(page == paginationData.value.currPage && firstLoad == false) 
    return;
  if(page < 0)
    page = 0;
  if(page > paginationData.value.lastPage)
    page = paginationData.value.lastPage;
  if(paginationData.value.perPage[page] != undefined){
    paginationData.value.results = paginationData.value.perPage[page];
    paginationData.value.currPage = page;
  }else{
    paginationData.value.results = paginationData.value.perPage[paginationData.value.lastPage];
    paginationData.value.currPage = paginationData.value.lastPage;
  }
  jobs.value = paginationData.value.results;
}

let termToSearch = ref('');
function searchJobs(){
  if(termToSearch.value != '' && termToSearch.value.length > 2){
    let dataToDisplay = [];
    let usedTitle = [];
    for(let title of Object.keys(paginationData.value.search)){
      if(title.indexOf(termToSearch.value.toLowerCase()) != -1 && !usedTitle.includes(title)){
        for(let value of paginationData.value.search[title]){
          dataToDisplay.push(value);
        }
        usedTitle.push(title);
      }
    }
    showPagination.value  = false;
    jobs.value = dataToDisplay;
  }else{
    showToast(toast, translate('be more specific', 'ucfirst'), 'info');
  }
}

function checkJobSearch(){
  if(termToSearch.value == ''){
    jobs.value = paginationData.value.results;
    showPagination.value = true;
  }
}

const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const breakPoint = 500;
function isScreenSmall(){
  return windowWidth.value > breakPoint ? false : true;
}
function handleResize(){
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}
</script>