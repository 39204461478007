<template>
    <div class="h-screen">
      <section class="min-h-screen bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
          <div class="mx-auto text-center py-5 text-xl rounded font-bold text-blue-oxford">
            <h1 class="mt-auto mb-auto">
              {{ translate("We are undergoing maintenance, but don't worry, the site will be available soon", 'ucfirst') }}!
            </h1>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script setup>
  import { showToast } from "@/composables/toast";
  import { translate } from "@/composables/translate";
  import store from "@/store";
  import { useToast } from "primevue/usetoast";
  import { onMounted } from "vue";

  let toast = useToast();
  onMounted(() => {
    if(store.getters.hasSessionManage){
      const sessionMessageData = store.getters.getSessionMessage;
      showToast(toast, sessionMessageData.message, sessionMessageData.type);
      store.dispatch('cleanSessionMessage');
    }
  })
  </script>
  