/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { translate } from './composables/translate';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available.');
      if (confirm(translate('new update available. Do you want to reupload to the latest version?', 'ucfirst'))) {
        clearCacheAndReload(registration);
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

// Clears cache and refresh page
function clearCacheAndReload(registration) {
  if ('caches' in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
      console.log('cache cleaned');
    }).then(() => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      console.log('refreshing page');
      window.location.reload(true);
    }).catch((error) => {
      console.error("error cleaning cache:", error);
    });
  } else {
    console.log('refreshing page');
    window.location.reload(true);
  }
}
