import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const countries = {
    async listCountries(onlyCountryFileData = false) {
        try {
            if(onlyCountryFileData){
                let countryFileData = store.getters.getOnComboChest('country_file_data');
                if(countryFileData !== null && countryFileData !== undefined && !utils.isEmpty(countryFileData))
                    return countryFileData;
                let countriesFileData = requestHandler.fetchOnPublicData('countries.json');
                if(countriesFileData){
                    store.dispatch('saveOnComboChest', {
                        key: 'country_file_data',
                        value: countriesFileData
                    });
                    return countriesFileData
                }
                return false;
            }
            let countryCombo = store.getters.getOnComboChest('country');
            if(countryCombo !== null && countryCombo !== undefined && !utils.isEmpty(countryCombo))
                return countryCombo;
            let countriesFileData = requestHandler.fetchOnPublicData('countries.json');
            if(countriesFileData){
                store.dispatch('saveOnComboChest', {
                    key: 'country_file_data',
                    value: countriesFileData
                });
                return countriesFileData
            }
            const result = await requestHandler.executeRequest('api/countries/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'country',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default countries;