<template>
  <Card
    v-for="(applicant, index) in selectedPageData"
    :key="index"
    class="shadow-md border border-iron w-full md:w-[23%]" 
  > 
    <template #content>
      <div class="flex flex-col items-center text-center pb-3">
        <div
          class="w-24 h-24 rounded-full mb-3 bg-slate-200 border border-iron profile-photo"
          :style="{ backgroundImage: `url(${applicant.professional_photo})`}"
        ></div>

        <h1 class="text-lg font-bold text-oxford-blue">{{ applicant.professional_fullname	 }}</h1>
        <p v-if="applicant.professional_profession" class="text-sm font-semibold mb-1">
          {{ applicant.professional_profession }}
        </p>
        <template v-else>
          <p class="text-sm font-semibold mb-1 hidden-text">...</p>
        </template> 
        <span class="flex items-center gap-x-2" v-if="applicant.location">
          <i class="pi pi-map-marker text-sm"></i>
          <p class="text-[0.85rem] font-medium">{{ applicant.location	 }}</p>
        </span>
        <template v-else>
          <p class="text-[0.85rem] font-medium hidden-text">...</p>
        </template>

        <Rating
          v-if="applicant.rating"
          v-model="applicant.rating"
          class="mx-auto mt-2.5"
          readonly
          :cancel="false"
        />

        <div
          class="w-full flex justify-between items-center mt-4 -mx-7 -mb-6 pt-[0.500rem] text-sm font-semibold text-oxford-blue border-t border-iron flex-col md:flex-row"
        >
          <Button 
            class="bg-primary-color font-semibold py-2 px-4 border text-white rounded mt-5 md:mt-1 w-full" 
            :data-id="applicant.professional_id"
            @click.prevent="openProfessionalCurriculum(applicant.professional_id)"
          >
            <i class="pi pi-address-book mr-3"></i>{{ translate('see profile', 'ucfirst') }}
          </Button>
          <RouterLink
            v-if="[undefined, null, 0].includes(env('APP_BETA'))"
            class="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded mt-5 md:mt-1 w-full"
           :to="`/dashboard/${username}/jobs/${applicant.professional_slug	}`" 
          >
            {{ translate('message', 'ucfirst') }}
          </RouterLink>
        </div>
      </div>
    </template>
  </Card>
  <div class="c-paginator w-full col-span-4 mt-5">
    <Paginator 
      :rows="paginationData.perPage" 
      :totalRecords="content.length"
      @page="changePage"
    />
  </div>

  <Dialog
    v-model:visible="showProfessionalCurriculum"
    modal
    :style="{ width: '35rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :draggable="false"
    :closable="true"
    class="relative bg-white rounded-lg"
  >
    <ProfessionalCurriculum :professional_id="selectedProfessionalId" />
  </Dialog>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { RouterLink } from "vue-router";
import { useStore } from "vuex";
import Card from "primevue/card";
import Rating from "primevue/rating";
import { translate } from "@/composables/translate";
import Paginator from 'primevue/paginator';
import { env } from "@/composables/config";
import ProfessionalCurriculum from "@/components/Dashboard/Applicant/ProfessionalCurriculum";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

const store = useStore();
let username = computed(() => store.state.username);

let props = defineProps(["data"]);
let showProfessionalCurriculum = ref(false);
let selectedProfessionalId = ref(null);
let content = ref([]);
onMounted(() => {
  content.value = props.data;
  gatherPaginationData();
  changePage(0);
})

watch(() => props.data, (newData) => {
  content.value = newData;
  gatherPaginationData();
  changePage(0);
},
  { deep: true }
);

let paginationData = reactive({
  page: 1,
  perPage: 8,
  contentPerPage: [],
  lastPage: 1
});
let selectedPageData = ref([]);
function changePage(pageNumber = 0) {
  pageNumber = pageNumber.page != undefined ? pageNumber.page : pageNumber;
  if(pageNumber == paginationData.lastPage || pageNumber > paginationData.lastPage){
    pageNumber = paginationData.lastPage
  }
  for(let data of paginationData.contentPerPage){
    if(data['page'] == pageNumber){
      selectedPageData.value = data['content'];
    }
  }
}

function gatherPaginationData(){
  let total = content.value.length;
  let pages = Math.round(total / paginationData.perPage);
  let cNumber = 0;
  paginationData.contentPerPage = [];
  if(pages == 0){
    paginationData.contentPerPage.push({
      content: content.value,
      page: 0
    });
    paginationData.lastPage = 0;
  }else{
    for(let i = 0; i < pages; i++){
      let thisPageData = [];
      for(let index = 0; index < paginationData.perPage; index++){
        if(content.value[cNumber] == undefined)
          break;
        thisPageData.push(content.value[cNumber]);
        cNumber++;
      }
      paginationData.contentPerPage.push({
        content: thisPageData,
        page: i
      });
      paginationData.lastPage = i;
    }
  }
}

function openProfessionalCurriculum(professional_id = null){
  selectedProfessionalId.value = professional_id;
  showProfessionalCurriculum.value = true;
}

</script>

<style scoped></style>
