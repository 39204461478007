<template>
  <div class="p-5b 4grid 4grid-cols-3 4gap-4 5pt-12 5pb-16 5px-5 7xs:pl-8 7xs:pr-4">
    <!-- Componentes de mensagens/Chat -->
    <DashboardChat
      :data="messages"
      :chats="chats"
      :my-messages="myMessages"
      @send-message="sendMessage"
    />

    <!-- <section class="col-span-34 4md:col-span-2 5flex 5flex-col 5gap-y-5"></section> -->
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";

import DashboardChat from "@/components/Dashboard/DashboardChat.vue";

// ############ Declaração de variáveis ############

let myMessages = ref({
  id: 4,
  name: "Ravana Miranda",
  message: "Esta é uma mensagem de teste 4",
});

let messages = ref([
  { id: 1, name: "John Doe", message: "Esta é uma mensagem de teste 1" },
  { id: 2, name: "Nickolas", message: "Esta é uma mensagem de teste 2" },
  { id: 3, name: "Vanessa Pieres", message: "Esta é uma mensagem de teste 3" },
  { id: 4, name: "Ravana Miranda", message: "Esta é uma mensagem de teste 4" },
  { id: 5, name: "Estevão Luis", message: "Esta é uma mensagem de teste 5" },
]);

let chats = reactive([
  {
    id: 1,
    company_id: 3,
    applicant_id: 5,
    companyMessage: "Esta mensagem 1 é minha como empresa que publica a vaga",
    applicantMessage: "Esta mensagem 1 é minha como profissional",
    companyMsgState: "active",
    applicantMsgState: "active",
    send_date: "12 Jun 12:04",
  },
  {
    id: 2,
    company_id: 3,
    applicant_id: 5,
    companyMessage: "Esta mensagem 2 é minha como empresa que publica a vaga",
    applicantMessage: "Esta mensagem 2 é minha como profissional",
    companyMsgState: "active",
    applicantMsgState: "active",
    send_date: "12 Jun 12:04",
  },
  {
    id: 3,
    company_id: 3,
    applicant_id: 5,
    companyMessage: "Esta mensagem 3 é minha como empresa que publica a vaga",
    applicantMessage: "Esta mensagem 3 é minha como profissional",
    companyMsgState: "active",
    applicantMsgState: "deleted",
    send_date: "12 Jun 12:04",
  },
  {
    id: 4,
    company_id: 3,
    applicant_id: 5,
    companyMessage: "Esta mensagem 4 é minha como empresa que publica a vaga",
    applicantMessage: "Esta mensagem 4 é minha como profissional",
    companyMsgState: "deleted",
    applicantMsgState: "active",
    send_date: "12 Jun 12:04",
  },
  {
    id: 5,
    company_id: 3,
    applicant_id: 5,
    companyMessage: "Esta mensagem 5 é minha como empresa que publica a vaga",
    applicantMessage: "Esta mensagem 5 é minha como profissional",
    companyMsgState: "active",
    applicantMsgState: "deleted",
    send_date: "12 Jun 12:04",
  },
  {
    id: 6,
    company_id: 3,
    applicant_id: 5,
    companyMessage: "Esta mensagem 6 é minha como empresa que publica a vaga",
    applicantMessage: "Esta mensagem 6 é minha como profissional",
    companyMsgState: "active",
    applicantMsgState: "active",
    send_date: "12 Jun 12:04",
  },
]);

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############
function sendMessage(msg) {
  console.log("Mensagem enviada", msg);
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onMounted(() => {});
</script>
