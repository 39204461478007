import store from "@/store";
import Cookies from "js-cookie";
import { decrypte, encrypte } from "./crypto";


const cookiesHandler = {
    hasAcceptedCookies() {
        if(store.getters.isLoggedIn)
            return true;
        let acceptedCookies = Cookies.get('accepted_cookies');
        return ![null,undefined,''].includes(acceptedCookies) ? true : false;
    },

    hasCookie(cookieName = ''){
        return ![null,undefined,''].includes(Cookies.get(cookieName)) ? true : false;
    },

    saveCookieAcecptance(){
        Cookies.set('accepted_cookies', '1');
    },

    removeCookieAcceptance(){
        Cookies.remove('accepted_cookies');
    },

    setEncryptedCookie(cookieName, value = ''){
        Cookies.set(cookieName, encrypte(value));
    },

    getEncryptedCookie(cookieName){
        return decrypte(Cookies.get(cookieName));
    }
}

export default cookiesHandler;