<template>
  <div>
    <!-- Menu web -->
    <WebMenuComponent :user="user" />

    <!-- Menu Mobile -->
    <MobileMenuComponent :user="user" />
  </div>

  <!-- Accept cookies popup -->
  <div 
    v-if="showAcceptCookiesDialog" 
    style="z-index: 100000; margin-bottom: 5%;"
    class="fixed bottom-0 w-full md:w-2/5 rounded p-3"
  >
    <div class="mx-auto bg-white py-5 px-2 rounded border-iron shadow-md">
      <img src="@/assets/cookies.svg" class="h-20 mb-2 px-2">
      <p class="px-2 text-xl">
        {{ `${translate("by clicking 'Accept all cookies', you agree Jobifull can store cookies on your device and use information in accordance with our Cookie Policy", 'ucfirst')}.` }}
      </p>
      <p class="px-2 my-2">
        {{ `${translate('note: If your do not accept our cookies, you will not be able to login', 'ucfirst')}. ${translate('know more at our', 'ucfirst')}` }} 
        <RouterLink to="/cookies-policy" class="underline">
          {{ translate('cookies policy', 'capitalize') }}.
        </RouterLink>
      </p>
      <div class="flex justify-between">
        <Button 
          :label="translate('do not accept', 'ucfirst')"
          class="w-full md:w-3/5 md:me-2 py-2 px-5 mt-4 text-white rounded bg-red-600"
          @click="handleCookies(false)"
        />
        <Button 
          :label="translate('accept all', 'ucfirst')"
          class="w-full md:w-3/5 py-2 px-5 mt-4 text-white rounded bg-sea-serpent"
          @click="handleCookies(true)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import { useStore } from "vuex";
import WebMenuComponent from "@/components/WebMenuComponent.vue";
import MobileMenuComponent from "@/components/MobileMenuComponent.vue";
import cookiesHandler from "@/composables/cookiesHandler";
import Button from "primevue/button";
import { translate } from "@/composables/translate";
import { RouterLink } from "vue-router";

const store = useStore();

const user = reactive({
  logged: computed(() => store.state.user.userLogged),
  name: computed(() => store.state.user.name),
  userType: computed(() => store.state.user.userType),
  loggedAs: computed(() => store.state.user.loggedAs),
});

let showAcceptCookiesDialog = ref(false);
// onMounted(() => {
//   if(!cookiesHandler.hasAcceptedCookies()){
//     showAcceptCookiesDialog.value = true;
//   }else{
//     showAcceptCookiesDialog.value = false;
//   }
// })

function handleCookies(accepted = true){
  if(accepted){
    cookiesHandler.saveCookieAcecptance();
  }else{
    cookiesHandler.removeCookieAcceptance();
  }
  showAcceptCookiesDialog.value = false;
}
</script>
