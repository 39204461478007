import CryptoJS from 'crypto-js';

export function encrypte(data) {
  if (data) {  
    const key = CryptoJS.PBKDF2(process.env.VUE_APP_CRYPTO_KEY, process.env.VUE_APP_CRYPTO_HASH, { keySize: 256/32, iterations: 100 });
    const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_IV);
    const encrypted = CryptoJS.AES.encrypt(data.toString(), key, { iv: iv, mode: CryptoJS.mode.CBC });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  }
}

export function decrypte(data) {
  if (data) {
    const key = CryptoJS.PBKDF2(process.env.VUE_APP_CRYPTO_KEY, process.env.VUE_APP_CRYPTO_HASH, { keySize: 256/32, iterations: 100 });
    const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_IV);
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(data) }, key, { iv: iv, mode: CryptoJS.mode.CBC });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}