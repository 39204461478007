<template>
  <div class="px-5">
    <section
      class="w-full flex flex-col items-center md:w-2/3 xl:w-3/6 md:mx-auto p-5 md:p-12 mt-32 mb-20 rounded-lg bg-white shadow-lg"
    >
      <h1 class="text-2xl font-bold text-center text-oxford-blue">{{ translate('reset password', 'ucfirst') }}</h1>
      <p class="w-full lg:w-2/3 text-center mt-2">
        {{ translate('you requested a reset link to reset your password, if it was not you', 'ucfirst') }},
        <RouterLink to="/login" class="font-medium text-sea-serpent">{{ translate('ignore it') }}</RouterLink>.
      </p>

      <form
        @submit.prevent="resetPassword"
        class="w-full lg:w-2/3 flex flex-col gap-y-4 mt-8"
      >
        <Password
          v-model="reset.password"
          :placeholder="translate('new password', 'ucfirst')"
          toggleMask
          id="password"
          :promptLabel="translate('new password', 'ucfirst')"
          :weakLabel="translate('weak password', 'ucfirst')"
          :mediumLabel="translate('medium complexity', 'ucfirst')"
          :strongLabel="translate('strong password', 'ucfirst')"
          inputClass="w-full py-2 px-3 rounded-lg border border-color-iron"
        >
          <template #footer>
            <Divider />
            <p class="mt-2">{{ translate('suggestions', 'ucfirst') }}</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
              <li>{{ translate('at least a lowercase letter', 'ucfirst') }}</li>
              <li>{{ translate('at least an uppercase letter', 'ucfirst') }}</li>
              <li>{{ translate('at least a number', 'ucfirst') }}</li>
              <li>{{ translate('at least a special character', 'ucfirst') }}</li>
              <li>{{ translate('minimum of 6 characters', 'ucfirst') }}</li>
            </ul>
          </template>
        </Password>

        <Password
          v-model="reset.password_confirmation"
          :placeholder="translate('confirm new password', 'ucfirst')"
          toggleMask
          id="password"
          inputClass="w-full py-2 px-3 rounded-lg border border-color-iron"
        />

        <div class="py-3 text-center">
          <Button 
            :label="translate('generate password', 'ucfirst')"
            class="py-2 px-3 rounded-lg border border-color-iron"
            icon="pi pi-key" 
            iconPos="right"
            @click="generateUserPassword()"
            :disabled="reset.loading"
          />
        </div>

        <Button
          type="submit"
          :label="translate('reset', 'ucfirst')"
          class="w-full py-2 px-5 text-white rounded-lg bg-sea-serpent"
          :loading="reset.loading"
        />
      </form>
    </section>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { RouterLink, useRoute } from "vue-router";
import Password from "primevue/password";
import Button from "primevue/button";
import Divider from "primevue/divider";
import authUser from "@/composables/authUser";
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";
import requestHandler from "@/composables/requestHandler";
import store from "@/store";
import { translate } from "@/composables/translate";
import Cookies from "js-cookie";
import { generatePassword } from "@/composables/passwordGenerator";

const route = useRoute();
const toast = useToast();
// ############ Declaração de variáveis ############

const reset = reactive({
  password: "",
  password_confirmation: "",
  message: "",
  loading: false
});

// ############ Criação de Métodos/Funções ############

async function resetPassword() {
  reset.loading = true;
  const response = await authUser.resetPassword({email: route.query.email, password: reset.password, password_confirmation: reset.password_confirmation, token: route.query.token});
  reset.loading = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  store.dispatch('saveSessionMessage', {message: response.message, messageType: 'success'});
  Cookies.remove('XSRF-TOKEN');
  requestHandler.redirect('/login');
}

function generateUserPassword(){
  const newPass = generatePassword(12);
  reset.password = newPass;
  reset.password_confirmation = newPass;
}

// ############ Ciclos de Vida/Hooks ############

onMounted(() => {
  //
});
</script>

<style scoped></style>
