<template>
    <LoadingDialog :visible="actionIsBeingExecuted" />

    <ConfirmationModal 
      :title="currentConfirmationData.title"
      :text="currentConfirmationData.text"
      :visible="confirmationModalVisibility"
      @emitResponse="confirmationResponse"
    />
    <LoaderComponent class="col-span-5 text-center pt-5 mt-5" v-if="tabLoading" />
    <div class="content-wrapper col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5" v-if="!tabLoading">
        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-6 text-lg font-semibold">{{ translate('skills', 'ucfirst') }}</h1>
            <form
                class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-6 text-base font-medium"
            >
                <span class="col-span-3 p-float-label">
                    <AutoComplete
                        inputId="skillsAutocomplete"
                        v-model="skillName" 
                        :suggestions="skillsList" 
                        optionLabel="name"
                        @complete="searchForSkills"
                        :placeholder="`${translate('type to search')}...`"
                        :emptyMessage="translate('no results found', 'ucfirst')"
                        :emptySearchMessage="translate('no results found', 'ucfirst')"
                        :inputClass="'w-full'"
                        multiple
                        fluid 
                        minLength="3"
                        class="w-full rounded border border-iron autocomplete-w-full"
                    />
                </span>
                <hr class="iron pt-2 pb-2 col-span-3">
                <div class="col-span-3 flex flex-wrap">
                    <div v-for="(skill, index) of skillName" :class="`w-full mb-3 md:pe-3 md:w-1/3 skill-opt-${skill.code}-${skill.name.replace(' ', 'aaaa-aaaa-aaaa')}`" :key="index">
                        <span>{{ skill.name }}</span>: 
                        <Dropdown
                            v-model="senioritiesCombo.selected[`${skill.code}-${skill.name.replace(' ', 'aaaa-aaaa-aaaa')}`]"
                            :options="senioritiesCombo.data"
                            @change="highlightDropDown(`${skill.code}-${skill.name.replace(' ', 'aaaa-aaaa-aaaa')}`, false);"
                            optionLabel="name"
                            :placeholder="translate('select a seniority', 'ucfirst')"
                            class="w-full rounded-full border border-iron dropdown-el"
                            required
                            filter
                            :loading="senioritiesCombo.loading"
                            :id="`seniority-${skill.code}-${skill.name.replace(' ', 'aaaa-aaaa-aaaa')}`"
                        />
                    </div>
                </div>
            </form>
        </section>
    </div>
    <ActionCard 
        v-if="!tabLoading"
        :jobId="jobId"
        :jobStatus="props.jobStatus"
        :actionButtonText="translate('save job skills', 'ucfirst')"
        @emitClick="openConfirmationModal('skillSave')"
        class="col-span-3 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
    />
</template>

<script setup>
    import { ref, onMounted, watch, reactive, onBeforeMount } from "vue";
    import requestHandler from "@/composables/requestHandler";
    import AutoComplete from "primevue/autocomplete";
    import { translate } from "@/composables/translate";
    import { sleep } from "@/composables/sleep";
    import store from "@/store";
    import utils from "@/composables/utils";
    import ActionCard from "@/components/Dashboard/Company/SaveJob/ActionCard"
    import ConfirmationModal from "@/components/Utils/ConfirmationModal";
    import Dropdown from "primevue/dropdown";
    import jobData from "@/composables/jobData";
    import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
    import { showToast } from "@/composables/toast";
    import { useToast } from "primevue/usetoast";
    import LoaderComponent from "@/components/Utils/LoaderComponent.vue";

    let toast = useToast();

    const props = defineProps({
        jobId: {
            type: String,
            required: false,
            default: null
        },
        jobStatus: {
            type: String,
            required: false,
            default: 'draft'
        }
    });

    let tabLoading = ref(true);
    let actionIsBeingExecuted = ref(false);
    let jobId = ref(null);
    onMounted(function(){
        handleAutoCompleteElements();
        getEssentialData();
    });

    onBeforeMount(function(){
        jobId.value = props.jobId;
        if(jobId.value == null)
            goToJobPage();
    });

    async function getEssentialData(){
        tabLoading.value = true;
        await feedSenioritiesCombo();
        await fetchAndFeedSkills();
        tabLoading.value = false;
    }

    async function fetchAndFeedSkills(){
        if(!jobId.value)
            return;
        let values = await jobData.searchJobSkills(jobId.value);
        skillName.value = values.skill;
        for(let key in values.seniority){
            senioritiesCombo.selected[key] = values.seniority[key];
        }
    }

    let senioritiesCombo = reactive({
        data: [],
        selected: [],
        loading: false
    })
    async function feedSenioritiesCombo(){
        senioritiesCombo.loading = true;
        const result = await jobData.getJobComboData('job_seniority_level');
        senioritiesCombo.data = result;
        senioritiesCombo.loading = false;
    }

    async function goToJobPage(){
        await requestHandler.redirect(`/dashboard/company/${store.getters.getUserSlug}/post-job`);
    }

    watch(() => props.jobId, (newValue) => {
      jobId.value = newValue;
      handleAutoCompleteElements();
    });

    
    let confirmationModalVisibility = ref(false);
    const confirmationData = {
        skillSearch: {
            title: translate('no match found for this result', 'ucfirst'),
            text: translate('do you wish to add it anyway to your job skills? This action will make this skill be avaliable to professionals only after its validation', 'ucfirst')
        },
        skillSave: {
            title: translate('are you sure?', 'ucfirst'),
            text: translate('do you wish to save these job skills? This action will make your job skills unavaliable until it passes by our validation trials', 'ucfirst')
        }
    }
    let currentConfirmationData = reactive({
        title: '',
        text: '',
        action: ''
    })
    function openConfirmationModal(action = ''){
        currentConfirmationData.action = '';
        confirmationModalVisibility.value = false;
        if(action == '' || !['skillSearch','skillSave'].includes(action))
            return;
        currentConfirmationData.action = action;
        currentConfirmationData.title = confirmationData[action].title;
        currentConfirmationData.text  = confirmationData[action].text;
        confirmationModalVisibility.value = true;
    }

    let termToSearch = ref('');
    let customSkills = ref([]);
    let skillName = ref([]);
    let skillsList = ref([]);
    async function searchForSkills(event){
      sleep(500);
      try {
        termToSearch.value = event.query;
        const response = await requestHandler.executeRequest('/api/tags/search', {term: termToSearch.value}, 'get');
        let values = [];
        let userLang = store.getters.getUserLang && ['en','pt','es'].includes(store.getters.getUserLang) ? store.getters.getUserLang : 'en';
        let responseAsArray = utils.parseToArray(response.data.data);
        for(let skill of responseAsArray){
          values.push({name: skill[userLang] == null ? skill['en'] : skill[userLang], code: skill['tags_id']});
        }
        skillsList.value = values;
        if(responseAsArray.length < 1){
            openConfirmationModal('skillSearch');
        }else{
            termToSearch.value = '';
        }
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }

    async function handleAutoCompleteElements(){
      let tries = 0;
      let autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
      while(autocompleteEls.length < 1){
          autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
          tries++;
          await sleep(50);
          if(tries == 30){
          break;
          }
      }
      if(autocompleteEls.length < 1)
          return;
      autocompleteEls.forEach(el => {
        if(el.style.width == '')
          el.style.setProperty('width', '100%', 'important');
          const ulEl = el.querySelector('ul');
          if(ulEl){
            ulEl.style.setProperty('width', '100%', 'important');
            // ulEl.style.setProperty('border-radius', '9999px', 'important');
            let inputEl = ulEl.querySelector('input');
          if(inputEl){
            inputEl.style.setProperty('width', '100%', 'important');
            inputEl.style.setProperty('outline', 'unset', 'important');
          }
        }
      });
    }

    async function confirmationResponse(result){
        confirmationModalVisibility.value = false;
        if(result.confirmation){
            actionIsBeingExecuted.value = true;
            switch(currentConfirmationData.action){
                case 'skillSearch':
                    addCustomSkill();
                break;
                case 'skillSave':
                    await saveSkills();
                break;
            }
            actionIsBeingExecuted.value = false;
        }
        currentConfirmationData.action = '';
    }

    async function saveSkills(){
        let jobsIds   = [];
        let jobsNames = [];
        let jobSeniorities = [];
        for(let data of skillName.value){
            jobsIds.push(data['code']);
            jobsNames.push(data['name']);
            let id = `${data.code}-${data.name.replace(' ', 'aaaa-aaaa-aaaa')}`;
            if(senioritiesCombo.selected[id] == undefined){
                showToast(toast, translate('no seniority informed'), 'error');
                highlightDropDown(id);
                return;
            }
            highlightDropDown(id, false);
            jobSeniorities.push(senioritiesCombo.selected[id].code);
        }
        const response = await requestHandler.executeRequest(`api/joblist/manageskills/${jobId.value}`, {
            action: 'sync', job_skills_ids: jobsIds, job_skills_names: jobsNames, job_skills_seniorities: jobSeniorities
        }, 'post');
        if(!response.success){
            let errorMessage = jobsIds.length > 1 ? translate('skills not saved') : translate('skill not saved');
            showToast(toast, errorMessage, 'error');
            return;
        }
        let successMessage = jobsIds.length > 1 ? translate('skills saved') : translate('skill saved');
        showToast(toast, successMessage);
        await jobData.updateMyCompanyJobs(jobId.value, response.data.data);
        store.dispatch('saveOnDataChest', {
            key: `save_job_${jobId.value}`,
            value: response.data.data
        });
    }

    function highlightDropDown(id = '', mark = true){
        id = id.replace(' ', 'aaaa-aaaa-aaaa');
        let el = document.querySelector(`.skill-opt-${id}`);
        if(el != undefined && el.querySelector('.dropdown-el') != null){
            el.querySelector('.dropdown-el').style.border = mark ? '1px solid red' : '';
        }
    }

    let confirmationModalOpen = ref(false);
    function addCustomSkill(){
        confirmationModalVisibility.value = false;
        if(confirmationModalOpen.value){
            confirmationModalOpen.value = false;
            return;
        }
        customSkills.value.push({code: 0, name: termToSearch.value});
        skillName.value.push({code: 0, name: termToSearch.value})
        termToSearch.value = ''; 
        document.querySelector('#skillsAutocomplete').value = '' ;
    }
</script>