import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const drivingLicenses = {
    async listDrivingLicenses() {
        try {
            let drivingLicenseCombo = store.getters.getOnComboChest('driving_license');
            if(drivingLicenseCombo !== null && drivingLicenseCombo !== undefined && !utils.isEmpty(drivingLicenseCombo))
                return drivingLicenseCombo;
            const result = await requestHandler.executeRequest('api/driving_license/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'driving_license',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default drivingLicenses;