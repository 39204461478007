<template>
  <ProfileChooseComponent
    v-if="profileChoose.show"
    :action="'switchAccount'"
  />
  <LoadingDialog :visible="loading" />
  <div
    class="mobile-navbar w-full fixed top-0 left-0 z-50 flex md:hidden justify-between items-center font-semibold bg-white py-5 px-4"
  >
    <Image
      src="../../img/jobifull-retangular.png"
      alt="logo jobifull"
      imageClass="w-28"
      @click="requestHandler.redirect('/')"
    />

    <i @click="visible = true" class="pi pi-bars" style="font-size: 1.8rem"></i>

    <Sidebar v-model:visible="visible">
      <template #header>
        <Image
          src="../../img/jobifull-retangular.png"
          alt="logo jobifull"
          imageClass="w-24"
        />
      </template>
      <nav
        class="flex flex-col items-center gap-y-4 font-semibold bg-white py-5 px-4 md:px-12 text-oxford-blue"
      >
        <ul
          class="flex flex-col md:flex-row space-y-1 md:space-y-0 md:space-x-6 lg:space-x-7"
        >
          <li>
            <RouterLink to="/" @click="closeMenu()">{{ translate('home', 'ucfirst') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/jobs" @click="closeMenu()">{{ translate('jobs', 'ucfirst') }}</RouterLink>
          </li>
          <li v-if="[undefined, null, 0].includes(env('APP_BETA'))">
            <RouterLink to="/applicants" @click="closeMenu()">{{ translate('professionals', 'ucfirst') }}</RouterLink>
          </li>
          <li v-if="[undefined, null, 0].includes(env('APP_BETA'))">
            <RouterLink to="/companies" @click="closeMenu()">{{ translate('companies', 'ucfirst') }}</RouterLink>
          </li>
          <li v-if="[undefined, null, 0].includes(env('APP_BETA'))">
            <RouterLink to="/plans" @click="closeMenu()">{{ translate('plans', 'ucfirst') }}</RouterLink>
          </li>
        </ul>

        <div v-if="store.getters.isLoggedIn" class="flex items-center mt-5 md:space-x-2">
          <i v-if="[undefined, null, 0].includes(env('APP_BETA'))" class="pi pi-bell mr-2" style="font-size: 1.5rem; color: #3c496c"></i>
          <Avatar v-if="store.getters.getProfileImage != null" :image="store.getters.getProfileImage !== null ? store.getters.getProfileImage : require('@/assets/images/avatar.png')" shape="circle" />
          <p>{{ store.getters.getNameByProfile }}</p>
          <Button
            type="button"
            icon="pi pi-chevron-down"
            @click="toggle"
            style="font-size: 1.8rem;"
            aria-haspopup="true"
            aria-controls="home_user_submenu"
          />
          <Menu ref="menu" id="home_user_submenu" :model="items" :popup="true">
            <template #item="{ item, props }">
              <div v-if="item.profile.includes(store.getters.getLoggedAs) && (item.display == undefined || item.display == true)">
                <RouterLink
                  v-if="item.route"
                  v-slot="{ href, navigate }"
                  :to="item.route"
                  custom
                >
                  <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                    <span :class="item.icon" />
                    <span class="ml-2">{{ item.label }}</span>
                  </a>
                </RouterLink>
                <a
                  v-else
                  v-ripple
                  :href="item.url"
                  :target="item.target"
                  v-bind="props.action"
                  @click.prevent="callMethod(item.action)"
                >
                  <span :class="item.icon" />
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </div>
            </template>
          </Menu>
        </div>

        <div v-else class="flex items-center mt-5 md:space-x-2">
          <RouterLink to="/login" @click="closeMenu()" class="mr-3">{{ translate('enter', 'ucfirst') }}</RouterLink>
          <RouterLink to="/register" @click="closeMenu()" class="btn-register">{{ translate('register', 'ucfirst') }}</RouterLink>
        </div>
      </nav>
    </Sidebar>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { RouterLink } from "vue-router";
import Sidebar from "primevue/sidebar";
import Image from "primevue/image";
import Avatar from "primevue/avatar";
import store from "@/store";
import { translate } from "@/composables/translate";
import ProfileChooseComponent from "./ProfileChooseComponent.vue";
import { env } from "@/composables/config";
import Menu from "primevue/menu";
import Button from "primevue/button";
import requestHandler from "@/composables/requestHandler";
import authUser from "@/composables/authUser";
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";
import LoadingDialog from "./Utils/LoadingDialog.vue";

let toast = useToast();
const profileChoose = reactive({
  show: false,
  profileName: null
});

const visible = ref(false);

const toggle = (event) => {
  menu.value.toggle(event);
};

function closeMenu(){
  visible.value = false;
}

const loading = ref(false);
const menu = ref();
const items = ref([
  {
    label: "",
    items: [
      {
        label: translate('dashboard', 'ucfirst'),
        icon: "pi pi-home",
        route: `/dashboard/${store.getters.getLoggedAs !== null ? `${store.getters.getLoggedAs}/${store.getters.getUserSlug}` : 'profile'}`,
        profile: ['company','recruiter','professional',null]
      },
      {
        label: translate('publish job offer', 'ucfirst'),
        icon: "pi pi-file-edit",
        route: `/dashboard/${store.getters.getLoggedAs === null || store.getters.getLoggedAs !== 'company' ? 'profile' : `company/${store.getters.getUserSlug}/post-job`}`,
        profile: ['company']
      },
      {
        label: translate('switch account', 'ucfirst'),
        icon: "pi pi-user-edit",
        action: 'switchAccount',
        profile: ['company','recruiter','professional'],
        display: store.getters.getLoggedUserNotLoggedInProfile.length > 1 ? true : false 
      },
      {
        label: translate('end session'),
        icon: "pi pi-sign-out",
        name: "logout",
        action: 'logout',
        profile: ['company','recruiter','professional',null]
      },
    ],
  },
]);

function callMethod(methodName = ''){
  switch(methodName){
    case 'logout':
      logout();
    break;
    case 'switchAccount':
      switchAccount();
    break;
  }
}

async function logout(){
  loading.value = true;
  const response = await authUser.logout();
  if(!response['success']){
    showToast(toast, response['message'], 'error');
  }else{
    store.dispatch('saveSessionMessage', {message: translate('logged out with success'), messageType: 'success'});
    requestHandler.redirect('/login');
  }
  loading.value = false;
}

async function switchAccount(){
  if(profileChoose.profileName == null){
    profileChoose.show = true;
    return;
  }
  profileChoose.show = false;
  showToast(toast, translate('profile changed', 'ucfrist'));
}

// const props = defineProps(["user"]);
</script>

<style scoped></style>
