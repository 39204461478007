import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import NotFound from "@/views/NotFoundView.vue";
import Maintance from "@/views/MaintanceView.vue";

import Index from "@/views/HomeView.vue";
import Login from "@/views/LoginView.vue";
import Register from "@/views/RegisterView.vue";
import Plans from "@/views/PlansView.vue";
import Applicants from "@/views/applicants/ApplicantsView.vue";
import Applicant from "@/views/applicants/NameView.vue";
import ResetPassword from "@/views/reset-password/TokenView.vue";
import Companies from "@/views/companies/CompaniesView.vue";
import Jobs from "@/views/jobs/JobView.vue";
import About from "@/views/AboutView.vue";
import Faq from "@/views/FaqView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicyView.vue";
import Terms from "@/views/TermsView.vue";

//  Rotas do Dashboard da empresa
import DashboardCompany from "@/views/dashboard/company/IndexView.vue";
import DashboardCompanyPostjob from "@/views/dashboard/company/PostJobView.vue";
import DashboardCompanyJobs from "@/views/dashboard/company/JobsView.vue";
import DashboardCompanyJobDescription from "@/views/dashboard/company/JobDescriptionView.vue";
import DashboardCompanyProfile from "@/views/dashboard/company/ProfileView.vue";
import DashboardCompanyChat from "@/views/dashboard/company/ChatView.vue";
import DashboardCompanySettings from "@/views/dashboard/company/SettingView.vue";

//  Rotas do Dashboard do profissional
import DashboardApplicant from "@/views/dashboard/applicant/IndexView.vue";
 
import DashboardApplicantPNewCurriculo from "@/views/dashboard/applicant/CreateCurriculoView.vue";
import DashboardApplicantJobs from "@/views/dashboard/applicant/JobsView.vue";
import DashboardApplicantJobDescription from "@/views/dashboard/applicant/JobDescriptionView.vue";
import DashboardApplicantProfile from "@/views/dashboard/applicant/ProfileView.vue";
import DashboardApplicantChat from "@/views/dashboard/applicant/ChatView.vue";
import DashboardApplicantSettings from "@/views/dashboard/applicant/SettingView.vue";

//  Rotas do Dashboard do recrutador
import DashboardRecruiter from "@/views/dashboard/recruiter/IndexView.vue";
import DashboardRecruiterPostjob from "@/views/dashboard/recruiter/PostJobView.vue";
import DashboardRecruiterJobs from "@/views/dashboard/recruiter/JobsView.vue";
import DashboardRecruiterJobDescription from "@/views/dashboard/recruiter/JobDescriptionView.vue";
import DashboardRecruiterProfile from "@/views/dashboard/recruiter/ProfileView.vue";
import DashboardRecruiterChat from "@/views/dashboard/recruiter/ChatView.vue";
import DashboardRecruiterSettings from "@/views/dashboard/recruiter/SettingView.vue";
 
import store from "@/store";
import Cookies from "js-cookie";
import { translate } from "@/composables/translate";
import IndexView from "@/views/dashboard/person/IndexView.vue";
import JobDetailPageComponent from "@/components/Job/JobDetailPageComponent.vue";
import authUser from "@/composables/authUser";
import ipHandler from "@/composables/ipHandler";

const routes = [
  {
    path: "/",
    name: "public",
    component: DefaultLayout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: Index,
      },
      {
        path: "/login",
        component: Login,
      },
      {
        path: "/register",
        component: Register,
      },
      {
        path: "/plans",
        component: Plans,
      },
      {
        path: "/applicants",
        component: Applicants,
      },
      {
        path: "/applicants/:name",
        component: Applicant,
      },
      {
        path: "/companies",
        component: Companies,
      },
      {
        path: "/jobs",
        component: Jobs,
      },
      {
        path: "/reset-password/:token",
        component: ResetPassword,
      },
      {
        path: "/about",
        component: About,
      },
      {
        path: "/faq",
        component: Faq,
      },
      {
        path: "/privacy-policy",
        component: PrivacyPolicy,
      },
      {
        path: "/terms",
        component: Terms,
      }, 
      {
        path: "/reset-password",
        component: ResetPassword,
      },
      {
        path: "/job/:job_id",
        component: JobDetailPageComponent,
      },

    ],
  },
  // ########### Rotas Dashboard da EMPRESA, PROFISSIONAL E RECRUTADOR ###########
  {
    path: "/dashboard",
    component: DashboardLayout,
    redirect: "/",
    children: [
      // ########### Rotas empresa ###########
      {
        path: "/dashboard/company",
        name: "company",
        redirect: "/",
        children: [
          {
            path: ":name",
            component: DashboardCompany,
          },
          {
            path: ":name/jobs",
            component: DashboardCompanyJobs,
          },
          {
            path: ":name/post-job",
            component: DashboardCompanyPostjob,
          },
          {
            path: ":name/post-job/:job_id",
            component: DashboardCompanyPostjob,
          },
          {
            path: ":name/jobs",
            component: DashboardCompanyJobs,
          },
          {
            path: ":name/jobs/:job_id",
            component: DashboardCompanyJobDescription,
          },
          {
            path: ":name/profile",
            component: DashboardCompanyProfile,
          },
          {
            path: ":name/settings",
            component: DashboardCompanySettings,
          },
          {
            path: ":name/messages",
            component: DashboardCompanyChat,
          },
        ],
      },

      // ########### Rotas profissional ###########
      {
        path: "/dashboard/professional",
        name: "professional",
        redirect: "/",
        children: [
          {
            path: ":name",
            component: DashboardApplicant,
          },
          {
            path: ":name/jobs-application",
            component: DashboardApplicantJobs,
          },
          {
            path: ":name/create-curriculo",
            component: DashboardApplicantPNewCurriculo,
          },
          {
            path: ":name/jobs",
            component: DashboardApplicantJobs,
          },
          {
            path: ":name/jobs/:description",
            component: DashboardApplicantJobDescription,
          },
          {
            path: ":name/profile",
            component: DashboardApplicantProfile,
          },
          {
            path: ":name/settings",
            component: DashboardApplicantSettings,
          },
          {
            path: ":name/messages", 
            component: DashboardApplicantChat,
          }
        ],
      },

      // ########### Rotas Recrutador ###########
      {
        path: "/dashboard/recruiter",
        name: "recruiter",
        redirect: "/",
        children: [
          {
            path: ":name",
            component: DashboardRecruiter,
          },
          {
            path: ":name/jobs",
            component: DashboardRecruiterJobs,
          },
          {
            path: ":name/jobs",
            component: DashboardRecruiterPostjob,
          },
          {
            path: ":name/jobs",
            component: DashboardRecruiterJobs,
          },
          {
            path: ":name/post-job",
            component: DashboardRecruiterPostjob,
          },
          {
            path: ":name/jobs",
            component: DashboardRecruiterJobs,
          },
          {
            path: ":name/jobs/:description",
            component: DashboardRecruiterJobDescription,
          },
          {
            path: ":name/profile",
            component: DashboardRecruiterProfile,
          },
          {
            path: ":name/settings",
            component: DashboardRecruiterSettings,
          },
          {
            path: ":company/messages",
            component: DashboardRecruiterChat,
          },
        ],
      }, 

      // ########### Rotas person ###########
      {
        path: "/dashboard/profile",
        component: IndexView,
      }
    ], 
       
  },
  {
    path: "/maintance",
    name: "Maintance",
    component: Maintance,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('setRouteChanged', {url: to.path});
  next();
});

/**
 * Middleware to check auth routes
 */
router.beforeEach((to, from, next) => {
  ipHandler.detectIp();

  authUser.attemptLoginByCookies();

  if (to.path.startsWith('/dashboard')) {
    if (store.state.user.userLogged) {
      next();
    } else {
      if(!Cookies.get('aut_data')){
        if(!store.getters.isTryingToLogin){
          store.dispatch('saveSessionMessage', {message: translate('not authenticated'), messageType: 'error'});

        }
      }
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
