
import store from "@/store";
import requestHandler from "./requestHandler";
import { limitText } from "./textUtils";
import jobModality from "./jobModality";
import countries from "./country";
import seniority from "./seniority";
import drivingLicenses from "./drivingLicense";
import jobCertifications from "./jobCertification";
import professions from "./profession";
import workingVisas from "./workingVisa";
import jobPeriods from "./jobPeriod";
import commonCurrencies from "./commonCurrency";
import utils from "./utils";
import { ucfirst } from "./textUtils";
import { getBase64 } from "./imageChecker";
import languageData from "./languageData";
import jobContract from "./jobContract";
import jobPayingType from "./jobPayingType";
import typeVisa from "./typeVisa";

export default {
    async getJobs(perPage = 10, page = 1) {
        let params = {
            per_page: perPage,
            page: page,
            user_lang: store.getters.getUserLang
        };
        return requestHandler.executeRequest("api/joblist/index", params, 'get');
    },

    async searchForJob(job_id = '') {
        return requestHandler.executeRequest(`api/joblist/${job_id}`, {}, 'get');
    },

    async searchForMyCompanyJob(job_id = '') {
        return requestHandler.executeRequest('api/company/jobs', { job_id: job_id }, 'get');
    },

    readJobImages(job = {}, customCompanyLogo = 'img/logo-icon-jobifull.png', customCompanyCoverPhoto = '@/assets/images/img-cover.png') {
        job['company_logo'] = getBase64(job.company_logo, true, customCompanyLogo);
        job['company_cover_photo'] = getBase64(job.company_cover_photo, true, customCompanyCoverPhoto);
        return job;
    },

    async getMostRecentJobs() {
        let params = {
            per_page: 20,
            page: 1,
            user_lang: store.getters.getUserLang,
            filter: 'mostrecent'
        };
        return requestHandler.executeRequest("api/joblist/index", params, 'get');
    },

    getLocation(jobLocation = '') {
        if (jobLocation == '' || jobLocation == null)
            return '';
        let arr = jobLocation.split(',');
        if (arr.length == 1)
            return arr[0];
        return limitText(arr[0], 15) + ', ' + arr[1];
    },

    async getRelevantJobCategories(limit = 10) {
        let params = {
            per_page: limit,
            page: 1,
            user_lang: store.getters.getUserLang
        };
        return requestHandler.executeRequest("api/professions/index", params, 'get');
    },

    getCategoryPlaceholder() {
        return `${process.env.VUE_APP_BASE_URL}img/category-placeholder.jpg`;
    },

    async getJobComboData(only = null, rawAndProcessed = false) {
        let filtersAvaliable = {
            job_modality_id: [],
            job_country: [],
            job_seniority: [],
            job_seniority_level: [],
            job_driving_license: [],
            job_contract: [],
            job_certification: [],
            profession_for_job: [],
            working_visa: [],
            job_period: [],
            job_payment_type: [],
            wage_currency: [],
            languages: [],
            language_level: [],
            type_visas: [],
            ddi: []
        };
        let langIso = store.getters.getUserLang;
        if (!['en', 'pt', 'es'].includes(langIso))
            langIso = 'en';
        for (let name of Object.keys(filtersAvaliable)) {
            if (only !== null)
                name = only;
            let groupedData = [];
            let miscData = [];
            switch (name) {
                case 'job_modality_id':
                    filtersAvaliable[name] = utils.parseToArray(await jobModality.listJobModalities());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['job_modality_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_country':
                    filtersAvaliable[name] = utils.parseToArray(await countries.listCountries());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['lcountry_id']) ? object['lcountry_id'] : object['object_id'];
                        groupedData.push({ name: ucfirst(object[langIso]), code: id })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'ddi':
                    filtersAvaliable[name] = utils.parseToArray(await countries.listCountries());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['lcountry_id']) ? object['lcountry_id'] : object['object_id'];
                        groupedData.push({ 
                            name: ucfirst(object[langIso]), code: id, ddi: object['ddi'], flag: object['flag'], flagSrc: `data/flags/${object['lcountry_acronyn']}.svg`
                        })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_seniority':
                    filtersAvaliable[name] = utils.parseToArray(await seniority.listJobSeniority());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['proficiency_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_seniority_level':
                    filtersAvaliable[name] = utils.parseToArray(await seniority.listJobSeniority('level'));
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['proficiency_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_driving_license':
                    filtersAvaliable[name] = utils.parseToArray(await drivingLicenses.listDrivingLicenses());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['object_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_contract':
                    filtersAvaliable[name] = utils.parseToArray(await jobContract.listJobContract());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['object_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_payment_type':
                    filtersAvaliable[name] = utils.parseToArray(await jobPayingType.listjobPayingType());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['object_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'job_certification':
                    filtersAvaliable[name] = utils.parseToArray(await jobCertifications.listjobCertifications());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['object_id'] })
                        miscData.push(ucfirst(object[langIso]));
                    }
                    filtersAvaliable['test'] = miscData
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'profession_for_job':
                    filtersAvaliable[name] = utils.parseToArray(await professions.listProfessions());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['lprofession_id']) ? object['lprofession_id'] : object['object_id'];
                        groupedData.push({ name: ucfirst(object[langIso]), code: id })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'working_visa':
                    filtersAvaliable[name] = utils.parseToArray(await workingVisas.listWorkingVisas());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['object_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                break;
                case 'type_visas':
                    filtersAvaliable[name] = utils.parseToArray(await typeVisa.listTypeVisa());
                    for(let object of filtersAvaliable[name]){
                        groupedData.push({name: ucfirst(object[langIso]), code: object['typevisas_id'] != undefined ? object['typevisas_id'] : object['object_id']})
                    }
                    filtersAvaliable[name] = groupedData;
                break;
                case 'job_period':
                    filtersAvaliable[name] = utils.parseToArray(await jobPeriods.listJobPeriods());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['object_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'wage_currency':
                    filtersAvaliable[name] = utils.parseToArray(await commonCurrencies.listCommonCurrencies());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['common_currency_id']) ? object['common_currency_id'] : object['object_id'];
                        groupedData.push({ name: ucfirst(object[langIso]), code: id })
                    }
                    if (rawAndProcessed) {
                        filtersAvaliable[name] = {
                            'processed': groupedData,
                            'raw': filtersAvaliable[name]
                        }
                    } else {
                        filtersAvaliable[name] = groupedData;
                    }
                    break;
                case 'languages':
                    filtersAvaliable[name] = utils.parseToArray(await languageData.getAllLanguages());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['llangue_id']) ? object['llangue_id'] : object['object_id'];
                        groupedData.push({ name: ucfirst(object[langIso]), code: id })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'language_level':
                    filtersAvaliable[name] = utils.parseToArray(await seniority.listJobSeniority('language'));
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['proficiency_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                default:
                    return [];
            }
            if (only !== null)
                break;
        }
        return only ? filtersAvaliable[only] : filtersAvaliable;
    },

    async saveJob(form = {}) {
        let job_id = ![null, undefined, ''].includes(form.job_id) ? form.job_id : null;
        let response = false;
        if (job_id !== null) {
            response = await requestHandler.executeRequest(`/api/joblist/${job_id}`, form, 'put');
        } else {
            response = await requestHandler.executeRequest('/api/joblist/store', form, 'post');
        }
        return response;
    },

    async postJob(job_id = '') {
        return requestHandler.executeRequest('api/company/postjob', { job_id: job_id }, 'post');
    },

    async trashJob(job_id = '') {
        return requestHandler.executeRequest('api/company/trashjob', { job_id: job_id }, 'post');
    },

    async untrashJob(job_id = '') {
        return requestHandler.executeRequest('api/company/untrashjob', { job_id: job_id }, 'post');
    },

    async searchCompanyjob(job_id = '') {
        let cachedData = store.getters.getOnDataChest(`save_job_${job_id}`);
        let jobData = false;
        if (cachedData !== null && cachedData !== undefined && !utils.isEmpty(cachedData)) {
            jobData = cachedData;
        } else {
            const response = await requestHandler.executeRequest(`api/company/job/search/${job_id}`, {}, 'get');
            if (!response.success || response.data.data.length < 1) {
                return false;
            }
            jobData = response.data.data;
            store.dispatch('saveOnDataChest', {
                key: `save_job_${job_id}`,
                value: jobData
            });
        }
        return jobData;
    },

    async searchJobSkills(job_id = '') {
        let responseFormat = {
            skill: [],
            seniority: []
        }
        let jobData = await this.searchCompanyjob(job_id);
        if (!jobData)
            return responseFormat
        let skills = [];
        let seniorities = [];
        for (let index = 0; index < jobData.skillsNames.length; index++) {
            skills.push({
                code: jobData.skillsIds[index],
                name: jobData.skillsNames[index]
            });
            seniorities[`${jobData.skillsIds[index]}-${jobData.skillsNames[index].replace(' ', 'aaaa-aaaa-aaaa')}`] = {
                code: jobData.skillsProficiencyId[index],
                name: ucfirst(jobData.skillsProficiencyName[index])
            }
        }
        responseFormat.skill = skills;
        responseFormat.seniority = seniorities;
        return responseFormat;
    },

    async searchJobCertifications(job_id = ''){
        let responseFormat = {
            certification: [],
            country: []
        }
        let jobData = await this.searchCompanyjob(job_id);
        if(!jobData)
            return responseFormat
        let certifications = [];
        for(let index = 0; index < jobData.certifications.length; index++){
            certifications.push({
                code: jobData.certificationsIds[index],
                name: jobData.certifications[index]
            });
        }
        responseFormat.certification = certifications;
        return responseFormat;
    },

    async searchJobLanguages(job_id = '') {
        let responseFormat = {
            language: [],
            seniority: []
        }
        let jobData = await this.searchCompanyjob(job_id);
        if (!jobData)
            return responseFormat;
        let languages = [];
        let seniorities = [];
        for (let index = 0; index < jobData.languagesName.length; index++) {
            languages.push({
                code: jobData.languagesIds[index],
                name: ucfirst(jobData.languagesName[index])
            });
            seniorities[`${jobData.languagesIds[index]}-${ucfirst(jobData.languagesName[index]).replace(' ', 'aaaa-aaaa-aaaa')}`] = {
                code: jobData.languagesProficiencyId[index],
                name: ucfirst(jobData.languagesProficiencyName[index])
            }
        }
        return {
            language: languages,
            seniority: seniorities
        };
    },

    async searchDrivingLicenses(job_id = ''){
        let responseFormat = {
            drivingLicense: []
        }
        let jobData = await this.searchCompanyjob(job_id);
        if(!jobData)
            return responseFormat;
        let drivingLicenses = [];
        for(let index = 0; index < jobData.drivingLicenses.length; index++){
            drivingLicenses.push({
                code: jobData.drivingLicensesIds[index],
                name: ucfirst(jobData.jobDrivingLicensesNames[index])
            });
        }
        return {
            drivingLicense: drivingLicenses
        };
    },

    async searchTypeVisas(job_id = ''){
        let responseFormat = {
            visas: [],
            country: []
        }
        let jobData = await this.searchCompanyjob(job_id);
        if(!jobData)
            return responseFormat;
        let visas = [];
        let countries = [];
        for(let index = 0; index < jobData.visasIds.length; index++){
            visas.push({
                code: jobData.visasIds[index],
                name: ucfirst(jobData.visasNames[index])
            });
            countries[`${jobData.visasIds[index]}-${ucfirst(jobData.visasNames[index]).replace(' ', 'aaaa-aaaa-aaaa')}`] = {
                code: jobData.countriesIds[index],
                name: ucfirst(jobData.countriesNames[index])
            }
        }
        return {
            visas: visas,
            country: countries
        };
    },

    async updateMyCompanyJobs(jobId, jobData = []) {
        let currentCompanyJobs = store.getters.getOnDataChest('my_company_jobs');
        currentCompanyJobs = currentCompanyJobs !== null && currentCompanyJobs !== undefined && !utils.isEmpty(currentCompanyJobs) ? currentCompanyJobs : [];
        let updatedJobs = [];
        let found = false;
        for (let job of currentCompanyJobs) {
            if (job.job_id == jobId) {
                updatedJobs.push(jobData);
                found = true;
            } else {
                updatedJobs.push(job);
            }
        }
        if (!found)
            updatedJobs.push(jobData);
        store.dispatch('saveOnDataChest', {
            key: 'my_company_jobs',
            value: updatedJobs
        });
    },

    async getDataToApply(jobId) {
        return requestHandler.executeRequest(`api/joblist/${jobId}/getInformations`, {}, 'get');
    }
};