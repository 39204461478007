<template>
    <Dialog 
      v-model:visible="isShown"
      modal 
      :header="translate('filters', 'ucfirst')" 
      :style="{ width: '80%' }" 
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :draggable="false"
      :closable="true" 
      @hide="emitClosed"
    >
      <div class="flex flex-wrap mt-5 mb-5">
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.job_modality_id" 
            display="chip" 
            :options="filtersAvaliable.job_modality_id" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('job modalities', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.job_country" 
            display="chip" 
            :options="filtersAvaliable.job_country" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('job country', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.job_seniority" 
            display="chip" 
            :options="filtersAvaliable.job_seniority" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('job seniority', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
      </div>
      <div class="flex flex-wrap mt-5 mb-5">
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.job_driving_license" 
            display="chip" 
            :options="filtersAvaliable.job_driving_license" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('driving license', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.wage_currency" 
            display="chip" 
            :options="filtersAvaliable.wage_currency" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter
            :placeholder="translate('wage currency', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.profession_for_job" 
            display="chip" 
            :options="filtersAvaliable.profession_for_job" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('profession', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
      </div>
      <div class="flex flex-wrap mt-5 mb-5">
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.working_visa" 
            display="chip" 
            :options="filtersAvaliable.working_visa" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('working visa', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.job_period" 
            display="chip" 
            :options="filtersAvaliable.job_period"
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('job period', 'ucfirst')"
            class="w-full ps-3 pe-3" />
        </div>
        <div class="w-full md:w-1/3">
          <MultiSelect 
            v-model="filtersAvaliable.selected.job_certification" 
            display="chip" 
            :options="filtersAvaliable.job_certification" 
            :loading="filtersAvaliable.wage_currency.length < 1 ? true : false"
            optionLabel="name" 
            filter 
            :placeholder="translate('job certification', 'ucfirst')"
            class="w-full ps-3 pe-3"
            :hidden="true"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-5 mb-5">
        <label for="skillsAutocomplete">{{ translate('skills', 'ucfirst') }}:</label>
        <div class="w-full border-b mt-1">
          <AutoComplete
            inputId="skillsAutocomplete"
            v-model="skillName" 
            :suggestions="skillsList" 
            optionLabel="name"
            @complete="searchForSkills" 
            :placeholder="`${translate('management')}...`"
            :emptyMessage="translate('no results found', 'ucfirst')"
            :emptySearchMessage="translate('no results found', 'ucfirst')"
            multiple
            fluid 
            class="autocomplete-w-full"
          />
        </div>
      </div>

      <div>
        <Button
          v-if="!wageData.filterByWage"
          @click.prevent="wageData.filterByWage = true"
          icon="pi pi-filter"
          :label="translate('filter by wage', 'ucfirst')"
          class="mt-5 ps-3 pe-3 p-2 rounded-md me-3 bg-transparent text-oxford-blue border"
        />
        <Button
          v-if="wageData.filterByWage"
          @click.prevent="wageData.filterByWage = false"
          icon="pi pi-delete-left"
          :label="translate('erase wage filter', 'ucfirst')"
          class="mt-5 ps-3 pe-3 p-2 rounded-md me-3 bg-transparent text-oxford-blue border"
        />
      </div>
      <div class="flex flex-wrap mt-5 mb-5 justify-between" v-if="wageData.filterByWage">
        <div class="flex-col md:w-2/5 mt-3">
          <label>{{ translate('minimun wage', 'ucfirst') }}:</label>
          <div class="flex items-center mb-4">
            <i class="pi pi-money-bill me-2 text-xl"></i>
            <InputText v-model.number="minValue" class="w-full" />
          </div>
          <Slider v-model="minValue" :min="wageData.min" :max="maxValue" class="w-full" />
        </div>
        <div class="flex-col md:w-2/5 mt-3">
          <label>{{ translate('maximun wage', 'ucfirst') }}:</label>
          <div class="flex items-center mb-4">
            <i class="pi pi-money-bill me-2 text-xl"></i>
            <InputText v-model.number="maxValue" class="w-full" />
          </div>
          <Slider v-model="maxValue" :min="minValue" :max="wageData.max" class="w-full" />
        </div>
      </div>
      
      <div class="flex justify-end">
        <Button
          @click.prevent="emitExportSelectedFilters(filtersAvaliable.selected)"
          :label="translate('search', 'ucfirst')"
          class="mt-5 ps-3 pe-3 p-2 text-white rounded-md bg-sea-serpent"
        />
      </div>
  </Dialog>

</template>

<script setup> 
    import { ref, reactive, onMounted, watch } from "vue";
    import Dialog from "primevue/dialog";
    import AutoComplete from "primevue/autocomplete";
    import MultiSelect from "primevue/multiselect";
    import { translate } from "@/composables/translate";
    import { sleep } from "@/composables/sleep";
    import jobData from "@/composables/jobData";
    import utils from "@/composables/utils";
    import requestHandler from "@/composables/requestHandler";
    import store from "@/store";
    import Button from "primevue/button";
    import Slider from 'primevue/slider';
    import InputText from "primevue/inputtext";

    const props = defineProps({
      show: {
        type: Boolean,
        required: true,
        default: false
      },
    });

    let minValue = ref(1);
    let maxValue = ref(2);
    const wageData = reactive({
      min: 1,
      max: 50000,
      filterByWage: false
    });

    const isShown = ref(props.show);
    const emit = defineEmits({
      click: null,
      exportSelectedFilters: ({ filters }) => {
        return filters;
      },
      closed: () => {
        return true;
      }
    })
    function emitExportSelectedFilters(filters){
      if(wageData.filterByWage){
        filters['job_salary_start'] = minValue.value;
        filters['job_salary_end']   = maxValue.value;
      }else{
        filters['job_salary_start'] = null;
        filters['job_salary_end']   = null;
      }
      isShown.value = false;
      emit('exportSelectedFilters', { filters })
    }
    const emitClosed = () => {
      isShown.value = false;
      emit('closed');
    };

    watch(() => props.show, (newValue) => {
      isShown.value = newValue;
      handleAutoCompleteElements();
    });

    let filtersAvaliable = reactive({
        job_modality_id: [],
        job_country: [],
        job_seniority: [],
        job_driving_license: [],
        job_certification: [],
        profession_for_job: [],
        working_visa: [],
        job_period: [],
        wage_currency: [],
        selected: {
            job_modality_id: [],
            job_country: [],
            job_seniority: [],
            job_driving_license: [],
            job_certification: [],
            certification: [],
            profession_for_job: [],
            working_visa: [],
            job_period: [],
            wage_currency: [],
            job_skills: [],
            job_salary_start: null,
            job_salary_end: null
        }
    });
    async function getFilterData(){
      for(let key of Object.keys(filtersAvaliable)){
        if(key == 'selected')
          break;
        filtersAvaliable[key] = await jobData.getJobComboData(key);
      }
    }

    let skillName = ref();
    let skillsList = ref([]);
    async function searchForSkills(event){
      sleep(250);
      try {
        const response = await requestHandler.executeRequest('/api/tags/search', {term: event.query}, 'get');
        let values = [];
        let userLang = store.getters.getUserLang && ['en','pt','es'].includes(store.getters.getUserLang) ? store.getters.getUserLang : 'en';
        for(let skill of utils.parseToArray(response.data.data)){
          values.push({name: skill[userLang] == null ? skill['en'] : skill[userLang], code: skill['tags_id']});
        }
        skillsList.value = values;
        filtersAvaliable.selected.job_skills = values;
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }
    
    async function handleAutoCompleteElements(){
      let tries = 0;
      let autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
      while(autocompleteEls.length < 1){
          autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
          tries++;
          await sleep(50);
          if(tries == 30){
          break;
          }
      }
      if(autocompleteEls.length < 1)
          return;
      autocompleteEls.forEach(el => {
        if(el.style.width == '')
          el.style.setProperty('width', '100%', 'important');
          const ulEl = el.querySelector('ul');
          if(ulEl){
            ulEl.style.setProperty('width', '100%', 'important');
            let inputEl = ulEl.querySelector('input');
          if(inputEl){
            inputEl.style.setProperty('width', '100%', 'important');
            inputEl.style.setProperty('outline', 'unset', 'important');
          }
        }
      });
    }

    onMounted(() => {
      getFilterData();
      handleAutoCompleteElements();
    });
</script>