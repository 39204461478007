import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const jobPeriods = {
    async listJobPeriods() {
        try {
            let jobPeriodCombo = store.getters.getOnComboChest('job_period');
            if(jobPeriodCombo !== null && jobPeriodCombo !== undefined && !utils.isEmpty(jobPeriodCombo))
                return jobPeriodCombo;
            const result = await requestHandler.executeRequest('api/job_period/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'job_period',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default jobPeriods;