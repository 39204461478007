<template>
    <Dialog
      v-model:visible="emailVerification.show"
      modal
      :style="{ width: '35rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :draggable="false"
      :closable="props.isClosable"
      class="relative bg-white rounded-lg"
    >
      <h1 class="text-2xl font-bold text-center text-oxford-blue">{{ emailVerification.title }}</h1>

      <form class="flex flex-col items-center my-6">
        <InputText
            v-if="!emailVerification.optionsOnly"
            :disabled="handleEmailEdition()"
            v-model.trim="emailVerification.email"
            :placeholder="translate('email', 'ucfirst')"
            id="email"
            aria-describedby="email-help"
            class="w-full md:w-3/4 mb-1.5 py-2 px-3 rounded-lg border border-color-iron"
        />

        <template v-if="emailVerification.emailSendMenu">
            <small v-if="!emailVerification.optionsOnly" id="email-help">{{ translate('don\'t forget to check your email\'s junk or spam folder') }}.</small>
            <Button
                v-if="!emailVerification.emailSendOpts.awaitingEmailSend && !emailVerification.emailSendOpts.waiting"
                @click.prevent="sendVerificationEmailToUser()"
                :label="translate('send verification email', 'ucfirst')"
                class="w-full md:w-3/4 py-2 px-5 mt-4 text-white rounded-lg bg-cyan-500"
            />
            <LoaderComponent v-if="emailVerification.emailSendOpts.awaitingEmailSend" />
            <p v-if="emailVerification.emailSendOpts.waiting">Wait for {{ emailVerification.emailSendOpts.seconds - emailVerification.emailSendOpts.tracker }} {{ translate('before requesting new email') }}</p>
        
            <Button
                v-if="!emailVerification.emailSendOpts.awaitingEmailSend && !emailVerification.emailSendOpts.waiting"
                @click.prevent="openCodeMenu()"
                :label="translate('already have a code', 'ucfirst')"
                class="w-full md:w-3/4 py-2 px-5 mt-4 text-white rounded-lg bg-slate-500"
            />
        </template>

        <Divider />

        <template v-if="emailVerification.codeMenu">
            <InputText
                :disabled="emailVerification.codeOpts.waitingConfirmation"
                v-model.trim="emailVerification.code"
                :placeholder="translate('your verification code', 'ucfirst')"
                id="code"
                class="w-full md:w-3/4 mb-1.5 py-2 px-3 rounded-lg border border-color-iron"
            />
            <Button
                v-if="!emailVerification.codeOpts.waitingConfirmation"
                v-model.trim="emailVerification.code"
                :label="translate('verify code', 'ucfirst')"
                @click.prevent="validateCode()"
                class="w-full md:w-3/4 py-2 px-5 mt-4 text-white rounded-lg bg-sea-serpent"
            />
            <Button
                v-if="!emailVerification.codeOpts.waitingConfirmation"
                @click.prevent="openEmailSendMenu()"
                :label="translate('don\t have a code', 'ucfirst')"
                class="w-full md:w-3/4 py-2 px-5 mt-4 text-white rounded-lg bg-slate-500"
            />
            <LoaderComponent v-if="emailVerification.codeOpts.waitingConfirmation" />
        </template>

        <Divider />

        <template v-if="emailVerification.codeVerifiedMenu">
            <p>{{ translate('your email was verified!') }}</p>
            <Button
                :label="translate('proceed!')"
                @click.prevent="closeEmailVerificationDialog()"
                class="w-full md:w-3/4 py-2 px-5 mt-4 text-white rounded-lg bg-sea-serpent"
            />
        </template>

        <div class="w-full mt-5">
            <AlertComponent
                v-if="alerts.success.show"
                :message="alerts.success.message"
                :alertType="alerts.success.type"
                v-on:close="alerts.success.show = false"
            />
            <AlertComponent
                v-if="alerts.error.show"
                :message="alerts.error.message"
                :alertType="alerts.error.type"
                v-on:close="alerts.error.show = false"
            />
        </div>

        
      </form>
    </Dialog>
</template>

<script setup>
    import requestHandler from "@/composables/requestHandler";
    import Dialog from "primevue/dialog";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import { onMounted, reactive } from "vue";
    import { sleep } from "@/composables/sleep";
    import LoaderComponent from "./Utils/LoaderComponent.vue";
    import Divider from 'primevue/divider';
    import AlertComponent from "./Utils/AlertComponent.vue";
    import { translate } from "@/composables/translate";

    const alerts = reactive({
        success: {
            show: false,
            message: '',
            type: 'success'
        },
        error: {
            show: false,
            message: '',
            type: 'error'
        }
    })

    const props = defineProps({
        show: {
            type: Boolean,
            required: true,
            default: true
        },
        title: {
            type: String,
            required: false,
            default: 'verificar email'
        },
        openCodeMenu: {
            type: Boolean,
            required: false,
            default: false
        },
        email: {
            type: String,
            required: false,
            default: ''
        },
        redirectOnSuccess: {
            type: String,
            required: false,
            default: ''
        },
        isClosable: {
            type: Boolean,
            required: false,
            default: true
        },
        blockEmailEdition: {
            type: Boolean,
            required: false,
            default: false
        } 
    });

    const emailVerification = reactive({
        title: 'verificar email',
        email: '',
        code: '',
        show: true,

        codeMenu: false,
        codeOpts:{
            waitingConfirmation: false
        },

        emailSendMenu: true,
        emailSendOpts: {
            waiting: false,
            tracker: 0,
            seconds: 60,
            awaitingEmailSend: false
        },

        optionsOnly: true,

        codeVerifiedMenu: false
    });

    onMounted(function(){
        emailVerification.show = props.show;
        emailVerification.title = props.title;
        emailVerification.email = props.email;
        if(props.openCodeMenu)
            openCodeMenu();
    })

    async function startCounting(){
        emailVerification.emailSendOpts.waiting = true;
        emailVerification.emailSendOpts.tracker = 0;
        for(let i = 0; i < emailVerification.emailSendOpts.seconds; i++){
            await sleep(1000);
            emailVerification.emailSendOpts.tracker = emailVerification.emailSendOpts.tracker + 1;
        }
        emailVerification.emailSendOpts.tracker = 0;
        emailVerification.emailSendOpts.waiting = false;
    }

    async function validateCode(){
        if(emailVerification.email == ''){
            setAlertMessage(translate('no email informed'));
            return;
        }
        if(emailVerification.code == ''){
            setAlertMessage(translate('no code informed'));
            return;
        }
        emailVerification.codeOpts.waitingConfirmation = true;
        const response = await requestHandler.executeRequest('api/verifyemail', {email: emailVerification.email, code: emailVerification.code});
        emailVerification.emailSendOpts.awaitingEmailSend = false;
        emailVerification.codeOpts.waitingConfirmation = false;
        if(!response.success){
            setAlertMessage(response.message);
            return;
        }
        emailVerification.emailSendMenu = false;
        emailVerification.codeMenu = false
        emailVerification.codeVerifiedMenu = true;
    }

    async function sendVerificationEmailToUser(){
        if(emailVerification.email == ''){
            if(emailVerification.optionsOnly){
                emailVerification.optionsOnly = false;
                return;
            }
            setAlertMessage(translate('no email informed'));
            return;
        }
        emailVerification.emailSendOpts.awaitingEmailSend = true;
        const response = await requestHandler.executeRequest('api/requestemailconfirmationcode', {email: emailVerification.email});
        emailVerification.emailSendOpts.awaitingEmailSend = false;
        if(!response.success){
            setAlertMessage(response.message);
            return;
        }
        startCounting();
        setAlertMessage(response.message, 'success');
        emailVerification.codeMenu = true;
    }

    function closeEmailVerificationDialog(){
        emailVerification.email = '';
        emailVerification.code = '';
        emailVerification.show = false;
        emailVerification.codeMenu = false;
        emailVerification.waitingConfirmation = false;
        emailVerification.emailSendOpts.waiting = false;
        emailVerification.emailSendOpts.awaitingEmailSend = false;
        emailVerification.codeVerifiedMenu = false;
        if(props.redirectOnSuccess != '')
            requestHandler.redirect(props.redirectOnSuccess);
    }

    function setAlertMessage(message, type = 'error'){
        if(type == 'error'){
            alerts.success.show = false;
            alerts.success.message = '';
            alerts.error.show = true;
            alerts.error.message = message;
        }else{
            alerts.error.show = false;
            alerts.error.message = '';
            alerts.success.show = true;
            alerts.success.message = message;
        }
    }

    function openCodeMenu(){
        emailVerification.codeMenu = true; 
        emailVerification.emailSendMenu = false; 
        emailVerification.optionsOnly = false
    }

    function openEmailSendMenu(){
        emailVerification.codeMenu = false; 
        emailVerification.emailSendMenu = true;
        emailVerification.optionsOnly = false;
    }

    function handleEmailEdition(){
        if(props.blockEmailEdition && props.email != '')
            return true;
        return emailVerification.emailSendOpts.awaitingEmailSend || emailVerification.emailSendOpts.waiting || emailVerification.codeOpts.waiting || emailVerification.codeOpts.waitingConfirmation;
    }
</script>