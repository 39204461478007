<template>
  <div>
    <!-- eslint-disable-next-line -->
    <!-- eslint-disable  -->
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style="enable-background: new 0 0 500 500"
      xml:space="preserve"
    >
      <g id="Background_Complete">
        <g>
          <rect y="382.398" style="fill: #ebebeb" width="500" height="0.25" />
          <rect
            x="428.222"
            y="398.494"
            style="fill: #ebebeb"
            width="21.679"
            height="0.25"
          />
          <rect
            x="310.558"
            y="393.306"
            style="fill: #ebebeb"
            width="34.109"
            height="0.25"
          />
          <rect
            x="396.586"
            y="389.208"
            style="fill: #ebebeb"
            width="44.525"
            height="0.25"
          />
          <rect
            x="161.877"
            y="394.25"
            style="fill: #ebebeb"
            width="11.138"
            height="0.25"
          />
          <rect
            x="114.585"
            y="394.25"
            style="fill: #ebebeb"
            width="38.389"
            height="0.25"
          />
          <rect
            x="66.307"
            y="390.028"
            style="fill: #ebebeb"
            width="27.695"
            height="0.25"
          />
          <path
            style="fill: #ebebeb"
            d="M237.014,337.8H43.915c-3.147,0-5.708-2.561-5.708-5.708V60.66c0-3.147,2.561-5.708,5.708-5.708
			h193.099c3.146,0,5.707,2.561,5.707,5.708v271.432C242.721,335.239,240.16,337.8,237.014,337.8z M43.915,55.203
			c-3.01,0-5.458,2.448-5.458,5.458v271.432c0,3.01,2.448,5.458,5.458,5.458h193.099c3.009,0,5.457-2.448,5.457-5.458V60.66
			c0-3.009-2.448-5.458-5.457-5.458H43.915z"
          />
          <path
            style="fill: #ebebeb"
            d="M453.31,337.8H260.212c-3.147,0-5.707-2.561-5.707-5.708V60.66c0-3.147,2.561-5.708,5.707-5.708
			H453.31c3.148,0,5.708,2.561,5.708,5.708v271.432C459.019,335.239,456.458,337.8,453.31,337.8z M260.212,55.203
			c-3.009,0-5.457,2.448-5.457,5.458v271.432c0,3.01,2.448,5.458,5.457,5.458H453.31c3.01,0,5.458-2.448,5.458-5.458V60.66
			c0-3.009-2.448-5.458-5.458-5.458H260.212z"
          />
        </g>
        <g>
          <g>
            <path
              style="fill: #ebebeb"
              d="M216.072,200.878l0.45-13.141l-8.068-2.636c-0.332-4.49-1.083-8.924-2.151-13.196l6.838-5.042
				l-4.645-12.343l-8.458,0.681c-2.016-3.991-4.385-7.788-7.04-11.35l4.381-7.313l-9.008-9.611l-7.562,3.872
				c-3.406-2.895-6.996-5.516-10.839-7.834l1.225-8.394l-11.998-5.445l-5.497,6.442c-4.188-1.359-8.527-2.375-13.016-3.049
				l-2.044-8.228l-13.176-0.451l-2.602,8.069c-4.525,0.365-8.924,1.082-13.195,2.151l-5.078-6.805l-12.342,4.611l0.682,8.459
				c-3.992,2.05-7.788,4.418-11.35,7.074l-7.278-4.38l-9.646,8.972l3.871,7.596c-2.893,3.372-5.516,6.998-7.801,10.842l-8.392-1.259
				l-5.479,11.996l6.476,5.498c-1.358,4.189-2.411,8.562-3.049,13.018l-8.264,2.076l-0.45,13.141l8.102,2.639
				c0.331,4.523,1.083,8.924,2.152,13.194l-6.837,5.042l4.645,12.344l8.458-0.683c2.016,3.991,4.385,7.788,7.04,11.35l-4.382,7.313
				l9.009,9.612l7.561-3.873c3.372,2.894,6.997,5.518,10.84,7.836l-1.26,8.391l11.998,5.445l5.532-6.441
				c4.188,1.359,8.527,2.375,13.017,3.049l2.044,8.228l13.176,0.451l2.603-8.069c4.524-0.365,8.923-1.082,13.194-2.151l5.043,6.803
				l12.342-4.611l-0.648-8.456c3.993-2.05,7.754-4.421,11.35-7.076l7.279,4.382l9.645-8.973l-3.87-7.596
				c2.893-3.372,5.516-6.998,7.801-10.842l8.392,1.26l5.479-11.997l-6.477-5.498c1.359-4.188,2.41-8.526,3.049-13.016
				L216.072,200.878z M127.276,243.449c-28.778-0.986-51.313-25.12-50.327-53.899c0.987-28.813,25.121-51.346,53.899-50.36
				c28.778,0.986,51.313,25.118,50.326,53.931C180.188,221.9,156.054,244.435,127.276,243.449z"
            />
          </g>
          <g>
            <path
              style="fill: #ebebeb"
              d="M440.957,157.423l3.314-3.095l-1.324-2.61c1.014-1.176,1.912-2.44,2.693-3.742l2.897,0.438
				l1.877-4.142l-2.229-1.896c0.482-1.465,0.83-2.968,1.051-4.484l2.852-0.715l0.147-4.54l-2.788-0.9
				c-0.125-1.536-0.361-3.051-0.733-4.553l2.346-1.748l-1.598-4.253l-2.911,0.23c-0.69-1.353-1.5-2.659-2.43-3.917l1.508-2.505
				l-3.103-3.323L439.923,113c-1.193-1.015-2.44-1.912-3.742-2.693l0.421-2.898l-4.134-1.885l-1.896,2.229
				c-1.473-0.474-2.976-0.822-4.493-1.043l-0.707-2.843l-4.54-0.164l-0.908,2.796c-1.528,0.117-3.051,0.361-4.546,0.742
				l-1.739-2.353l-4.261,1.589l0.238,2.92c-1.353,0.69-2.676,1.5-3.918,2.431l-2.521-1.509l-3.314,3.095l1.332,2.619
				c-1.022,1.184-1.912,2.44-2.693,3.742l-2.897-0.438l-1.878,4.143l2.229,1.896c-0.482,1.465-0.83,2.968-1.051,4.484l-2.852,0.715
				l-0.147,4.541l2.788,0.899c0.117,1.528,0.361,3.052,0.732,4.554l-2.353,1.739l1.598,4.253l2.92-0.222
				c0.69,1.353,1.5,2.658,2.431,3.918l-1.508,2.505l3.103,3.323l2.602-1.333c1.192,1.014,2.44,1.912,3.742,2.693l-0.429,2.889
				l4.134,1.885l1.904-2.22c1.474,0.474,2.969,0.813,4.493,1.042l0.707,2.843l4.54,0.164l0.908-2.796
				c1.528-0.117,3.051-0.361,4.546-0.742l1.739,2.354l4.262-1.589l-0.239-2.92c1.352-0.69,2.667-1.491,3.918-2.43L440.957,157.423z
				 M417.341,138.292c-2.438-2.611-2.298-6.706,0.313-9.144c2.614-2.441,6.709-2.301,9.147,0.311
				c2.438,2.611,2.298,6.706-0.316,9.147C423.874,141.044,419.779,140.903,417.341,138.292z"
            />
          </g>
          <path
            style="fill: #ebebeb"
            d="M319.989,292.336l-10.341-7.142c-0.003-0.008-0.003-0.017-0.006-0.025
			c-1.023-3.757-2.434-7.373-4.159-10.772l2.649-10.398l-15-16.065l-10.559,1.933c-3.27-1.951-6.779-3.605-10.454-4.882
			c-0.008-0.002-0.018-0.004-0.026-0.008l-6.42-10.81l-21.965-0.753l-7.142,10.341c-0.013,0.003-0.027,0.007-0.041,0.01
			c-3.753,1.023-7.366,2.431-10.76,4.154l-10.393-2.649l-16.064,15.001l1.929,10.538c-0.002,0.004-0.004,0.005-0.006,0.009
			c-1.992,3.307-3.608,6.817-4.885,10.494l-10.808,6.417l-0.753,21.966l10.35,7.148c1.022,3.783,2.389,7.391,4.147,10.818
			c0.001,0.002,0.001,0.002,0.002,0.004l-2.642,10.366l15,16.063l10.493-1.92c3.312,1.958,6.826,3.614,10.546,4.893
			c0.003,0.002,0.005,0.002,0.009,0.002l6.409,10.794l21.965,0.753l7.137-10.334c3.797-1.021,7.415-2.431,10.851-4.158
			c0.001,0,0.002,0,0.003-0.002l10.346,2.636l16.064-14.999l-1.929-10.54c1.992-3.307,3.608-6.819,4.885-10.496
			c0,0,0-0.002,0.001-0.002l10.812-6.421L319.989,292.336z M251.537,325.726c-13.654-0.468-24.32-11.931-23.854-25.546
			c0.468-13.654,11.892-24.321,25.546-23.854c13.654,0.468,24.321,11.892,23.854,25.546
			C276.617,315.487,265.191,326.194,251.537,325.726z"
          />
          <path
            style="fill: #ebebeb"
            d="M343.548,229.954c-0.024,0-0.048,0-0.072-0.001l-17.317-0.593
			c-0.719-0.024-1.376-0.414-1.743-1.032l-4.663-7.852c-2.479-0.899-4.922-2.033-7.283-3.378l-7.507,1.373
			c-0.708,0.134-1.432-0.109-1.923-0.636l-11.826-12.663c-0.491-0.526-0.68-1.266-0.503-1.963l1.888-7.41
			c-1.147-2.347-2.107-4.854-2.859-7.47l-7.531-5.201c-0.592-0.408-0.935-1.091-0.911-1.809l0.593-17.317
			c0.024-0.719,0.414-1.376,1.032-1.743l7.866-4.671c0.933-2.554,2.064-4.985,3.37-7.248l-1.379-7.533
			c-0.129-0.707,0.11-1.433,0.636-1.923l12.665-11.827c0.526-0.492,1.266-0.68,1.963-0.503l7.435,1.895
			c2.405-1.17,4.904-2.134,7.449-2.871l5.197-7.525c0.408-0.592,1.079-0.958,1.81-0.91l17.317,0.594
			c0.719,0.024,1.376,0.413,1.743,1.032l4.672,7.866c2.482,0.909,4.906,2.04,7.227,3.372l7.554-1.384
			c0.709-0.129,1.433,0.11,1.924,0.636l11.826,12.666c0.491,0.526,0.68,1.266,0.503,1.963l-1.896,7.438
			c1.171,2.406,2.134,4.904,2.872,7.446l7.525,5.196c0.592,0.408,0.935,1.091,0.911,1.81l-0.593,17.318
			c-0.024,0.719-0.414,1.376-1.032,1.743l-7.869,4.673c-0.933,2.553-2.062,4.983-3.366,7.243l1.379,7.536
			c0.129,0.707-0.11,1.433-0.636,1.923l-12.665,11.824c-0.525,0.492-1.264,0.682-1.962,0.503l-7.401-1.886
			c-2.439,1.178-4.952,2.141-7.488,2.87l-5.192,7.519C344.891,229.614,344.241,229.954,343.548,229.954z M327.457,225.179
			l15.009,0.515l4.971-7.198c0.284-0.411,0.706-0.709,1.189-0.839c2.765-0.743,5.508-1.795,8.155-3.126
			c0.005-0.002,0.009-0.004,0.014-0.007c0.441-0.217,0.955-0.284,1.458-0.153l7.038,1.793l10.977-10.249l-1.313-7.174
			c-0.093-0.506,0.003-1.028,0.268-1.47c1.448-2.403,2.676-5.039,3.65-7.834c0.16-0.496,0.499-0.913,0.941-1.173l7.523-4.468
			l0.514-15.01l-7.203-4.975c-0.377-0.26-0.659-0.636-0.804-1.069c-0.018-0.054-0.034-0.111-0.048-0.169
			c-0.753-2.758-1.801-5.468-3.114-8.055c-0.232-0.457-0.289-0.982-0.164-1.478l1.804-7.078l-10.25-10.979l-7.189,1.316
			c-0.504,0.093-1.023-0.002-1.462-0.264c-2.5-1.492-5.14-2.725-7.846-3.666c-0.153-0.053-0.308-0.124-0.441-0.205
			c-0.292-0.18-0.535-0.427-0.709-0.721l-4.472-7.529l-15.009-0.515l-4.975,7.204c-0.296,0.429-0.739,0.731-1.243,0.852
			c-2.777,0.761-5.478,1.805-8.054,3.112c-0.457,0.232-0.983,0.29-1.477,0.163l-7.075-1.804l-10.977,10.251l1.313,7.173
			c0.083,0.45,0.016,0.915-0.188,1.324c-0.029,0.058-0.061,0.114-0.095,0.17c-1.451,2.412-2.681,5.056-3.654,7.859
			c-0.164,0.47-0.488,0.868-0.916,1.122l-7.528,4.471l-0.514,15.009l7.21,4.98c0.411,0.283,0.708,0.704,0.839,1.186
			c0.777,2.876,1.823,5.607,3.11,8.117c0.074,0.144,0.132,0.296,0.171,0.454c0.085,0.339,0.084,0.694-0.002,1.033l-1.798,7.054
			l10.25,10.976l7.138-1.306c0.501-0.091,1.017,0.002,1.454,0.26c2.545,1.505,5.198,2.737,7.888,3.665
			c0.486,0.154,0.907,0.483,1.174,0.934L327.457,225.179z M352.241,130.367h0.01H352.241z M336.728,206.214
			c-0.37,0-0.739-0.007-1.111-0.02c-17.446-0.598-31.153-15.277-30.555-32.723c0.598-17.445,15.301-31.148,32.723-30.556
			c17.446,0.598,31.153,15.277,30.555,32.724c-0.289,8.44-3.85,16.269-10.026,22.042
			C352.403,203.207,344.774,206.214,336.728,206.214z M336.69,147.12c-14.698,0-26.9,11.695-27.407,26.496
			c-0.518,15.118,11.36,27.839,26.479,28.357c7.326,0.245,14.311-2.368,19.669-7.377c5.353-5.004,8.439-11.788,8.689-19.102
			c0.518-15.118-11.361-27.84-26.479-28.358C337.323,147.126,337.005,147.12,336.69,147.12z"
          />
          <g>
            <polygon
              style="fill: #e0e0e0"
              points="383.439,105.323 376.265,103.397 374.339,96.224 377.148,93.415 380.111,99.551 
				386.247,102.515 			"
            />
            <path
              style="fill: #e0e0e0"
              d="M383.884,100.521c1.307-1.307,1.307-3.437,0-4.744c-1.307-1.307-3.427-1.297-4.734,0.01
				l3.568-5.353l4.786,2.419l1.049,5.029L383.884,100.521z"
            />
            <path
              style="fill: #f0f0f0"
              d="M376.582,93.981l5.257-5.257l7.173,1.926l1.927,7.174l-5.257,5.257l-7.174-1.927L376.582,93.981z
				 M386.127,98.279c1.307-1.307,1.307-3.437,0-4.744c-1.307-1.307-3.427-1.297-4.734,0.01c-1.307,1.307-1.307,3.418,0,4.724
				C382.7,99.576,384.82,99.586,386.127,98.279z"
            />
          </g>
          <g>
            <polygon
              style="fill: #e0e0e0"
              points="90.221,296.708 94.07,295.674 95.104,291.825 93.597,290.318 92.007,293.611 
				88.714,295.201 			"
            />
            <path
              style="fill: #e0e0e0"
              d="M89.982,294.131c-0.701-0.701-0.701-1.844,0-2.546c0.701-0.701,1.839-0.696,2.54,0.005
				l-1.915-2.873l-2.568,1.298l-0.563,2.698L89.982,294.131z"
            />
            <path
              style="fill: #f0f0f0"
              d="M93.901,290.622l-2.821-2.821l-3.849,1.034l-1.034,3.85l2.821,2.821l3.849-1.034L93.901,290.622z
				 M88.778,292.928c-0.701-0.701-0.701-1.844,0-2.546c0.701-0.701,1.839-0.696,2.54,0.005s0.701,1.834,0,2.535
				C90.618,293.624,89.48,293.629,88.778,292.928z"
            />
          </g>
          <g>
            <path
              style="fill: #f0f0f0"
              d="M76.363,85.662l3.195,3.195c0.832,0.832,2.193,0.832,3.025,0l0,0c0.832-0.832,0.832-2.193,0-3.025
				l-3.195-3.195c-0.832-0.832-2.193-0.832-3.025,0l0,0C75.531,83.469,75.531,84.831,76.363,85.662z"
            />
            <path
              style="fill: #e0e0e0"
              d="M74.112,77.705c2.462-0.884,5.131,0.272,5.961,2.583c0.83,2.311-0.493,4.901-2.955,5.786
				c-2.462,0.884-5.131-0.272-5.961-2.583C70.327,81.18,71.65,78.59,74.112,77.705z"
            />

            <rect
              x="74.93"
              y="76.839"
              transform="matrix(-0.338 -0.9411 0.9411 -0.338 24.1067 180.7352)"
              style="fill: #f0f0f0"
              width="1.37"
              height="10.102"
            />
          </g>
        </g>
      </g>
      <g id="Background_Simple" style="display: none">
        <g style="display: inline">
          <path
            style="fill: #407bff"
            d="M418.911,125.423c39.287,31.84,35.519,75.55,1.844,100.469
			c-16.15,11.951-48.67,9.635-67.166,18.072c-24.13,11.006-36.378,31.389-54.87,47.664c-45.994,40.48-60.954,20.469-113.319,20.999
			C148.585,313,113.588,344.03,76.704,322.724c-45.078-26.04-17.163-78.178,19.643-98.308
			c42.273-23.12,15.679-77.469,76.485-117.507C232.646,67.524,360.561,76.811,418.911,125.423z"
          />
          <path
            style="opacity: 0.9; fill: #ffffff"
            d="M418.911,125.423c39.287,31.84,35.519,75.55,1.844,100.469
			c-16.15,11.951-48.67,9.635-67.166,18.072c-24.13,11.006-36.378,31.389-54.87,47.664c-45.994,40.48-60.954,20.469-113.319,20.999
			C148.585,313,113.588,344.03,76.704,322.724c-45.078-26.04-17.163-78.178,19.643-98.308
			c42.273-23.12,15.679-77.469,76.485-117.507C232.646,67.524,360.561,76.811,418.911,125.423z"
          />
        </g>
      </g>
      <g id="Shadow_1_">
        <ellipse
          id="_x3C_Path_x3E__373_"
          style="fill: #f5f5f5"
          cx="250"
          cy="416.238"
          rx="193.889"
          ry="11.323"
        />
      </g>
      <g id="Oops_x21_">
        <g>
          <path
            style="fill: #263238"
            d="M86.716,120.646c-1.001-4.403-0.554-8.11,1.342-11.123c1.896-3.011,5.034-5.014,9.413-6.01
			c4.49-1.021,8.223-0.602,11.2,1.257c2.977,1.858,4.959,4.958,5.946,9.301c0.717,3.153,0.774,5.859,0.172,8.118
			c-0.603,2.26-1.779,4.179-3.529,5.757c-1.751,1.579-4.123,2.707-7.116,3.388c-3.042,0.692-5.67,0.78-7.884,0.265
			c-2.214-0.516-4.179-1.682-5.895-3.499C88.649,126.284,87.432,123.8,86.716,120.646z M95.06,118.789
			c0.619,2.724,1.57,4.565,2.853,5.524c1.282,0.961,2.794,1.241,4.537,0.845c1.791-0.406,3.045-1.304,3.761-2.692
			s0.732-3.592,0.045-6.609c-0.577-2.539-1.511-4.278-2.802-5.218c-1.291-0.938-2.813-1.208-4.567-0.809
			c-1.681,0.382-2.895,1.284-3.643,2.705C94.496,113.957,94.435,116.041,95.06,118.789z"
          />
          <path
            style="fill: #263238"
            d="M118.593,117.347c-0.678-2.98-0.23-5.666,1.343-8.056c1.572-2.39,4.07-3.974,7.493-4.751
			c3.913-0.89,7.127-0.428,9.643,1.388c2.022,1.463,3.338,3.531,3.946,6.205c0.683,3.007,0.247,5.695-1.311,8.068
			c-1.557,2.373-4.096,3.96-7.617,4.76c-3.14,0.714-5.861,0.495-8.162-0.66C121.099,122.868,119.321,120.55,118.593,117.347z
			 M126.097,115.623c0.396,1.741,1.041,2.949,1.937,3.624c0.895,0.674,1.876,0.889,2.943,0.646c1.079-0.246,1.872-0.858,2.378-1.838
			c0.506-0.979,0.553-2.377,0.141-4.191c-0.385-1.693-1.021-2.875-1.909-3.544c-0.888-0.669-1.847-0.887-2.877-0.652
			c-1.092,0.248-1.898,0.874-2.418,1.876C125.771,112.545,125.706,113.905,126.097,115.623z"
          />
          <path
            style="fill: #263238"
            d="M148.585,128.566l-6.133-26.977l7.011-1.595l0.657,2.889c0.693-1.434,1.395-2.457,2.107-3.071
			c0.961-0.824,2.104-1.388,3.429-1.688c2.613-0.595,4.861-0.054,6.745,1.62c1.884,1.675,3.161,3.984,3.83,6.929
			c0.739,3.251,0.524,5.909-0.645,7.975c-1.169,2.065-2.943,3.369-5.323,3.91c-1.153,0.262-2.249,0.305-3.29,0.128
			c-1.04-0.177-2.022-0.565-2.946-1.168l2.121,9.329L148.585,128.566z M152.194,109.709c0.351,1.546,0.937,2.619,1.757,3.22
			c0.82,0.601,1.727,0.787,2.721,0.562c0.871-0.198,1.52-0.723,1.945-1.574c0.425-0.852,0.443-2.136,0.053-3.854
			c-0.36-1.583-0.931-2.675-1.713-3.277c-0.782-0.604-1.615-0.804-2.498-0.604c-0.956,0.218-1.669,0.774-2.137,1.666
			C151.854,106.742,151.811,108.028,152.194,109.709z"
          />
          <path
            style="fill: #263238"
            d="M169.435,110.299l7.275-2.39c0.507,0.813,1.081,1.348,1.719,1.604
			c0.638,0.254,1.418,0.277,2.338,0.068c1.005-0.229,1.736-0.62,2.191-1.175c0.357-0.416,0.481-0.862,0.372-1.341
			c-0.122-0.539-0.499-0.892-1.13-1.057c-0.452-0.116-1.584-0.129-3.395-0.04c-2.704,0.139-4.605,0.129-5.706-0.026
			c-1.1-0.157-2.101-0.615-3.003-1.377c-0.902-0.761-1.487-1.731-1.754-2.907c-0.292-1.287-0.17-2.48,0.366-3.582
			c0.537-1.102,1.409-2.032,2.617-2.79c1.208-0.758,2.916-1.388,5.124-1.89c2.331-0.53,4.092-0.742,5.283-0.64
			c1.191,0.104,2.243,0.445,3.155,1.024c0.913,0.58,1.759,1.451,2.539,2.616l-6.944,2.314c-0.326-0.585-0.737-0.975-1.233-1.171
			c-0.679-0.26-1.435-0.295-2.269-0.104c-0.847,0.192-1.429,0.482-1.747,0.87c-0.318,0.388-0.429,0.797-0.331,1.226
			c0.108,0.478,0.436,0.783,0.982,0.916c0.546,0.135,1.664,0.111,3.352-0.066c2.554-0.283,4.496-0.313,5.825-0.086
			c1.329,0.226,2.421,0.732,3.277,1.517c0.855,0.785,1.41,1.735,1.664,2.851c0.256,1.128,0.165,2.302-0.273,3.522
			c-0.439,1.221-1.319,2.313-2.642,3.278c-1.322,0.963-3.247,1.733-5.774,2.309c-3.57,0.812-6.229,0.879-7.975,0.206
			C171.592,113.304,170.291,112.078,169.435,110.299z"
          />
          <path
            style="fill: #263238"
            d="M193.849,85.566l8.208-0.046l0.036,6.303l-1.514,12.671l-5.076,0.028l-1.619-12.653L193.849,85.566
			z M194.25,106.454l7.661-0.043l0.039,6.774l-7.661,0.043L194.25,106.454z"
          />
        </g>
      </g>
      <g id="_x34_04">
        <g>
          <g>
            <path
              style="fill: #263238"
              d="M128.68,171.581c-0.06,0-0.121-0.011-0.181-0.034c-4.501-1.743-7.194-3.446-8.592-5.492
				c-1.016,0.204-2.153,0.069-3.232-0.444c-1.048-0.498-2.057-1.369-2.781-2.622c-0.675,0.521-1.509,0.663-2.425,0.375
				c-1.016-0.319-2.073-1.175-2.952-2.411c-0.559,0.404-1.132,0.465-1.552,0.43c-2.973-0.242-7.001-6.16-7.29-12.467
				c-0.013-0.275,0.201-0.51,0.477-0.521c0.27-0.023,0.51,0.2,0.522,0.477c0.274,5.985,4.109,11.331,6.373,11.515
				c0.367,0.036,0.674-0.079,0.932-0.279c-1.358-2.383-2.063-5.781-1.023-9.412c0.86-3.005,1.62-4.18,2.532-3.962
				c1.723,0.429,1.497,6.972,0.584,11.288c-0.207,0.979-0.505,1.681-0.845,2.183c0.789,1.165,1.724,1.949,2.543,2.207
				c0.732,0.23,1.274,0.035,1.674-0.346c-0.786-1.936-0.949-4.574,0.118-7.939c1.404-4.429,1.969-4.549,2.307-4.62
				c0.238-0.051,0.476,0.028,0.645,0.22c0.924,1.043-0.18,7.922-1.344,11.324c-0.151,0.442-0.339,0.829-0.557,1.157
				c0.621,1.217,1.536,2.046,2.493,2.501c0.768,0.365,1.556,0.498,2.273,0.431c-0.09-0.189-0.171-0.381-0.244-0.576
				c-0.793-2.147-0.405-4.626,1.185-7.578c1.055-1.958,1.915-2.871,2.707-2.872c0.001,0,0.001,0,0.001,0
				c0.235,0,0.675,0.087,0.986,0.662c0.872,1.609,0.241,6.919-1.483,9.554c-0.406,0.621-0.967,1.092-1.618,1.398
				c1.34,1.762,3.884,3.314,7.949,4.89c0.257,0.1,0.385,0.389,0.286,0.646C129.07,171.46,128.88,171.581,128.68,171.581z
				 M123.026,155.111c-0.003,0-0.565,0.004-1.827,2.347c-1.446,2.685-1.815,4.895-1.127,6.757c0.084,0.228,0.184,0.45,0.3,0.67
				c0.534-0.229,0.991-0.601,1.321-1.104c1.4-2.141,2.071-6.538,1.55-8.257C123.136,155.172,123.027,155.111,123.026,155.111z
				 M115.854,150.898c-0.258,0.485-0.692,1.484-1.34,3.529c-0.858,2.708-0.863,4.884-0.385,6.548
				c0.033-0.084,0.064-0.168,0.093-0.251C115.398,157.286,115.971,152.55,115.854,150.898z M109.268,147.748
				c-0.169,0.155-0.655,0.785-1.352,3.221c-0.919,3.213-0.353,6.144,0.727,8.246c0.207-0.434,0.351-0.934,0.45-1.402
				C110.13,152.902,109.828,148.459,109.268,147.748z"
            />
            <path
              style="fill: #263238"
              d="M166.698,223.997c-1.525,0-3.129-0.348-4.561-1.189c-0.238-0.141-0.318-0.446-0.178-0.685
				c0.139-0.239,0.446-0.316,0.684-0.179c3.567,2.095,8.178,0.633,9.127-0.893c0.11-0.179,0.211-0.406,0.147-0.671
				c-1.411,0.149-3.147-0.225-5.131-1.466c-2.545-1.591-2.741-2.064-2.725-2.442c0.01-0.224,0.137-0.419,0.341-0.523
				c0.996-0.505,5.505,1.574,7.385,2.81c0.246,0.162,0.454,0.337,0.622,0.522c0.831-0.202,1.475-0.632,1.903-1.145
				c0.294-0.353,0.49-0.737,0.587-1.122c-0.066,0.01-0.133,0.018-0.2,0.024c-1.599,0.161-3.203-0.525-4.911-2.104
				c-1.174-1.085-1.63-1.833-1.478-2.426c0.048-0.189,0.206-0.525,0.719-0.653c1.383-0.344,4.797,1.111,6.193,2.642
				c0.314,0.344,0.528,0.749,0.642,1.184c1.305-0.613,2.685-1.988,4.319-4.271c0.161-0.225,0.473-0.276,0.698-0.115
				c0.225,0.16,0.276,0.473,0.116,0.697c-1.889,2.64-3.464,4.136-5.046,4.746c-0.062,0.704-0.353,1.42-0.869,2.04
				c-0.507,0.607-1.246,1.129-2.181,1.411c0.1,0.45,0.01,0.926-0.28,1.392C171.776,222.938,169.358,223.997,166.698,223.997z
				 M165.81,217.061c0.336,0.248,0.818,0.576,1.506,1.007c1.357,0.849,2.566,1.24,3.6,1.322
				C169.259,218.363,167.092,217.454,165.81,217.061z M169.3,212.813c0.056,0.156,0.278,0.567,1.166,1.388
				c1.486,1.374,2.838,1.985,4.131,1.844c0.106-0.011,0.213-0.026,0.32-0.048c-0.071-0.304-0.216-0.585-0.434-0.825
				C173.076,213.629,169.983,212.652,169.3,212.813z"
            />
            <path
              style="fill: #407bff"
              d="M179.942,222.625l-15.582,2.83l-14.147-77.212l-30.064,5.461l-48.989,86.999l5.26,28.708
				l62.837-11.414l4.302,23.481l30.064-5.461l-4.302-23.48l15.582-2.831L179.942,222.625z M101.094,236.948l25.959-45.567
				l7.244,39.536L101.094,236.948z"
            />
          </g>
          <g>
            <path
              style="fill: #407bff"
              d="M305.43,142.992c-0.582-5.334-1.647-10.073-3.197-14.218c-1.549-4.145-3.88-8.208-6.99-12.189
				c-3.11-3.981-7.345-7.437-12.704-10.366c-5.36-2.928-12.434-4.967-21.223-6.112c-18.292-2.383-32.551,0.865-42.782,9.74
				c-10.232,8.877-16.963,25.881-20.198,51.011c-1.316,10.221-1.642,19.669-0.977,28.345c0.663,8.675,2.255,15.653,4.778,20.933
				c3.451,7.419,8.335,13.344,14.649,17.772c6.313,4.429,14.962,7.36,25.95,8.791c19.597,2.553,34.119-1.208,43.569-11.287
				c9.448-10.077,15.717-27.112,18.805-51.101C306.489,163.608,306.595,153.169,305.43,142.992z"
            />
            <path
              style="fill: #263238"
              d="M251.766,226.433c-2.405-0.082-4.964-0.299-7.607-0.644c-9.154-1.192-16.237-3.509-21.053-6.888
				c-4.752-3.332-8.347-7.716-10.992-13.406l-0.049-0.103c-1.923-4.023-3.179-9.753-3.735-17.028
				c-0.605-7.888-0.296-16.669,0.918-26.102c3.569-27.721,10.941-39.287,16.497-44.107c6.478-5.62,15.221-8.16,26.73-7.766
				c2.358,0.081,4.855,0.292,7.421,0.626c7.386,0.962,13.23,2.596,17.37,4.857c4.009,2.191,7.142,4.71,9.31,7.485
				c2.404,3.076,4.205,6.195,5.354,9.269c1.223,3.27,2.086,7.159,2.566,11.558c1.065,9.309,0.964,18.953-0.293,28.722
				c-2.773,21.539-8.129,36.673-15.92,44.981c-5.779,6.165-14.452,8.961-26.514,8.547
				C251.767,226.433,251.766,226.433,251.766,226.433z"
            />
            <g>
              <path
                style="fill: #407bff"
                d="M271.336,143.287l0.13-3.788l-2.325-0.76c-0.096-1.294-0.312-2.572-0.62-3.804l1.971-1.453
					l-1.339-3.558l-2.438,0.196c-0.581-1.15-1.264-2.245-2.029-3.272l1.263-2.108l-2.597-2.77l-2.18,1.116
					c-0.982-0.834-2.017-1.59-3.125-2.258l0.353-2.42l-3.458-1.57l-1.584,1.857c-1.207-0.392-2.458-0.685-3.752-0.879l-0.589-2.372
					l-3.798-0.13l-0.75,2.326c-1.305,0.105-2.572,0.312-3.803,0.62l-1.464-1.961l-3.558,1.329l0.197,2.438
					c-1.151,0.591-2.245,1.273-3.272,2.039l-2.098-1.262l-2.78,2.586l1.116,2.189c-0.834,0.972-1.59,2.017-2.249,3.125l-2.419-0.363
					l-1.579,3.458l1.867,1.585c-0.392,1.208-0.695,2.468-0.879,3.752l-2.382,0.598l-0.13,3.788l2.335,0.761
					c0.095,1.304,0.312,2.572,0.62,3.803l-1.971,1.453l1.339,3.558l2.438-0.197c0.581,1.15,1.264,2.245,2.029,3.272l-1.263,2.108
					l2.597,2.771l2.179-1.116c0.972,0.834,2.017,1.59,3.124,2.259l-0.363,2.419l3.458,1.57l1.595-1.857
					c1.207,0.392,2.458,0.685,3.752,0.879l0.589,2.372l3.798,0.13l0.75-2.326c1.304-0.105,2.572-0.312,3.803-0.62l1.454,1.961
					l3.558-1.329l-0.187-2.438c1.151-0.591,2.235-1.274,3.272-2.04l2.098,1.263l2.78-2.587l-1.116-2.189
					c0.834-0.972,1.59-2.017,2.248-3.125l2.419,0.363l1.579-3.458l-1.867-1.585c0.392-1.207,0.695-2.458,0.879-3.752
					L271.336,143.287z M245.741,155.558c-8.295-0.284-14.791-7.241-14.507-15.536c0.285-8.305,7.241-14.8,15.536-14.516
					c8.295,0.284,14.791,7.24,14.506,15.545C260.993,149.346,254.036,155.842,245.741,155.558z"
              />
            </g>
            <g>
              <path
                style="opacity: 0.3; fill: #407bff"
                d="M287.122,212.865l0.275-2.553l-1.535-0.626c-0.005-0.878-0.081-1.747-0.227-2.594
					l1.4-0.889l-0.711-2.47l-1.662,0.013c-0.335-0.807-0.73-1.582-1.196-2.311l0.948-1.36l-1.605-1.998l-1.531,0.638
					c-0.611-0.606-1.271-1.167-1.991-1.671l0.372-1.626l-2.251-1.235l-1.176,1.188c-0.807-0.344-1.641-0.595-2.477-0.787
					l-0.283-1.636l-2.553-0.286l-0.626,1.535c-0.878,0.005-1.735,0.093-2.594,0.239l-0.888-1.412l-2.471,0.723l0.013,1.662
					c-0.796,0.336-1.57,0.731-2.311,1.197l-1.36-0.96l-1.997,1.605l0.638,1.531c-0.595,0.612-1.167,1.271-1.672,1.991l-1.615-0.36
					l-1.235,2.251l1.177,1.164c-0.332,0.82-0.594,1.642-0.787,2.489l-1.636,0.271l-0.275,2.553l1.534,0.638
					c-0.006,0.866,0.081,1.735,0.228,2.583l-1.411,0.888l0.723,2.471l1.662-0.002c0.336,0.796,0.73,1.57,1.197,2.3l-0.959,1.36
					l1.605,2.009l1.544-0.649c0.611,0.606,1.27,1.167,1.991,1.672l-0.372,1.626l2.251,1.236l1.175-1.177
					c0.808,0.332,1.642,0.583,2.478,0.775l0.283,1.636l2.553,0.286l0.626-1.535c0.866-0.005,1.735-0.092,2.594-0.238l0.888,1.411
					l2.459-0.723l-0.001-1.662c0.796-0.336,1.57-0.73,2.311-1.196l1.348,0.959l2.009-1.604l-0.65-1.532
					c0.606-0.611,1.167-1.271,1.683-1.99l1.614,0.359l1.235-2.251l-1.177-1.164c0.332-0.819,0.583-1.642,0.775-2.478
					L287.122,212.865z M274.249,211.874c-1.186,2.159-3.905,2.956-6.064,1.77c-2.159-1.186-2.944-3.904-1.758-6.063
					c1.186-2.159,3.892-2.944,6.052-1.759C274.638,207.008,275.434,209.715,274.249,211.874z"
              />
            </g>
            <path
              style="fill: #ffffff"
              d="M301.29,169.649l-2.981-2.059c-0.001-0.002-0.001-0.005-0.002-0.007
				c-0.295-1.083-0.702-2.125-1.199-3.105l0.764-2.997l-4.324-4.631l-3.043,0.557c-0.942-0.563-1.954-1.039-3.013-1.407
				c-0.002-0.001-0.005-0.001-0.008-0.002l-1.85-3.116l-6.331-0.217l-2.059,2.981c-0.004,0.001-0.008,0.002-0.012,0.003
				c-1.082,0.295-2.123,0.701-3.102,1.197l-2.996-0.764l-4.63,4.324l0.556,3.038c-0.001,0.001-0.001,0.002-0.002,0.003
				c-0.574,0.953-1.04,1.965-1.408,3.025l-3.115,1.85l-0.217,6.331l2.983,2.06c0.294,1.09,0.689,2.13,1.195,3.118
				c0,0.001,0,0.001,0,0.001l-0.761,2.988l4.323,4.63l3.025-0.553c0.954,0.564,1.967,1.042,3.04,1.41
				c0.001,0.001,0.002,0.001,0.003,0.001l1.847,3.111l6.331,0.217l2.057-2.979c1.094-0.294,2.137-0.701,3.128-1.199
				c0,0,0.001,0,0.001-0.001l2.982,0.76l4.63-4.323l-0.556-3.038c0.574-0.953,1.04-1.966,1.408-3.025c0,0,0-0.001,0-0.001
				l3.116-1.851L301.29,169.649z M281.559,179.274c-3.936-0.135-7.01-3.439-6.876-7.363c0.135-3.936,3.428-7.01,7.363-6.876
				c3.936,0.135,7.01,3.428,6.876,7.363C288.788,176.322,285.494,179.409,281.559,179.274z"
            />
            <path
              style="fill: #407bff"
              d="M323.043,119.124l0.521-1.257c0.001,0,0.002-0.001,0.003-0.001
				c0.374-0.193,0.725-0.425,1.046-0.685l1.161,0.044l1.36-1.952l-0.444-1.074c0.132-0.39,0.228-0.799,0.279-1.217
				c0-0.001,0-0.002,0-0.003l0.998-0.925l-0.419-2.342l-1.257-0.521c-0.001-0.001-0.001-0.003-0.002-0.004
				c-0.193-0.374-0.424-0.724-0.684-1.045l0.044-1.16l-1.952-1.36l-1.072,0.443c0,0-0.001,0-0.001,0
				c-0.395-0.136-0.803-0.227-1.221-0.279l-0.925-0.998l-2.342,0.419l-0.522,1.258c-0.377,0.194-0.728,0.421-1.051,0.685
				c0,0,0,0,0,0l-1.157-0.044l-1.359,1.952l0.441,1.067c-0.132,0.395-0.228,0.804-0.278,1.227c0,0,0,0.001,0,0.001l-0.997,0.923
				l0.419,2.342l1.256,0.521c0.194,0.379,0.426,0.73,0.687,1.054c0,0,0,0,0,0l-0.044,1.155l1.952,1.36l1.072-0.444
				c0.395,0.136,0.804,0.227,1.222,0.279c0,0,0,0,0,0l0.925,0.999L323.043,119.124z M317.954,112.637
				c-0.26-1.456,0.711-2.845,2.162-3.105c1.456-0.26,2.844,0.706,3.105,2.162s-0.707,2.844-2.162,3.105
				C319.608,115.059,318.215,114.093,317.954,112.637z"
            />
            <path
              style="fill: #407bff"
              d="M295.607,112.489l-0.764-0.645c1.328-1.572,3.211-3.564,6.008-5.029l0.464,0.887
				C298.333,109.263,296.423,111.524,295.607,112.489z"
            />
            <path
              style="fill: #407bff"
              d="M303.591,106.802l-0.395-0.92c3.783-1.62,7.735-2.252,11.739-1.88l-0.093,0.996
				C311.002,104.632,307.218,105.246,303.591,106.802z"
            />
            <path
              style="fill: #407bff"
              d="M301.703,113.72l-0.826-0.563c2.054-3.017,6.113-5.644,9.871-6.389l0.194,0.98
				C307.42,108.448,303.621,110.903,301.703,113.72z"
            />
            <path
              style="fill: #ffffff"
              d="M235.343,209.957c-0.134,0-0.264-0.054-0.359-0.152l-2.097-2.161
				c-1.068-0.101-2.124-0.297-3.146-0.586l-3.268,1.441c-0.155,0.071-0.335,0.053-0.478-0.04l-5.475-3.63
				c-0.142-0.095-0.226-0.254-0.224-0.425l0.054-3.563c-0.665-0.828-1.258-1.727-1.766-2.677l-2.805-1.088
				c-0.159-0.062-0.275-0.199-0.31-0.366l-1.305-6.438c-0.034-0.167,0.02-0.34,0.142-0.459l2.161-2.096
				c0.1-1.063,0.296-2.12,0.584-3.149l-1.439-3.264c-0.069-0.156-0.053-0.336,0.04-0.479l3.629-5.476
				c0.094-0.142,0.228-0.23,0.425-0.224l3.561,0.055c0.836-0.668,1.736-1.261,2.679-1.765l1.088-2.807
				c0.062-0.159,0.2-0.275,0.367-0.31l6.438-1.306c0.167-0.032,0.34,0.02,0.458,0.142l2.094,2.159
				c1.064,0.1,2.122,0.297,3.153,0.585l3.263-1.439c0.155-0.068,0.335-0.053,0.478,0.041l5.475,3.629
				c0.142,0.095,0.226,0.254,0.224,0.425l-0.054,3.565c0.666,0.829,1.258,1.728,1.764,2.675l2.807,1.089
				c0.159,0.062,0.275,0.199,0.31,0.366l1.305,6.438c0.034,0.167-0.02,0.34-0.142,0.459l-2.162,2.097
				c-0.099,1.051-0.295,2.106-0.585,3.143l1.441,3.269c0.069,0.156,0.053,0.337-0.04,0.479L250,203.591
				c-0.094,0.142-0.244,0.213-0.424,0.224l-3.563-0.054c-0.83,0.662-1.729,1.253-2.675,1.759l-1.091,2.813
				c-0.061,0.159-0.2,0.275-0.367,0.31l-6.438,1.306C235.409,209.954,235.376,209.957,235.343,209.957z M229.706,206.027
				c0.047,0,0.095,0.007,0.141,0.021c1.071,0.314,2.184,0.522,3.311,0.616c0.121,0.01,0.233,0.063,0.317,0.15l2.036,2.099
				l5.902-1.197l1.06-2.732c0.044-0.113,0.128-0.207,0.235-0.263c1.003-0.523,1.95-1.145,2.815-1.851
				c0.091-0.074,0.194-0.125,0.323-0.112l3.471,0.053l3.327-5.02l-1.404-3.184c-0.047-0.107-0.055-0.229-0.022-0.342
				c0.316-1.091,0.523-2.205,0.616-3.311c0.01-0.121,0.063-0.233,0.15-0.318l2.099-2.036l-1.196-5.901l-2.727-1.058
				c-0.116-0.045-0.211-0.131-0.267-0.242c-0.52-1-1.143-1.943-1.851-2.806c-0.075-0.092-0.116-0.207-0.114-0.325l0.053-3.474
				l-5.02-3.327l-3.176,1.4c-0.108,0.047-0.228,0.055-0.341,0.023c-1.084-0.315-2.202-0.523-3.323-0.616
				c-0.121-0.01-0.233-0.064-0.317-0.149l-2.033-2.097l-5.902,1.197l-1.057,2.727c-0.044,0.113-0.128,0.207-0.236,0.263
				c-0.996,0.519-1.944,1.143-2.816,1.854c-0.091,0.074-0.213,0.127-0.325,0.114l-3.47-0.054l-3.327,5.02l1.402,3.179
				c0.06,0.137,0.056,0.293-0.01,0.426c-0.282,0.999-0.489,2.114-0.583,3.233c-0.01,0.12-0.063,0.232-0.15,0.317l-2.098,2.035
				l1.196,5.901l2.726,1.057c0.122,0.047,0.22,0.14,0.273,0.257c0.518,0.992,1.14,1.934,1.844,2.791
				c0.075,0.092,0.117,0.209,0.115,0.327l-0.053,3.472l5.02,3.328l3.184-1.404C229.568,206.042,229.637,206.027,229.706,206.027z
				 M234.624,202.792c-2.528,0-5.082-0.716-7.343-2.214c-2.966-1.966-4.99-4.97-5.699-8.457c-0.709-3.488-0.016-7.043,1.95-10.01
				c0.001,0,0.001,0,0.001,0c4.065-6.125,12.352-7.807,18.473-3.75c6.122,4.063,7.802,12.35,3.744,18.474
				C243.189,200.698,238.945,202.792,234.624,202.792z M224.366,182.664c-1.818,2.743-2.459,6.031-1.803,9.258
				c0.655,3.226,2.527,6.004,5.271,7.822c5.667,3.754,13.329,2.201,17.081-3.462c3.754-5.664,2.201-13.33-3.462-17.087
				C235.791,175.442,228.126,176.998,224.366,182.664L224.366,182.664z"
            />
          </g>
          <g>
            <path
              style="fill: #407bff"
              d="M425.826,231.65l-15.695-2.118l10.38-77.808l-30.281-4.086l-73.453,67.626l-3.859,28.929
				l63.292,8.542l-3.156,23.662l30.281,4.086l3.156-23.662l15.695,2.118L425.826,231.65z M346.408,220.932l38.758-35.328
				l-5.315,39.841L346.408,220.932z"
            />
            <path
              style="fill: #263238"
              d="M394.134,279.741c-0.03,0-0.06-0.003-0.09-0.008c-0.271-0.05-0.452-0.31-0.402-0.582
				l23.326-127.93c0.049-0.27,0.312-0.44,0.581-0.402c0.272,0.05,0.452,0.311,0.402,0.582l-23.326,127.93
				C394.581,279.572,394.371,279.741,394.134,279.741z"
            />
            <g>
              <path
                style="fill: #263238"
                d="M414.831,153.37c-0.016,0.452-0.395,0.806-0.847,0.791c-0.452-0.016-0.806-0.395-0.791-0.847
					s0.395-0.806,0.847-0.791C414.493,152.538,414.847,152.917,414.831,153.37z"
              />
              <path
                style="fill: #263238"
                d="M411.499,170.671c-0.016,0.452-0.395,0.806-0.847,0.791s-0.806-0.395-0.791-0.847
					s0.395-0.806,0.847-0.791C411.16,169.839,411.514,170.218,411.499,170.671z"
              />
              <path
                style="fill: #263238"
                d="M408.166,187.971c-0.016,0.452-0.395,0.806-0.847,0.791s-0.806-0.395-0.791-0.847
					s0.395-0.806,0.847-0.791C407.828,187.14,408.182,187.519,408.166,187.971z"
              />
              <path
                style="fill: #263238"
                d="M401.613,205.982c-0.016,0.452-0.395,0.806-0.847,0.791c-0.452-0.016-0.806-0.395-0.791-0.847
					s0.395-0.806,0.847-0.791S401.629,205.529,401.613,205.982z"
              />
              <path
                style="fill: #263238"
                d="M401.501,222.573c-0.016,0.452-0.395,0.806-0.847,0.791c-0.452-0.016-0.806-0.395-0.791-0.847
					s0.395-0.806,0.847-0.791C401.163,221.741,401.517,222.121,401.501,222.573z"
              />
              <path
                style="fill: #263238"
                d="M398.169,239.874c-0.016,0.452-0.395,0.806-0.847,0.791s-0.806-0.395-0.791-0.847
					c0.015-0.452,0.395-0.806,0.847-0.791S398.184,239.421,398.169,239.874z"
              />
              <path
                style="fill: #263238"
                d="M394.836,257.175c-0.016,0.452-0.395,0.806-0.847,0.791c-0.452-0.016-0.806-0.395-0.791-0.847
					c0.016-0.452,0.395-0.806,0.847-0.791C394.498,256.343,394.852,256.722,394.836,257.175z"
              />
              <path
                style="fill: #263238"
                d="M391.504,274.475c-0.016,0.452-0.395,0.806-0.847,0.791s-0.806-0.395-0.791-0.847
					s0.395-0.806,0.847-0.791S391.519,274.023,391.504,274.475z"
              />
            </g>
            <path
              style="fill: #263238"
              d="M400.794,206.454c-0.23,0-0.438-0.16-0.488-0.396c-0.058-0.27,0.114-0.535,0.384-0.594
				l2.484-0.533c0.274-0.054,0.536,0.113,0.594,0.385c0.058,0.27-0.114,0.535-0.384,0.594l-2.484,0.533
				C400.864,206.45,400.829,206.454,400.794,206.454z"
            />
            <g>
              <path
                style="fill: #263238"
                d="M409.085,197.241c0,0-2.238,5.829-2.991,9.361c-0.753,3.532-0.799,11.424-0.799,11.424"
              />
              <path
                style="fill: #263238"
                d="M405.295,218.526c-0.001,0-0.002,0-0.003,0c-0.276-0.002-0.499-0.227-0.497-0.503
					c0.002-0.324,0.054-7.984,0.81-11.526c0.753-3.529,2.921-9.195,3.013-9.436c0.099-0.257,0.387-0.385,0.646-0.287
					c0.258,0.099,0.387,0.389,0.288,0.646c-0.022,0.059-2.232,5.834-2.969,9.286c-0.734,3.442-0.787,11.245-0.788,11.323
					C405.793,218.305,405.57,218.526,405.295,218.526z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Character_1_">
        <g>
          <path
            style="fill: #263238"
            d="M222.452,304.841c1.389-22.403-1.166-63.127,40.944-57.736
			c45.135,5.778,32.222,58.556,32.222,58.556L222.452,304.841z"
          />
          <g>
            <circle style="fill: #ffffff" cx="251.514" cy="273" r="18.375" />
            <path
              style="fill: #263238"
              d="M253.927,254.654c-8.704-1.486-18.01,4.93-18.927,11.43l34.507,3.173
				C268.291,261.847,260.833,255.833,253.927,254.654z"
            />
            <path
              style="fill: #263238"
              d="M265.576,272.543c-0.247,4.804-2.865,8.574-5.848,8.421s-5.201-4.172-4.955-8.976
				c0.247-4.804,2.865-8.574,5.848-8.421C263.604,263.72,265.823,267.739,265.576,272.543z"
            />
            <path
              style="fill: #407bff"
              d="M251.514,292.375c-10.684,0-19.376-8.691-19.376-19.375s8.692-19.375,19.376-19.375
				c10.684,0,19.375,8.691,19.375,19.375S262.197,292.375,251.514,292.375z M251.514,255.625c-9.581,0-17.376,7.794-17.376,17.375
				s7.794,17.375,17.376,17.375c9.581,0,17.375-7.794,17.375-17.375S261.094,255.625,251.514,255.625z"
            />
          </g>
          <path
            style="opacity: 0.3; fill: #ffffff"
            d="M290.485,304.277c-0.039,0-0.078-0.005-0.118-0.014
			c-0.268-0.065-0.434-0.335-0.369-0.604c0.033-0.135,3.219-13.694-0.09-27.403c-0.065-0.268,0.1-0.538,0.369-0.604
			c0.265-0.06,0.538,0.1,0.603,0.369c3.368,13.95,0.124,27.734,0.09,27.872C290.915,304.124,290.711,304.277,290.485,304.277z"
          />
          <path
            style="opacity: 0.3; fill: #ffffff"
            d="M287.698,268.759c-0.19,0-0.371-0.108-0.455-0.292
			c-1.286-2.806-2.881-5.382-4.742-7.657c-0.175-0.214-0.143-0.528,0.071-0.703c0.213-0.176,0.528-0.145,0.704,0.07
			c1.914,2.341,3.555,4.99,4.876,7.874c0.115,0.251,0.005,0.548-0.246,0.662C287.838,268.744,287.768,268.759,287.698,268.759z"
          />
          <path
            style="fill: #263238"
            d="M128.735,409.918c-0.124,0-0.248-0.001-0.372-0.005c-2.76-0.076-4.936-2.375-4.861-5.136
			c0.075-2.76,2.349-4.895,5.135-4.86c0.031,0.001,0.063,0.001,0.096,0.001c2.889,0,9.839-3.51,24.378-26.43
			c0.781-1.23,2.061-2.06,3.503-2.27c1.445-0.205,2.905,0.223,4.005,1.18c17.941,15.643,36.954,25.38,37.144,25.477
			c2.461,1.25,3.444,4.26,2.194,6.721c-1.25,2.462-4.258,3.447-6.719,2.196c-0.755-0.383-17.374-8.88-34.798-23.146
			C146.254,401.965,137.211,409.918,128.735,409.918z"
          />
          <path
            style="fill: #263238"
            d="M159.67,371.5c-0.142,0-0.284-0.01-0.428-0.03c-1.64-0.234-2.78-1.754-2.545-3.395
			c0.711-4.977-0.17-23.626-6.475-38.126c-0.575-1.321-0.129-2.864,1.062-3.676c13.698-9.335,42.876-10.451,44.112-10.494
			c1.647-0.036,3.044,1.238,3.102,2.894c0.058,1.655-1.238,3.045-2.893,3.102c-0.27,0.01-25.78,0.988-38.888,8.202
			c5.584,14.438,6.85,32.437,5.919,38.947C162.422,370.421,161.139,371.5,159.67,371.5z"
          />
          <path
            style="fill: #407bff"
            d="M164.71,367.616c-1.995-4.706-10.988-4.171-16.268-0.138c-2.822,2.155-6.641,8.641-7.043,10.991
			c-0.401,2.351,1.227,4.48,4.023,4.238c3.448-0.298,8.181-5.309,8.181-5.309c-3.149,3.088,0.914,5.267,6.977,0.801
			C163.944,375.722,166.944,372.886,164.71,367.616z"
          />
          <path
            style="fill: #263238"
            d="M336.706,306.161c-0.045,0-0.092-0.006-0.138-0.02c-0.265-0.076-0.419-0.354-0.342-0.618
			c1.332-4.64,2.787-7.475,4.7-9.051c-0.293-0.996-0.26-2.14,0.153-3.259c0.402-1.089,1.179-2.171,2.362-3.005
			c-0.579-0.626-0.795-1.445-0.59-2.382c0.227-1.041,0.984-2.17,2.136-3.156c-0.453-0.521-0.564-1.088-0.567-1.508
			c-0.025-2.982,5.507-7.525,11.762-8.379c0.274-0.051,0.526,0.153,0.563,0.428c0.037,0.273-0.154,0.525-0.428,0.563
			c-5.938,0.811-10.917,5.109-10.897,7.38c0.003,0.368,0.139,0.664,0.362,0.903c2.251-1.567,5.574-2.575,9.282-1.863
			c3.069,0.587,4.317,1.235,4.173,2.167c-0.272,1.753-6.809,2.115-11.19,1.594c-0.993-0.118-1.72-0.353-2.25-0.646
			c-1.089,0.891-1.787,1.891-1.969,2.73c-0.163,0.749,0.079,1.271,0.495,1.636c1.856-0.957,4.469-1.357,7.916-0.595
			c4.537,1.001,4.706,1.554,4.808,1.883c0.072,0.235,0.014,0.477-0.161,0.662c-0.956,1.013-7.906,0.532-11.398-0.323
			c-0.455-0.111-0.856-0.263-1.203-0.451c-1.157,0.729-1.901,1.713-2.268,2.708c-0.294,0.798-0.361,1.593-0.226,2.303
			c0.18-0.107,0.364-0.205,0.552-0.295c2.069-0.982,4.571-0.817,7.654,0.501c2.045,0.876,3.031,1.65,3.103,2.439
			c0.022,0.233-0.024,0.68-0.57,1.042c-1.525,1.013-6.87,0.859-9.648-0.62c-0.655-0.35-1.174-0.865-1.538-1.486
			c-1.635,1.493-2.953,4.166-4.156,8.355C337.123,306.019,336.923,306.161,336.706,306.161z M342.131,296.83
			c0.276,0.512,0.687,0.934,1.219,1.217c2.257,1.201,6.696,1.476,8.362,0.804c0.34-0.138,0.391-0.252,0.391-0.253
			c0-0.003-0.055-0.563-2.501-1.609c-2.803-1.199-5.038-1.367-6.831-0.518C342.553,296.575,342.339,296.694,342.131,296.83z
			 M345.465,290.262c0.086,0.025,0.173,0.049,0.258,0.07c3.531,0.864,8.298,1.012,9.933,0.743c-0.506-0.214-1.54-0.556-3.635-1.018
			C349.248,289.446,347.08,289.637,345.465,290.262z M346.727,284.64c0.451,0.168,0.961,0.267,1.437,0.323
			c4.983,0.59,9.382-0.107,10.04-0.728c-0.17-0.154-0.841-0.582-3.329-1.058C351.592,282.552,348.724,283.375,346.727,284.64z"
          />
          <path
            style="fill: #263238"
            d="M206.527,408.248c0.659-0.211,0.438-0.847-0.297-0.847h-13.539
			c-25.781-2.73-43.794,0.929-32.443,1.792c11.35,0.863-0.799,0.682-9.088,3.817c-8.289,3.136,61.654,3.926,68.015,0
			C225.041,409.389,197.392,411.176,206.527,408.248z"
          />
          <polygon
            style="fill: #407bff"
            points="303.469,412.238 185.667,408.333 180.778,294.111 319.889,297.222 		"
          />
          <path
            style="fill: #263238"
            d="M222.452,410.053c-0.258,0-0.477-0.197-0.498-0.459l-9.443-114.721
			c-0.023-0.275,0.182-0.517,0.458-0.539c0.283-0.016,0.517,0.182,0.539,0.457l9.443,114.721c0.022,0.275-0.182,0.517-0.458,0.539
			C222.479,410.052,222.465,410.053,222.452,410.053z"
          />
          <g>
            <polygon
              style="fill: #407bff"
              points="303.469,412.238 335.131,407.418 345.104,301.208 319.889,297.222 			"
            />
            <polygon
              style="opacity: 0.2"
              points="303.469,412.238 335.131,407.418 345.104,301.208 319.889,297.222 			"
            />
          </g>
          <path
            style="fill: #263238"
            d="M195.734,386.316l-2.904-67.861c-0.303-7.084,5.444-12.949,12.532-12.79l88.059,1.969
			c7.354,0.164,12.911,6.718,11.871,14l-9.763,68.391c-0.885,6.198-6.295,10.736-12.552,10.528l-75.391-2.499
			C201.174,397.841,196.009,392.725,195.734,386.316z"
          />
          <g>
            <path
              style="fill: #ffffff"
              d="M205.247,326.488l13.624,0.434l-0.112,3.513l-8.529-0.271l-0.083,2.615l7.912,0.252l-0.107,3.355
				l-7.912-0.252l-0.103,3.243l8.776,0.279l-0.118,3.726l-13.871-0.441L205.247,326.488z"
            />
            <path
              style="fill: #ffffff"
              d="M221.4,343.471l0.523-16.452l8.473,0.27c1.571,0.05,2.768,0.223,3.589,0.519
				c0.822,0.296,1.475,0.816,1.961,1.562c0.486,0.746,0.712,1.642,0.678,2.69c-0.029,0.913-0.249,1.694-0.659,2.344
				c-0.41,0.65-0.963,1.17-1.657,1.559c-0.442,0.248-1.044,0.446-1.806,0.594c0.599,0.222,1.034,0.438,1.304,0.648
				c0.182,0.141,0.444,0.438,0.785,0.891c0.341,0.453,0.568,0.801,0.68,1.045l2.31,4.846l-5.744-0.183l-2.557-5.114
				c-0.324-0.661-0.617-1.094-0.88-1.297c-0.359-0.266-0.77-0.407-1.234-0.422l-0.449-0.014l-0.212,6.678L221.4,343.471z
				 M226.818,333.848l2.143,0.068c0.232,0.007,0.683-0.053,1.354-0.181c0.339-0.057,0.619-0.22,0.841-0.49
				c0.222-0.27,0.339-0.585,0.35-0.944c0.017-0.531-0.138-0.944-0.466-1.239c-0.328-0.295-0.955-0.457-1.883-0.487l-2.233-0.071
				L226.818,333.848z"
            />
            <path
              style="fill: #ffffff"
              d="M239.277,344.04l0.523-16.452l8.473,0.27c1.571,0.05,2.768,0.223,3.589,0.519
				c0.822,0.296,1.475,0.816,1.961,1.562c0.486,0.746,0.712,1.643,0.678,2.69c-0.029,0.913-0.249,1.694-0.659,2.344
				c-0.411,0.65-0.963,1.17-1.657,1.559c-0.442,0.248-1.044,0.446-1.806,0.594c0.599,0.222,1.034,0.438,1.304,0.648
				c0.183,0.141,0.444,0.438,0.786,0.891c0.341,0.453,0.568,0.801,0.68,1.045l2.31,4.846l-5.745-0.183l-2.557-5.114
				c-0.323-0.661-0.617-1.094-0.88-1.297c-0.359-0.266-0.77-0.407-1.234-0.422l-0.449-0.014l-0.212,6.677L239.277,344.04z
				 M244.695,334.417l2.143,0.068c0.232,0.007,0.683-0.053,1.354-0.182c0.339-0.057,0.619-0.22,0.841-0.49
				c0.222-0.27,0.339-0.585,0.35-0.944c0.017-0.531-0.138-0.944-0.466-1.239c-0.328-0.295-0.956-0.457-1.883-0.487l-2.233-0.071
				L244.695,334.417z"
            />
            <path
              style="fill: #ffffff"
              d="M256.316,336.359c0.086-2.686,0.9-4.753,2.444-6.202c1.544-1.448,3.651-2.13,6.322-2.045
				c2.738,0.087,4.825,0.889,6.259,2.406c1.435,1.517,2.11,3.6,2.026,6.249c-0.061,1.923-0.435,3.49-1.121,4.7
				c-0.687,1.21-1.652,2.138-2.897,2.784c-1.245,0.646-2.78,0.939-4.606,0.881c-1.855-0.059-3.381-0.403-4.578-1.033
				c-1.197-0.63-2.153-1.596-2.868-2.9C256.582,339.895,256.255,338.282,256.316,336.359z M261.399,336.543
				c-0.053,1.661,0.218,2.864,0.812,3.609c0.594,0.745,1.422,1.135,2.485,1.169c1.092,0.035,1.949-0.294,2.57-0.985
				c0.621-0.692,0.961-1.958,1.02-3.799c0.049-1.548-0.227-2.69-0.829-3.424c-0.602-0.735-1.438-1.119-2.508-1.153
				c-1.025-0.033-1.86,0.304-2.504,1.01C261.801,333.676,261.452,334.867,261.399,336.543z"
            />
            <path
              style="fill: #ffffff"
              d="M275.907,345.205l0.523-16.452l8.473,0.27c1.571,0.05,2.768,0.223,3.589,0.519
				c0.822,0.296,1.475,0.816,1.961,1.562c0.486,0.746,0.712,1.643,0.678,2.69c-0.029,0.913-0.249,1.694-0.659,2.344
				c-0.41,0.65-0.963,1.17-1.657,1.559c-0.443,0.248-1.045,0.446-1.806,0.594c0.599,0.222,1.034,0.438,1.304,0.648
				c0.182,0.141,0.444,0.438,0.785,0.891c0.341,0.453,0.568,0.801,0.68,1.045l2.31,4.846l-5.744-0.183l-2.557-5.114
				c-0.324-0.661-0.617-1.094-0.88-1.297c-0.359-0.266-0.77-0.407-1.234-0.422l-0.449-0.014l-0.212,6.677L275.907,345.205z
				 M281.325,335.582l2.143,0.068c0.232,0.007,0.683-0.053,1.354-0.182c0.339-0.057,0.619-0.22,0.841-0.49
				c0.222-0.27,0.339-0.585,0.35-0.944c0.017-0.531-0.138-0.944-0.466-1.239c-0.328-0.295-0.955-0.457-1.883-0.487l-2.233-0.071
				L281.325,335.582z"
            />
          </g>
          <path
            style="fill: #ffffff"
            d="M283.999,373.158c-0.356,0-0.71-0.144-0.97-0.427l-8.561-9.369l-9.237,8.439
			c-0.535,0.49-1.365,0.451-1.854-0.083l-8.561-9.369l-9.237,8.439c-0.535,0.489-1.366,0.451-1.854-0.083l-8.561-9.369l-9.237,8.44
			c-0.256,0.235-0.602,0.358-0.944,0.343c-0.348-0.016-0.675-0.169-0.91-0.426l-8.561-9.369l-9.237,8.439
			c-0.535,0.489-1.365,0.45-1.854-0.083c-0.489-0.535-0.451-1.366,0.084-1.855l10.205-9.325c0.535-0.491,1.365-0.452,1.855,0.083
			l8.561,9.369l9.237-8.44c0.256-0.235,0.601-0.357,0.944-0.343c0.348,0.016,0.675,0.169,0.91,0.426l8.561,9.369l9.237-8.439
			c0.534-0.49,1.365-0.452,1.854,0.083l8.561,9.369l9.237-8.439c0.535-0.491,1.365-0.453,1.855,0.083l9.446,10.338
			c0.489,0.535,0.451,1.366-0.084,1.855C284.632,373.045,284.315,373.158,283.999,373.158z"
          />
          <g>
            <polygon
              style="fill: #407bff"
              points="125.75,414.667 119.492,411.682 113.909,384.515 116.939,381.178 			"
            />
            <g>
              <polygon
                style="fill: #407bff"
                points="125.75,414.667 134.055,409.815 126.935,387.432 116.939,381.178 				"
              />
              <polygon
                style="opacity: 0.2"
                points="125.75,414.667 134.055,409.815 126.935,387.432 116.939,381.178 				"
              />
            </g>
          </g>
          <path
            style="fill: #263238"
            d="M261.949,419.605c-0.236,0-0.467-0.004-0.691-0.01c-2.76-0.076-4.937-2.375-4.861-5.136
			c0.076-2.759,2.356-4.929,5.135-4.86c7.703,0.252,24.006-4.48,33.319-13.521c0.576-0.559,1.277-0.971,2.046-1.201
			c15.788-4.738,21.664,4.834,21.907,5.244l-8.607,5.09c0,0,0.033,0.069,0.115,0.183c-0.096-0.132-2.331-3.039-9.452-1.24
			C289.173,414.822,271.233,419.605,261.949,419.605z"
          />
          <g>
            <polygon
              style="fill: #407bff"
              points="261.395,426.252 243.182,425.104 241.745,391.528 266.222,392.443 			"
            />
            <g>
              <polygon
                style="fill: #407bff"
                points="261.395,426.252 265.652,422.015 268.291,401.079 266.222,392.443 				"
              />
              <polygon
                style="opacity: 0.2"
                points="261.395,426.252 265.652,422.015 268.291,401.079 266.222,392.443 				"
              />
            </g>
          </g>
          <path
            style="fill: #263238"
            d="M366.91,413.537c-0.632,0-1.27-0.199-1.813-0.611c-10.395-7.9-24.445-21.926-29.837-35.457
			c-0.079-0.197-0.136-0.403-0.171-0.613c-2.629-15.633-5.141-48.541-5.247-49.935c-0.125-1.651,1.112-3.093,2.764-3.218
			c1.647-0.121,3.093,1.112,3.218,2.765c0.026,0.336,2.563,33.566,5.128,49.073c5.378,13.156,19.957,26.666,27.775,32.607
			c1.319,1.003,1.576,2.885,0.573,4.204C368.711,413.129,367.816,413.537,366.91,413.537z"
          />
          <path
            style="fill: #263238"
            d="M380.778,415.053c-2.319,0-4.949-1.052-7.814-3.125c-1.342-0.972-1.643-2.847-0.671-4.189
			c0.972-1.342,2.847-1.643,4.189-0.672c2.477,1.793,3.83,1.986,4.297,1.986c1.657,0,3,1.343,3,3
			C383.778,413.71,382.435,415.053,380.778,415.053z"
          />
          <path
            style="fill: #407bff"
            d="M373.804,411.842c3.21-9.535,16.37-7.651,20.902-6.235c4.532,1.416,8.615,3.198,11.07,4.803
			c2.455,1.605,2.494,4.285-0.779,5.816c-4.036,1.888-14.161,0.213-14.161,0.213c5.241,2.295-0.01,5.972-9.205,2.36
			C378.637,417.623,372.671,415.336,373.804,411.842z"
          />
          <path
            style="fill: #263238"
            d="M341.862,331.036c-1.191,8.102-7.9,14.076-13.358,13.274c-5.458-0.802-5.685-8.038-4.494-16.14
			c1.191-8.102,3.349-14.001,8.807-13.199C338.275,315.774,343.053,322.935,341.862,331.036z"
          />
          <circle style="fill: #263238" cx="209.875" cy="297.875" r="0.819" />
          <path
            style="fill: #263238"
            d="M218.208,303.778c0,0.452-0.367,0.819-0.819,0.819s-0.82-0.367-0.82-0.819
			c0-0.453,0.367-0.819,0.82-0.819S218.208,303.325,218.208,303.778z"
          />
          <path
            style="fill: #263238"
            d="M219.125,400.683c0,0.453-0.367,0.819-0.819,0.819c-0.453,0-0.82-0.367-0.82-0.819
			c0-0.453,0.367-0.819,0.82-0.819C218.758,399.863,219.125,400.23,219.125,400.683z"
          />
          <path
            style="fill: #263238"
            d="M226.639,406.586c0,0.453-0.367,0.819-0.82,0.819c-0.452,0-0.819-0.367-0.819-0.819
			c0-0.453,0.367-0.819,0.819-0.819C226.272,405.766,226.639,406.133,226.639,406.586z"
          />
        </g>
      </g>
      <g id="Robot_Parts">
        <g>
          <path
            style="fill: #263238"
            d="M82.51,416.419c-2.38,0-5.598-1.442-7.333-3.949c-0.157-0.227-0.101-0.538,0.126-0.695
			c0.227-0.158,0.538-0.101,0.696,0.127c1.866,2.694,5.979,4.131,7.805,3.28c0.192-0.09,0.348-0.203,0.467-0.339
			c-1.632-0.754-3.124-2.23-3.526-4.482c-0.227-1.267-0.407-2.98,0.639-3.217c1.434-0.322,3.31,3.146,4.032,5.775
			c0.134,0.486,0.176,0.937,0.13,1.343c0.632,0.173,1.254,0.218,1.787,0.141c0.433-0.063,1.048-0.241,1.375-0.745
			c-1.215-0.821-2.032-2.269-2.305-4.189c-0.388-2.734-0.097-3.211,0.206-3.444c0.197-0.151,0.447-0.18,0.688-0.078
			c1.069,0.455,2.365,4.334,2.613,6.529c0.027,0.242,0.027,0.474,0.003,0.693c0.409,0.163,0.86,0.252,1.349,0.262
			c0.867,0.05,1.724-0.226,2.41-0.629c-0.142-0.089-0.276-0.188-0.402-0.295c-1.038-0.887-1.456-2.304-1.28-4.331
			c0.136-1.567,0.493-2.424,1.123-2.693c0.221-0.098,0.671-0.189,1.208,0.239c1.08,0.863,2.088,3.598,1.726,5.557
			c-0.075,0.405-0.25,0.794-0.502,1.151c1.835,0.316,4.417-0.199,6.644-0.749c0.267-0.065,0.539,0.097,0.605,0.365
			c0.066,0.269-0.097,0.539-0.365,0.605c-2.71,0.67-5.627,1.215-7.688,0.617c-0.916,0.729-2.206,1.171-3.498,1.162
			c-0.589-0.012-1.138-0.117-1.639-0.308c-0.394,0.671-1.127,1.125-2.124,1.271c-0.664,0.099-1.446,0.035-2.238-0.191
			c-0.225,0.375-0.565,0.676-1.013,0.886C83.749,416.31,83.161,416.418,82.51,416.419z M81.646,408.142
			c-0.058,0.153-0.163,0.659,0.085,2.044c0.329,1.84,1.52,3.063,2.839,3.708c-0.006-0.213-0.045-0.449-0.116-0.708
			C83.668,410.327,82.175,408.357,81.646,408.142z M87.217,407.27c-0.006,0.376,0.028,1.008,0.177,2.059
			c0.203,1.428,0.729,2.524,1.519,3.223C88.679,410.543,87.788,408.218,87.217,407.27z M93.508,406.401c0,0-0.001,0-0.002,0
			c-0.002,0.001-0.375,0.19-0.521,1.86c-0.146,1.683,0.16,2.823,0.934,3.484c0.176,0.15,0.376,0.276,0.599,0.379
			c0.283-0.313,0.476-0.663,0.543-1.028c0.268-1.449-0.422-3.571-1.196-4.43C93.665,406.443,93.536,406.401,93.508,406.401z"
          />
          <g>
            <polygon
              style="fill: #263238"
              points="228.873,423.978 231.583,419.278 228.873,414.578 225.972,414.578 227.611,419.278 
				225.972,423.978 			"
            />
            <path
              style="fill: #263238"
              d="M226.163,421.728c-1.35,0-2.45-1.1-2.45-2.45c0-1.35,1.1-2.44,2.45-2.44l-4.608-0.922
				l-1.222,3.721l2.056,3.139L226.163,421.728z"
            />
            <path
              style="fill: #407bff"
              d="M226.557,414.578h-5.43l-2.71,4.7l2.71,4.7h5.43l2.71-4.7L226.557,414.578z M223.847,421.728
				c-1.35,0-2.45-1.1-2.45-2.45c0-1.35,1.1-2.44,2.45-2.44c1.35,0,2.44,1.09,2.44,2.44
				C226.287,420.628,225.197,421.728,223.847,421.728z"
            />
          </g>
          <g>
            <polygon
              style="fill: #407bff"
              points="417.889,414.265 415.877,410.774 417.889,407.284 420.044,407.284 418.827,410.775 
				420.044,414.265 			"
            />
            <path
              style="fill: #407bff"
              d="M419.902,412.594c1.003,0,1.82-0.817,1.82-1.82c0-1.002-0.817-1.812-1.82-1.812l3.422-0.685
				l0.908,2.764l-1.527,2.331L419.902,412.594z"
            />
            <path
              style="fill: #263238"
              d="M419.61,407.284h4.033l2.013,3.49l-2.013,3.491h-4.033l-2.013-3.491L419.61,407.284z
				 M421.623,412.594c1.003,0,1.82-0.817,1.82-1.82c0-1.002-0.817-1.812-1.82-1.812c-1.003,0-1.812,0.81-1.812,1.812
				C419.81,411.777,420.62,412.594,421.623,412.594z"
            />
          </g>
          <g>
            <path
              style="fill: #263238"
              d="M342.948,418.898h4.518c1.176,0,2.139-0.962,2.139-2.139l0,0c0-1.176-0.963-2.139-2.139-2.139
				h-4.518c-1.177,0-2.139,0.963-2.139,2.139l0,0C340.809,417.936,341.771,418.898,342.948,418.898z"
            />
            <path
              style="fill: #407bff"
              d="M335.73,414.863c1.116-2.366,3.82-3.435,6.042-2.388c2.221,1.047,3.117,3.814,2.002,6.18
				s-3.82,3.435-6.041,2.388C335.51,419.996,334.614,417.229,335.73,414.863z"
            />

            <rect
              x="339.066"
              y="411.708"
              transform="matrix(-0.9045 -0.4265 0.4265 -0.9045 469.3328 938.6096)"
              style="fill: #263238"
              width="1.37"
              height="10.102"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
