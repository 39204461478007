import cookiesHandler from '@/composables/cookiesHandler';
import { createStore } from 'vuex'

export default createStore({
  state: { 
    person: null,
    professional: null,
    company: null,   
    recruiter: null,
    user: {
      slug: null,
      name: null,
      userLogged: false,
      userType: [],
      loggedAs: null
    },
    cookiesAccepted: null,
    isTryingToLogin: false,

    user_lang: 'pt',
    user_lang_name: 'português',

    translations: null,
    languages: null,
    
    sessionMessage: null,
    sessionMessageType: null,

    comboChest: {},
    dataChest: {},

    ipInformation: {
      ip: '',
      countryCode: '',
      additionalInfo: {}
    },
    expectedRoute: null,
    routeToGoAfterLogin: null,
    hasRouteChanged: false,
    checkedVersion: false,
    alreadyChangedLanguageByIp: false
  },
  getters: {
    getWholeDataChest(state){
      return state.dataChest;
    },
    getTranslations(state){
      return state.translations;
    },
    getLanguages(state){
      return state.languages;
    },
    isLoggedIn(state){
      return state.user.userLogged;
    },
    getProfileImage(state){
      let valToReturn = '';
      if(state.user.loggedAs != null && state.user.userType.includes(state.user.loggedAs)){
        switch(state.user.loggedAs){
          case 'professional':
            valToReturn = state.professional.professional_photo !== null ? state.professional.professional_photo : '';
          break;
          case 'recruiter':
            valToReturn = state.recruiter.recruiter_photo !== null ? state.recruiter.recruiter_photo : '';
          break;
          case 'company':
            valToReturn = state.company.company_logo  !== null ? state.company.company_logo  : '';
          break;
        }
      }
      return valToReturn != '' ? 'data:image/png;base64,' + valToReturn : '';
    },
    getNameByProfile(state){
      let valToReturn = state.user.name;
      if(state.user.loggedAs != null && state.user.userType.includes(state.user.loggedAs)){
        switch(state.user.loggedAs){
          case 'professional':
            valToReturn = state.professional !== null ? state.professional.professional_firstname + ' ' + state.professional.professional_lastname : state.user.name;
          break;
          case 'recruiter':
            valToReturn = state.user.name;
          break;
          case 'company':
            valToReturn = state.company.company_name !== null ? state.company.company_name : state.user.name;
          break;
        }
      }
      return valToReturn;
    },
    getUserSlug(state){
      return state.user.slug;
    },
    getUserName(state){
      return state.user.name;
    },
    getUserType(state){
      return state.user.userType;
    },
    getLoggedUserProfiles(state){
      let profilesArray = [];
      if(state.professional !== null)
        profilesArray.push('professional');
      if(state.company !== null)
        profilesArray.push('company');
      if(state.recruiter !== null)
        profilesArray.push('recruiter');
      return profilesArray;
    },
    getLoggedUserNotLoggedInProfile(state){
      let loggedAs = state.user.loggedAs;
      let profilesArray = [];
      if(state.professional !== null && loggedAs != 'professional')
        profilesArray.push('professional');
      if(state.company !== null && loggedAs != 'company')
        profilesArray.push('company');
      if(state.recruiter !== null && loggedAs != 'recruiter')
        profilesArray.push('recruiter');
      return profilesArray;
    },
    getUserLang(state){
      return state.user_lang === null ? 'pt' : state.user_lang;
    },
    getUserLangName(state){
      return state.user_lang_name === null ? 'português' : state.user_lang_name;
    },
    getPerson(state){
      return state.person;
    },
    getProfiles(state){
      return state.user.userType;
    },
    getCompany(state){
      return state.company;
    },
    getProfessional(state){
      return state.professional;
    },
    getRecruiter(state){
      return state.recruiter;
    },
    getLoggedAs(state){
      return state.user.loggedAs;
    },
    loggedAsProfessional(state){
      return state.user.loggedAs == 'professional' ? true : false;
    },
    loggedAsRecruiter(state){
      return state.user.loggedAs == 'recruiter' ? true : false;
    },
    loggedAsCompany(state){
      return state.user.loggedAs == 'company' ? true : false;
    },
    getSessionMessage(state){
      return {message: state.sessionMessage, type: state.sessionMessageType};
    },
    hasSessionManage(state){
      return state.sessionMessage != null ? true : false;
    },
    alreadyCheckedVersion(state){
      return state.checkedVersion;
    },
    alreadyChangedLanguageByIp(state){
      return state.alreadyChangedLanguageByIp;
    },
    getProfileTypes(state){
      let profiles = [];
      if(state.professional != null)
        profiles.push('professional');
      if(state.recruiter != null)
        profiles.push('recruiter');
      if(state.company != null)
        profiles.push('company');
      return profiles;
    },
    hasOnlyOneProfile(state){
      let profiles = [];
      if(state.professional != null)
        profiles.push('professional');
      if(state.recruiter != null)
        profiles.push('recruiter');
      if(state.company != null)
        profiles.push('company');
      if(profiles.length > 1)
        return false;
      return profiles[0];
    },
    isTryingToLogin(state){
      return state.isTryingToLogin;
    },
    getComboChest(state){
      return state.comboChest;
    },
    getOnComboChest: (state) => (key) => {
      if(state.comboChest[key] === undefined || state.comboChest == null)
        return [];
      if(!state.comboChest[key])
        return [];
      return state.comboChest[key];
    },
    getDataChest(state){
      return state.dataChest;
    },
    getOnDataChest: (state) => (key) => {
      if(state.dataChest[key] === undefined || state.dataChest == null)
        return [];
      if(!state.dataChest[key])
        return [];
      return state.dataChest[key];
    },
    getRouteToGoAfterLogin(state){
      return state.routeToGoAfterLogin;
    },
    hasRouteChanged(state){
      return state.hasRouteChanged;
    },
    getExpectedRoute(state){
      return state.expectedRoute;
    },
    getIpInformation(state){
      return state.ipInformation;
    },
    harvestedIpInformation(state){
      return state.ipInformation.ip != '' ? true : false;
    },
    getCookieAccepted(state){
      return state.cookiesAccepted;
    },
    /**
     * Checks if logged user has the profile requested
     * @param {*} state 
     * @param {String} profileName - 'professional','company', 'recruiter' or 'person' 
     * @returns 
     */
    checkProfileExistance: (state) => (profileName) => {
      let value = null;
      switch(profileName){
        case 'professional':
          value = state.professional != null ? state.professional : false;
        break;
        case 'company':
          value = state.company != null ? state.company : false;
        break;
        case 'recruiter':
          value = state.recruiter != null ? state.recruiter : false;
        break;
        case 'person':
          value = state.person != null ? state.person : false;
        break;
        case 'profile':
          value = state.person != null ? state.person : false;
        break;
      }
      return value;
    }
  },
  mutations: {
    /**
     * Auth and Profile informations
     */
    setPerson(state, person){
      state.user.slug = person.person_slug;
      state.user.name = person.person_username;
      state.user.userLogged = true;
      state.person = person;
    },
    setProfessional(state, professional){
      state.professional = professional;
      if(!state.user.userType.includes('professional'))
        state.user.userType.push('professional');
    },
    setCompany(state, company){
      state.company = company;
      if(!state.user.userType.includes('company'))
        state.user.userType.push('company');
    },
    setRecruiter(state, recruiter){
      state.recruiter = recruiter;
      if(!state.user.userType.includes('recruiter'))
        state.user.userType.push('recruiter');
    },
    setLoggedAs(state, loggedAs){
      state.user.loggedAs = loggedAs;
    },
    setUserLang(state, obj){
      state.user_lang = obj.langIso;
      state.user_lang_name = obj.langName;
    },
    cleanAuthData(state){
      state.person = null;
      state.professional = null;
      state.company = null;
      state.recruiter = null;
      state.user.loggedAs = null;
      state.user.userLogged = false;
    },

    /**
     * Session message handler
     */
    setSessionMessage(state, messageInfo){
      state.sessionMessage = messageInfo.message;
      state.sessionMessageType = messageInfo.type;
    },
    cleanSessionMessage(state){
      state.sessionMessage = null;
      state.sessionMessageType = null;
    },

    setTranslations(state, translations){
      state.translations = translations;
    },
    setLanguages(state, languages){
      state.languages = languages;
    },
    saveOnComboChest(state, values){
      state.comboChest[values.key] = values.value;
    },
    saveOnDataChest(state, values){
      state.dataChest[values.key] = values.value;
    },
    saveRouteToGoAfterLogin(state, values){
      state.routeToGoAfterLogin = values.url;
    },
    saveRouteChanged(state, values){
      state.hasRouteChanged = values.url;
    },
    cleanDataChest(state){
      state.dataChest = {};
    },
    cleanComboChest(state){
      state.comboChest = {};
    },
    saveCookieAccepted(state, value){
      state.cookiesAccepted = value.aceppted;
      cookiesHandler.setEncryptedCookie('cookies-acceptance', value.aceppted);
    },
    saveIsTyingToLogin(state, value){
      state.isTryingToLogin = value.isTrying;
    },
    alreadyCheckedVersion(state){
      state.checkedVersion = true;
    },
    saveExpectedRoute(state, value){
      state.expectedRoute = value.route;
    },
    saveIpInformation(state, value){
      state.ipInformation.ip             = value.ipData.query; 
      state.ipInformation.countryCode    = value.ipData.countryCode; 
      state.ipInformation.additionalInfo = value.ipData; 
    },
    setAlreadyChangedLanguageByIp(state, value){
      state.alreadyChangedLanguageByIp = value;
    }
  },
  actions: {
    setCrsfToken( {commit}, value){
      commit('setCrsfToken', value);
    },
    savePerson({ commit }, value){
      commit('setPerson', value);
    },
    saveProfile({ commit }, values){
      commit('setProfessional', values.professionals);
      commit('setCompany', values.companies);
      commit('setRecruiter', values.recruiters);
    },
    saveLoginInformation({ dispatch }, values){
      dispatch('savePerson', values.person)
      dispatch('saveProfile', values.profiles)
    },
    saveLoggedAs({ dispatch }, value){
      dispatch('setLoggedAs', value.loggedAs);
    },
    cleanAuthData({ commit }){
      commit('cleanAuthData');
    },
    saveSessionMessage({ commit }, values){
      commit('setSessionMessage', {message: values.message, type: values.messageType});
    },
    cleanSessionMessage({ commit }){
      commit('cleanSessionMessage');
    },
    saveTranslations({ commit }, values){
      commit('setTranslations', values)
    },
    saveLanguages({ commit }, values){
      commit('setLanguages', values)
    },
    changeLanguage({ commit }, values){
      commit('setUserLang', {langIso: values.langIso, langName: values.langName})
    },
    saveOnComboChest({ commit }, values){
      commit('saveOnComboChest', {key: values.key, value: values.value})
    },
    saveOnDataChest({ commit }, values){
      commit('saveOnDataChest', {key: values.key, value: values.value})
    },
    saveRouteToGoAfterLogin({ commit }, values){
      commit('saveRouteToGoAfterLogin', {url: values.url})
    },
    setRouteChanged({ commit }, values){
      commit('saveRouteChanged', {url: values.url})
    },
    cleanDataChest({ commit }, values){
      commit('cleanDataChest', {url: values.url})
    },
    cleanComboChest({ commit }, values){
      commit('cleanComboChest', {url: values.url})
    },
    saveCookiesAccepted({ commit }, values){
      commit('saveCookieAccepted', {aceppted: values})
    },
    saveIsTyingToLogin({ commit }, value){
      commit('saveIsTyingToLogin', {isTrying: value});
    },
    alreadyCheckedVersion({ commit }){
      commit('alreadyCheckedVersion', true);
    },
    saveExpectedRoute({ commit }, value){
      commit('saveExpectedRoute', {route: value});
    },
    saveIpInformation({ commit }, value){
      commit('saveIpInformation', {ipData: value});
    },
    setAlreadyChangedLanguageByIp({ commit }, value){
      commit('setAlreadyChangedLanguageByIp', value);
    }
  },
  modules: {
  },
})
