<template>
  <div>
    <div class="flex flex-col pt-9 pb-16 px-5 xs:px-8">
      <section
        class="col-span-1 md:col-span-4 flex justify-between items-center p-10 shadow-md rounded-md bg-white"
        v-if="jobData"
      >
        <div class="w-full flex flex-col"> 
          <span class="flex items-center gap-x-5 flex-col md:flex-row">
            <h1 class="text-3xl font-semibold text-oxford-blue">
              {{ jobData.job_title }}
            </h1>
            <Badge
              :value="translate(jobData.job_status, 'ucfirst')"
              :severity="getSeverity(jobData.job_status)"
              class="text-sm h-auto 5uppercase"
            ></Badge>
          </span>

          <span class="flex items-center text-sm font-normal mt-2 mb-3 flex-col md:flex-row">
            <i class="pi pi-clock mr-2" style="font-size: 1.1rem"></i> 
            {{ translate('published at:', 'ucfirst') }} {{ jobData.posted_at_full }}
          </span>

          <div class="flex gap-x-5 text-[0.958rem] font-normal flex-col md:flex-row">
            <h6 class="flex items-center gap-x-2">
              <i class="pi pi-map-marker" style="font-size: 1.2rem"></i>
              {{ jobData.full_location }}
            </h6>
            <h6 class="flex items-center gap-x-2">
              <i class="pi pi-briefcase" style="font-size: 1.2rem"></i>
              {{ jobData.job_modality }}
            </h6>
            <h6 class="flex items-center gap-x-2">
              <i class="pi pi-calendar" style="font-size: 1.2rem"></i>
              {{ jobData.experience_required }}
            </h6>
          </div>
        </div>
      </section>

      <!-- Seção -->
      <section class="px-4 mt-4 pb-14 bg-white shadow-md rounded-md">
        <TabView class="flex flex-col w-full">
          <TabPanel>
            <template #header>
              <div class="flex align-items-center gap-2">
                <span class="font-bold">{{ translate('applicants', 'ucfirst') }}</span>
                <Badge v-model:value="appliancesNumber" class="bg-oxford-blue"></Badge>
              </div>
            </template>
            <div class="mt-5">
              <LoaderComponent v-if="isLoadingJobAppliances" :class="'text-center col-span-5'" />
              <div v-if="!isLoadingJobAppliances && appliancesNumber < 1">
                <p class="text-oxford-blue">{{ translate('no applications found', 'ucfirst') }}.</p>
              </div>
              <div v-if="!isLoadingJobAppliances && appliancesNumber > 0">
                <span>
                  <InputGroup
                    class="w-full flex flex-wrap md:flex-nowrap items-center md:pl-1.5 py-0 bg-white md:rounded-full md:border md:border-r-0 border-iron"
                  >
                    <InputGroupAddon v-if="!isScreenSmall()">
                      <i class="pi pi-search"></i>
                    </InputGroupAddon>
                    <InputText
                      :unstyled="true"
                      v-model.trim="applianceTerm"
                      type="text"
                      id="text"
                      :placeholder="translate('type to search', 'ucfirst')"
                      :class="`w-full px-2 py-1 outline-none ${isScreenSmall() ? 'border iron' : ''}`"
                      @input="checkApplianceSearch()"
                    />
                    <Button
                      :unstyled="true"
                      @click="searchAppliances()"
                      :label="translate('search', 'ucfirst')"
                      class="py-1.5 px-4 font-semibold text-white md:rounded-r-full bg-sea-serpent w-full md:w-auto rounded"
                      :icon="isScreenSmall() ? 'pi pi-search' : ''"
                    />
                  </InputGroup>
                </span>
                <div class="flex flex-col md:flex-row justify-between flex-wrap mt-5 gap-4 w-full">
                  <JobApplicants v-if="!isLoadingJobAppliances" v-model:data="selectedJobAppliances" />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel :header="translate('job description', 'capitalize')">
            <div class="w-full pb-14 mt-5">
              <div class="px-6" v-if="jobData != undefined">
                <h3 class="my-3 font-semibold text-oxford-blue">{{ translate('job description', 'ucfirst') }}:</h3>
                <p>
                  {{ ucfirst(jobData.job_description) }}
                </p>

                <h3 class="my-3 font-semibold text-oxford-blue">{{ translate('activities', 'ucfirst') }}:</h3>
                <p>
                  {{ ucfirst(jobData.job_experience_description) }}
                </p>

                <h3 class="my-3 font-semibold text-oxford-blue">{{ translate('requirements', 'ucfirst') }}:</h3>
                <p>
                  {{ ucfirst(jobData.job_requirements) }}
                </p>

                <h3 class="my-3 font-semibold text-oxford-blue">{{ translate('benefits', 'ucfirst') }}:</h3>
                <p>
                  {{ ucfirst(jobData.job_offer) }}
                </p>
              </div>
              <template v-else >
                <LoaderComponent :class="'px-6'" />
              </template>
            </div>
          </TabPanel>
          <TabPanel header="Reviews" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
            <p class="m-0"></p>
          </TabPanel>
        </TabView>
      </section>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive, onUnmounted } from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Badge from "primevue/badge";
import JobApplicants from "@/components/Dashboard/Company/JobApplicants.vue";
import { env } from "@/composables/config";
import { translate } from "@/composables/translate";
import { ucfirst } from "@/composables/textUtils";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { useRoute } from "vue-router";
import requestHandler from "@/composables/requestHandler";
import { showToast } from "@/composables/toast";
import { useToast } from "primevue/usetoast";
import { getBase64 } from "@/composables/imageChecker";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import store from "@/store";

let route = useRoute();
let jobData = ref();
onMounted(() => {
  jobId.value = route.params != undefined && route.params.job_id != undefined ? route.params.job_id : null;
  getCompanyJobsApplications();
  jobData.value = store.getters.getOnDataChest(`save_job_${jobId.value}`);
  handleResize();
  window.addEventListener('resize', handleResize);
})

let toast = useToast();

let jobId = ref();
let applianceTerm = ref('');
let isLoadingJobAppliances = ref(true);
let jobAppliances = reactive([]);
let selectedJobAppliances = ref([]);
let jobAppliancesFilter = reactive({
  name: {},
  profession: {},
  email: {},
  location: {}
});
let appliancesNumber = ref(0);
async function getCompanyJobsApplications(){
  isLoadingJobAppliances.value = true;
  const response = await requestHandler.executeRequest('/api/company/appliedprofessionals', {job_id: jobId.value}, 'get');
  if(!response.success){
    showToast(toast, response.message, 'error');
    isLoadingJobAppliances.value = false;
    return;
  }
  for(let professional of response.data.data[jobId.value] != undefined ? response.data.data[jobId.value] : []){
    store.dispatch('saveOnDataChest', {
      key: `professional_${professional['professional_id']}`,
      value: professional
    });
  }
  jobAppliances = [];
  jobAppliancesFilter = {
    name: {},
    profession: {},
    email: {},
    location: {}
  };
  if(response.data.data[jobId.value] != undefined > 0){
    let values  = response.data.data[jobId.value] != undefined ? response.data.data[jobId.value] : [];
    let data = [];
    for(let appliance of values){
      appliance['professional_photo'] = getBase64(appliance.professional_photo, true, '/img/avatar.png');
      data.push(appliance);
      jobAppliancesFilter['name'][appliance.professional_fullname] = appliance;
      if(!['',null,undefined].includes(appliance.professional_profession))
        jobAppliancesFilter['profession'][appliance.professional_profession] = appliance;
        if(!['',null,undefined].includes(appliance.professional_email))
        jobAppliancesFilter['email'][appliance.professional_email] = appliance;
      if(!['',null,undefined].includes(appliance.location))
        jobAppliancesFilter['location'][appliance.location] = appliance;
    }
    jobAppliances = data;
    selectedJobAppliances.value = data;
  }
  appliancesNumber.value = jobAppliances.length;
  isLoadingJobAppliances.value = false;
}

function searchAppliances(){
  if(applianceTerm.value != ''){
    let dataToDisplay = [];
    let usedId = [];
    for(let appliance of jobAppliances){
      let applianceData = {
        name: appliance['professional_fullname'].toLowerCase(),
        profession: appliance['professional_profession'] != undefined ? appliance['professional_profession'].toLowerCase() : '',
        email: appliance['professional_email'].toLowerCase(),
        location: appliance['location'].toLowerCase()
      };
      let used = false;
      for(let filter of ['name','profession','email','location']){
        if(used)
          break;
        let val = applianceData[filter];
        if([undefined, ''].includes(val))
          continue;
        let term = applianceTerm.value.toLowerCase();
        if(val.indexOf(term) != -1 && !usedId.includes(appliance['applied_id'])){
          dataToDisplay.push(appliance);
          used = true;
          usedId.push(appliance['applied_id']);
        }
      }
    }
    selectedJobAppliances.value = dataToDisplay;
  }else{
    selectedJobAppliances.value = jobAppliances;
  }
}

function checkApplianceSearch(){
  if(applianceTerm.value == '')
    selectedJobAppliances.value = jobAppliances;
}

const getSeverity = (jobStatus) => {
  switch (jobStatus) {
    case "published":
      return "success";
    case "validating":
      return "warning";
    case "hidden":
      return "secondary";
    case "draft":
      return "warning";
    default:
      return null;
  }
};

const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const breakPoint = 500;

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

function isScreenSmall(){
  return windowWidth.value > breakPoint ? false : true;
}

function handleResize(){
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
  let ulEl  = document.querySelector('.p-tabview-nav');
  let liEls = ulEl.querySelectorAll('.p-tabview-header');
  if(windowWidth.value <= breakPoint){
    ulEl.style.setProperty('display', 'flex', 'important');
    ulEl.style.setProperty('flex-wrap', 'wrap', 'important');
    for(let el of liEls){
      el.style.setProperty('width', '100%', 'important');
    }
  }else{
    ulEl.style.removeProperty('display');
    ulEl.style.removeProperty('flex-wrap');
    for(let el of liEls){
      el.style.removeProperty('width');
    }
  }
}
</script>