<template>
  <div>
    <footer
      class="w-full fixed bottom-0 4text-center bg-slate-400 border-t border-color-iron"
    >
      Rodapé
    </footer>
  </div>
</template>

<script></script>

<style scoped></style>
