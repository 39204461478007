<template>
    <div :class="isScreenSmall() ? props.classOnSmall : props.class">
        <template v-for="(socialMedia, index) in socialMedias" :key="index">
            <ShareNetwork 
                :network="socialMedia.name" 
                :url="sharerValues.link" 
                :title="sharerValues.title" 
                :description="sharerValues.description"
                :quote="sharerValues.title"
                :hashtags="sharerValues.hashtag"
                v-slot="{ share }"
            > 
                <button 
                    @click="share" 
                    class="py-3 px-5 w-1/3 mb-4 md:w-1/4 lg:w-1/6 text-white border rounded-full md:rounded-none" 
                    :style="{
                        'background-color' : socialMedia.bgColor, 'border-color': socialMedia.bgColor, 
                        'margin': (isScreenSmall() ? 'auto' : ''), 'margin-bottom': (isScreenSmall() ? '2rem' : '')
                    }"
                >
                    <i :class="socialMedia.icon" style="font-size: 1.3rem;">{{ socialMedia.icon == '' ? ucfirst(socialMedia.name) : '' }}</i>
                </button>
            </ShareNetwork>
        </template>
    </div> 
</template>
  
<script setup>
import { env } from "@/composables/config";
import { translate } from "@/composables/translate";
import { ShareNetwork } from "vue3-social-sharing";
import { ucfirst } from "@/composables/textUtils";
import { onMounted, ref, watch } from "vue";

let props = defineProps({
    link: {
        type: String,
        required: true,
        default: env('APP_BASE_URL')
    },
    title: {
        type: String,
        required: true,
        default: translate('check out this', 'ucfirst')
    },
    description: {
        type: String,
        required: false,
        default: ''
    },
    hashtag: {
        type: String,
        required: false,
        default: ''
    },
    class: {
        type: String,
        required: false,
        default: 'flex flex-wrap'
    },
    classOnSmall: {
        type: String,
        required: false,
        default: 'flex flex-wrap flex-col'
    }
})

const socialMedias = [
    {name: 'facebook',  icon: 'pi pi-facebook',  bgColor: '#3a5997'},
    {name: 'twitter',   icon: 'pi pi-twitter',   bgColor: '#1e1c1d'},
    {name: 'linkedin',  icon: 'pi pi-linkedin',  bgColor: '#007bb6'},
    {name: 'whatsapp',  icon: 'pi pi-whatsapp',  bgColor: '#43d853'},
    {name: 'email',     icon: 'pi pi-envelope',  bgColor: 'rgb(145, 145, 145)'},
    {name: 'telegram',  icon: 'pi pi-telegram',  bgColor: '#0087cc'}
];

let sharerValues = ref({
    link: props.link,
    title: props.title,
    description: props.description,
})
const windowWidth = ref(window.innerWidth);
const breakPoint = 500;
onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
  isScreenSmall();
  sharerValues.value.link = props.link;
  sharerValues.value.title = props.title;
  sharerValues.value.description = props.description;
});

watch(() => props.link, (newLink) => {
    sharerValues.value.link = newLink;
},
  { deep: true }
);
watch(() => props.title, (newTitle) => {
    sharerValues.value.title = newTitle;
},
  { deep: true }
);
watch(() => props.description, (newDescription) => {
    sharerValues.value.description = newDescription;
},
  { deep: true }
);
watch(() => props.hashtag, (newHastag) => {
    sharerValues.value.hashtag = newHastag;
},
  { deep: true }
);

function handleResize(){
  windowWidth.value = window.innerWidth;
}

function isScreenSmall(){
  return windowWidth.value > breakPoint ? false : true;
}

</script>