<template>
  <div class="pt-16 2xl:pt-24 pb-28">
    <section class="lg:container lg:mx-auto px-5">
      <div class="pb-6 bg-white xl:rounded-b-lg border border-color-iron">
        <!-- Foto de capa -->
        <div
          :style="{
            backgroundImage: `url(${profissionalData.professional_pprofessional_cover})`,
          }"
          class="w-full h-52 bg-slate-300 profissional-cover-photo"
        ></div>

        <!-- Foto de perfil -->
        <div
          :style="{ backgroundImage: `url(${profissionalData.professional_photo})` }"
          class="w-36 h-36 ml-6 md:ml-20 -mt-16 rounded-full bg-oxford-blue border-2 border-white profissional-profile-photo"
        ></div>

        <div
          class="w-full px-6 flex flex-col justify-center md:justify-normal md:pl-20 pt-3"
        >
          <span class="flex items-center mb-2">
            <h1 class="text-2xl font-semibold capitalize text-oxford-blue">
              {{ profissionalData.professional_firstname }}
              {{ profissionalData.professional_lastname }}
            </h1>
            <i
              class="pi pi-verified ml-2 font-medium text-sea-serpent"
              style="font-size: 1.3rem"
            ></i>
          </span>

          <h3 class="text-lg text-oxford-blue">
            BDA | Analista Funcional | Engenheiro de Software
          </h3>
          <span class="flex items-center pt-3">
            <i class="pi pi-building mr-1" style="font-size: 1.2em"></i>
            <h4 class="font-semibold">São Paulo, Brasil</h4>
            <h4 class="font-semibold">pais {{ profissionalData.visas.visa_type }}</h4>
          </span>
        </div>
      </div>
    </section>

    <!-- Seção Apresentação -->
    <section class="lg:container lg:mx-auto px-5">
      <Fieldset
        legend="Apresentação"
        class="w-full px-5 pb-5 mt-10 md:mt-7 bg-white rounded-lg shadow-md border border-color-iron"
      >
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, magnam,
          laborum ea iusto voluptate sed aspernatur odio consectetur earum ex molestiae
          doloribus quasi? Pariatur accusantium architecto illo modi iste provident! Lorem
          ipsum dolor, sit amet consectetur adipisicing elit. Facilis facere ad quaerat
          rerum dolores vitae eum, repellendus aspernatur ut numquam sit consectetur
          necessitatibus ipsam tenetur similique pariatur nostrum, minus possimus.
        </p>
      </Fieldset>
    </section>

    <!--  seção Informação completa -->
    <section class="grid grid-cols-1 lg:grid-cols-4 gap-3 px-5 lg:container lg:mx-auto">
      <div class="lg:col-span-3 flex flex-wrap md:flex-nowrap md:gap-x-3">
        <!--  seção idioma -->
        <section class="w-full md:w-1/3">
          <Fieldset
            legend="Idiomas"
            class="w-full px-5 pb-4 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <ul class="list-inside list-disc">
              <li
                v-for="(languages, index) in profissionalData.languages"
                :key="index"
                class="flex justify-between items-center gap-x-3"
              >
                <span>{{ languages.language.llangue_name }}</span>
                <ProgressBar
                  :value="50"
                  class="w-full lg:w-4/5 xl:w-40 h-3.5 bg-sea-serpente"
                />
              </li>
            </ul>
          </Fieldset>
          <Fieldset
            legend="Habilidades"
            class="w-full px-5 pb-4 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <ul class="list-inside list-disc">
              <li v-for="(skill, index) in profissionalData.skills" :key="index">
                {{ skill.language.tags_name }}
              </li>
            </ul>
          </Fieldset>
          <Fieldset
            legend="Informações Adicionais"
            class="w-full px-5 pb-4 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <ul v-if="profissionalData.visas" class="list-inside list-disc">
              <li v-for="(visa, index) in profissionalData.visas" :key="index">
                {{ visa.visa_type.type_name }}
              </li>
            </ul>
          </Fieldset>
          <Fieldset
            legend="Formação"
            class="w-full px-5 pb-4 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <ul>
              <li class="mb-4">
                <h1 class="leading-5 font-semibold text-oxford-blue">
                  Graduação em Sistemas de Informação
                </h1>
                <span class="text-sm">Universidade Federal SP</span>
              </li>
              <li class="mb-4">
                <h1 class="leading-5 font-semibold text-oxford-blue">
                  Técnico em Administração
                </h1>
                <span class="text-sm">Universidade Federal SP</span>
              </li>
            </ul>
          </Fieldset>
          <Fieldset
            legend="Tags"
            class="w-full px-5 pb-4 mt-5 text-sm bg-white rounded-lg shadow-md border border-color-iron"
          >
            <ul class="flex flex-wrap gap-y-2 gap-x-4 list-inside list-disc">
              <li>Javascript</li>
              <li>Pyton</li>
              <li>Tailwind</li>
              <li>PHP</li>
              <li>Laravel</li>
              <li>linux</li>
              <li>MySql</li>
              <li>MySql</li>
            </ul>
          </Fieldset>
        </section>

        <!-- seção Experiência -->
        <section class="w-full md:w-2/3">
          <Fieldset
            legend="Experiência"
            class="w-full px-5 pt-2 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <article class="mb-6">
              <div class="mb-2">
                <h1 class="uppercase font-bold text-oxford-blue">Analista Funcional</h1>
                <h3 class="text-sm font-semibold text-oxford-blue">Informático Ya</h3>
                <span class="font-semibold 4text-sm">
                  <small>Jan, 2021</small> - <small>Abr, 2023</small>
                </span>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, magnam,
                laborum ea iusto voluptate sed aspernatur odio consectetur earum ex
                molestiae doloribus quasi? Pariatur accusantium architecto illo modi iste
                provident! Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Facilis facere ad quaerat rerum dolores vitae eum, repellendus aspernatur
                ut numquam sit consectetur necessitatibus ipsam tenetur similique pariatur
                nostrum, minus possimus.
              </p>
            </article>
            <article class="mb-6">
              <div class="mb-2">
                <h1 class="uppercase font-bold text-oxford-blue">DBa - mysql</h1>
                <h3 class="text-sm font-semibold text-oxford-blue">
                  Expert Data Company
                </h3>
                <span class="font-semibold 4text-sm">
                  <small>Jan 2019</small> - <small>Set 2020</small>
                </span>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, magnam,
                laborum ea iusto voluptate sed aspernatur odio consectetur earum ex
                molestiae doloribus quasi? Pariatur accusantium architecto illo modi iste
                provident! Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Facilis facere ad quaerat rerum dolores vitae eum, repellendus aspernatur
                ut numquam sit consectetur necessitatibus ipsam tenetur similique pariatur
                nostrum, minus possimus.
              </p>
            </article>
          </Fieldset>
          <Fieldset
            v-if="profissionalData.curricular"
            legend="Formação Extra Curricular"
            class="w-full px-5 pb-4 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <ul>
              <li v-for="(curricular, index) in profissionalData.curricular" :key="index">
                {{ curricular.name }}
              </li>
            </ul>
          </Fieldset>
          <Fieldset
            v-if="profissionalData.references"
            legend="Referências"
            class="w-full px-5 pb-4 mt-5 bg-white rounded-lg shadow-md border border-color-iron"
          >
            <p>{{ profissionalData.reference }}</p>
          </Fieldset>
        </section>
      </div>

      <!-- Barra lateral Perfis semelhantes -->
      <div class="col-span-1 xl:pl-2">
        <div
          class="p-5 mt-5 md:mt-9 bg-white rounded-lg shadow-md border border-color-iron"
        >
          <h1 class="mb-4 font-semibold md:text-center lg:text-left text-oxford-blue">
            Perfis semelhantes
          </h1>
          <ul class="md:flex md:flex-wrap md:justify-center gap-5 md:mt-7 lg:mt-0">
            <li
              v-for="n in 6"
              :key="n"
              class="flex gap-x-1 md:flex-col lg:flex-row md:items-center lg:items-start md:gap-3 md:p-4 lg:p-0 mb-3 md:bg-white lg:md:bg-none md:shadow-lg lg:shadow-none"
            >
              <img
                src="@/assets/images/avatar.png"
                class="w-11 h-11 md:w-20 md:h-20 lg:w-11 lg:h-11 xl:w-14 xl:h-14 rounded-full"
              />
              <span class="md:text-center lg:text-left">
                <h2 class="text-sm font-medium capitalize text-oxford-blue">
                  Mungongo Teixeira Martuns
                </h2>
                <h3 class="text-sm font-normal">Engenheiro de Software</h3>
                <h4 class="text-sm font-normal">Lisbo, Portugal</h4>
              </span>
            </li>
          </ul>
        </div>

        <!-- Área de publicidade -->
        <div
          class="p-5 mt-6 py-10 bg-white rounded-lg shadow-md border border-color-iron"
        >
          <h1 class="font-bold text-center text-oxford-blue">Espaço para publicidade</h1>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import Fieldset from "primevue/fieldset";
import ProgressBar from "primevue/progressbar";
import { onBeforeMount, reactive } from "vue";
import { useRoute } from "vue-router";

import useFetchData from "@/composables/fetchData.js";
  

let profissionalData = reactive([]);
let route = useRoute();

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############

async function getProfissional() {
  await useFetchData
    .get(`/professional/find/${route.params.name}`)
    .then((response) => {
      profissionalData = response.data;
      console.log("resp: ", profissionalData);
    })
    .catch((error) => {
      console.log("erro: ", error);
    });
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############

onBeforeMount(() => {
  getProfissional();
});
</script>

<style scoped>
.profissional-cover-photo {
  /* background-image: url(""); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.profissional-profile-photo {
  /* background-image: url("@/assets/images/img-login.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
