<template>
  <div class="">
    <div class="flex flex-col gap-y-10 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8">
      <!-- Seção visão geral estatísticas -->
      <section v-if="[undefined, null, 0].includes(env('APP_BETA'))">
        <ul class="flex flex-wrap justify-between gap-y-4 md:gap-x-1f lg:gap-x-2">
          <li
            v-for="(overview, index) in dataOverview"
            :key="index"
            class="w-full md:w-[24.4%] lg:w-[24%] md:h-24 flex justify-between items-center bg-white shadow-lg p-3 md:px-5 rounded-md border-t-2 border-sea-serpent hover:text-white hover:bg-sea-serpent"
          >
            <span>
              <h1
                class="mb-1 text-xl xl:text-3xl font-semibold text-oxford-blue hover:text-white"
              >
                {{ overview.number }}
              </h1>
              <RouterLink
                :to="`/dashboard/${username}/settings`"
                class="text-sm font-normal"
                >{{ overview.name }}
              </RouterLink>
            </span>
            <i class="pi pi-briefcase" style="font-size: 1.5rem"></i>
          </li>
        </ul>
      </section>

      <DataTables :displayButtons="true" />

      <!-- Donations section -->
      <DonationLinkComponent />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { RouterLink } from "vue-router";
import { env } from "@/composables/config";

// import DashboardCharts from "@/components/Dashboard/DashboardCharts.vue";
// import ApplicantsRecomendation from "@/components/Dashboard/Company/ApplicantsRecomendation.vue";
import DataTables from "@/components/Dashboard/Applicant/DataTables";
import DonationLinkComponent from "@/components/DonationLinkComponent"

const store = useStore();
let username = computed(() => store.state.username);

const dataTable = ref();

onMounted(() => {
  dataTable.value = [
    {
      name: "Director Esportivo",
      image: "link-url-image",
      aplicants: 3453,
      ration: 3,
      address: "RJ, Brasil",
      date: "17, Fevereiro, 2023",
      inventoryStatus: "ABERTO",
    },
    {
      name: "Engenheiro BDA",
      image: "link-url-image",
      aplicants: 3453,
      ration: 3,
      address: "Porto, Portugal",
      date: "17, Fevereiro, 2023",
      inventoryStatus: "Aberto",
    },
    {
      name: "Engenheiro de Software Backend PHP",
      image: "link-url-image",
      aplicants: 3453,
      ration: 3,
      address: "Madrid, Espanhã",
      date: "17, Fevereiro, 2023",
      inventoryStatus: "Fechado",
    },
    {
      name: "Cozinheiro",
      image: "link-url-image",
      aplicants: 3453,
      ration: 3,
      address: "Orlando, USA",
      date: "17, Fevereiro, 2023",
      inventoryStatus: "Pendente",
    },
  ];
});

const dataOverview = ref([
  { name: "Vagas Publicadas", number: 34.554 },
  { name: "Total Vagas", number: 3.554 },
  { name: "Vagas Regeitadas ", number: 54 },
  { name: "Total Reviews", number: 30 },
  { name: "Vagas Publicadas", number: 34.554 },
  { name: "Total Vagas", number: 3.554 },
  { name: "Vagas Regeitadas ", number: 54 },
  { name: "Total Reviews", number: 30 },
]);
</script>
