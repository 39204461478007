import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const typeVisa = {
    async listTypeVisa() {
        try {
            let typeVisaCombo = store.getters.getOnComboChest('type_visas');
            if(typeVisaCombo !== null && typeVisaCombo !== undefined && !utils.isEmpty(typeVisaCombo))
                return typeVisaCombo;
            const result = await requestHandler.executeRequest('api/type_visas/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'type_visas',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default typeVisa;