<template>
  <div class="p-5b 4grid 4grid-cols-3 4gap-4 5pt-12 5pb-16 5px-5 7xs:pl-8 7xs:pr-4">
    <!-- Componentes de mensagens/Chat -->
    <DashboardChat
      :data="messages"
      :my-messages="myMessages"
      @send-message="sendMessage"
    />

    <!-- <section class="col-span-34 4md:col-span-2 5flex 5flex-col 5gap-y-5"></section> -->
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

import DashboardChat from "@/components/Dashboard/DashboardChat.vue";

// ############ Declaração de variáveis ############

let myMessages = ref({
  id: 4,
  name: "Ravana Miranda",
  message: "Esta é uma mensagem de teste 4",
});
//let toast = useToast();

let messages = ref([
  { id: 1, name: "John Doe", message: "Esta é uma mensagem de teste 1" },
  { id: 2, name: "Nickolas", message: "Esta é uma mensagem de teste 2" },
  { id: 3, name: "Vanessa Pieres", message: "Esta é uma mensagem de teste 3" },
  { id: 4, name: "Ravana Miranda", message: "Esta é uma mensagem de teste 4" },
  { id: 5, name: "Estevão Luis", message: "Esta é uma mensagem de teste 5" },
]);

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############
function sendMessage() {
  console.log("Mensagem enviada");
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onMounted(() => {});
</script>
