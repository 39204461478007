<template>
    <section class="mt-5 mb-5 border-iron rounded py-3 text-center py-5">
        <h1 class="font-semibold text-xl">{{ translate('are you enjoying jobifull?', 'ucfirst') }}</h1>
        <p class="mt-5 mb-5 text-lg">{{ translate('help us improve even more!', 'ucfirst') }}</p>
        <div class="mt-5 pt-5">
            <a 
                class="py-3 text-xl px-3 text-white rounded-full bg-sea-serpent w-full md:w-1/3" 
                :href="getLink()"
                target="_blank"
            >
                <i class="pi pi-external-link me-2"></i>{{ translate('know more how', 'upper') }}
            </a> 
        </div>
    </section>
</template>
<script setup>
    import store from "@/store";
    import { translate } from "@/composables/translate";

    const donationsLinks = {
        en: 'https://jobifull.my.canva.site/en',
        pt: 'https://jobifull.my.canva.site/pt',
        es: 'https://jobifull.my.canva.site/es'
    }
    function getLink(){
        let userLang = store.getters.getUserLang;
        if(donationsLinks[userLang] == undefined)
            userLang = 'en';
        return donationsLinks[userLang];
    }
</script>