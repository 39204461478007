<template>
  <div class="flex w-full">
    <!-- Menu lateral empresa -->
    <CompanySidebarMenu id="sideBarMenu" v-if="userLogged && userType == 'company'" />

    <!-- Menu lateral profissional -->
    <ApplicantSidebarMenu id="sideBarMenu" v-else-if="userLogged &&userType == 'professional'" />

    <!-- Menu lateral recrutador -->
    <RecruiterSidebarMenu id="sideBarMenu" v-else-if="userLogged && userType == 'recruiter'" />

    <div class="w-full bg-white">
      <!-- Barra de navegação do topo -->
      <DashboardHeader />

      <div id="observed-content" class="w-full rounded-tl-3xl border border-iron" style="background: #f6f8fc">
        <RouterView> </RouterView>
      </div>

      <!-- Rodapé -->
      <DashboardFooter class="hidden" />
    </div>
  </div>
</template>

<script setup>
  import { RouterView } from "vue-router";
  import { useStore } from "vuex";
  import DashboardHeader from "@/components/Dashboard/DashboardHeader.vue";
  import DashboardFooter from "@/components/Dashboard/DashboardFooter.vue";
  import CompanySidebarMenu from "@/components/Dashboard/Company/SidebarMenu.vue";
  import ApplicantSidebarMenu from "@/components/Dashboard/Applicant/SidebarMenu.vue";
  import RecruiterSidebarMenu from "@/components/Dashboard/Recruiter/SidebarMenu.vue";

  const store = useStore();
  const userLogged = store.getters.isLoggedIn;
  const userType   = store.getters.getLoggedAs;
</script>