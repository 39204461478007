import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const jobContract = {
    async listJobContract() {
        try {
            let jobContractCombo = store.getters.getOnComboChest('job_contract');
            if(jobContractCombo !== null && jobContractCombo !== undefined && !utils.isEmpty(jobContractCombo))
                return jobContractCombo;
            const result = await requestHandler.executeRequest('api/job_contract/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'job_contract',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default jobContract;