import requestHandler from "@/composables/requestHandler";
import crsfHandler from "@/composables/csrfHandler";
import store from "@/store";
import Cookies from "js-cookie";
import { translate } from "./translate";
import cookiesHandler from "./cookiesHandler";
import routeHandler from "./routeHandler";

export default {
  async register(form) {
    await crsfHandler.getCRSF(true)
    
    return requestHandler.executeRequest("api/register", form);
  },

  async login(form) {
    await crsfHandler.getCRSF(true)

    form['acceptedCookies'] = cookiesHandler.getEncryptedCookie('cookies-acceptance');
    const response = await requestHandler.executeRequest("api/login", form);
    if(response.success){
        store.dispatch('saveLoginInformation', response.data);
    }
    return response;
  },

  async loginAndRedirect(form, toUrl = ''){
    await crsfHandler.getCRSF(true)

    const response = await this.login(form)
    if(response.success)
      requestHandler.redirect(toUrl);
  },

  async logout(cleanCacheOnly = false) {
    let response = null;
    if(!cleanCacheOnly)
      response = await requestHandler.executeRequest("api/logout");
    if(response.success || cleanCacheOnly){
        Cookies.remove('XSRF-TOKEN');
        Cookies.remove('jobifull_session');
        Cookies.remove('aut_data');
        Cookies.remove('last_login_as');
        store.dispatch('cleanAuthData');
    }
    return response;
  },

  /**
   * Attempts to login by checking if a XRSF-TOKEN exists
   */
  async attemptLoginByCookies(){
    if(!Cookies.get('XSRF-TOKEN') || store.getters.isLoggedIn)
      return false;
    store.dispatch('saveIsTyingToLogin', true);
    const response = await this.profile();
    if(!response.success)
        return false;
    store.dispatch('saveLoginInformation', response.data);
    if(response.data.person.accepted_cookies != null)
      store.dispatch('saveCookiesAccepted', response.data.person.accepted_cookies);
    let result = await routeHandler.checkExpectedRoute();
    if(result)
      return;
    let userProfileType = store.getters.hasOnlyOneProfile;
    if(userProfileType){
      store.commit('setLoggedAs', userProfileType);
      await requestHandler.redirect(`/dashboard/${userProfileType}/${store.state.user.slug}`);
    }else{
      let myProfile = store.getters.getProfileTypes;
      store.commit('setLoggedAs', myProfile[0]);
      await requestHandler.redirect(`/dashboard/${myProfile[0]}/${store.state.user.slug}`);
    }
    return true;
  },

  async profile() {
    return requestHandler.executeRequest("api/profile", {}, 'post');
  },

  async forgotPassword(email){
    await crsfHandler.getCRSF();
    return requestHandler.executeRequest("api/password/email", {email: email}, 'post');
  },

  async resetPassword(params = {}){
    const parameters = {
      email: params.email !== undefined ? params.email : '',
      password: params.password !== undefined ? params.password : '',
      password_confirmation: params.password_confirmation !== undefined ? params.password_confirmation : '',
      token: params.token !== undefined ? params.token : '',
    };
    await crsfHandler.getCRSF();
    return requestHandler.executeRequest("api/password/reset", parameters, 'post');
  },

  profileIsValid(profileName = ''){
    if(!store.getters.getLoggedUserProfiles.includes(profileName))
        return false;
    return true;
  },

  checkPassword(password = ''){
    let response = {
      success: false,
      message: ''
    }
    if (password.length < 6){
      response.message = `${translate('password is too shot, it must have more than 6 characters length', 'ucfirst')}:`;
      return response;
    }
    if (password.length > 20){
      response.message = `${translate('password is too long, it must have more than 20 characters length', 'ucfirst')}.`;
      return response;
    }
    const specialCharRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if(!specialCharRegex.test(password)){
      response.message = translate("invalid password, enter a special character.", 'ucfirst');
      return response;
    }
    const numberRegex = /[0-9]/;
    if(!numberRegex.test(password)){
      response.message = `${translate("invalid password, enter a number", 'ucfirst')}.`;
      return response;
    }
    const upperCasaRegex = /[A-Z]/;
    if(!upperCasaRegex.test(password)){
      response.message = `${translate("invalid password, enter an uppercase letter", 'ucfirst')}.`;
      return response;
    }
    const lowerCaseRegex = /[a-z]/;
    if(!lowerCaseRegex.test(password)){
      response.message = `${translate("invalid password, enter a lowercase letter", 'ucfirst')}.`;
      return response;
    }
    response.success = true;
    return response;
  },

  checkEmail(email = ''){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
};
