<template>
    <LoadingDialog :visible="actionIsBeingExecuted" />

    <ConfirmationModal 
      :title="currentConfirmationData.title"
      :text="currentConfirmationData.text"
      :visible="confirmationModalVisibility"
      @emitResponse="confirmationResponse"
    />
    <LoaderComponent class="col-span-5 text-center pt-5 mt-5" v-if="tabLoading" />
    <div class="content-wrapper col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5" v-if="!tabLoading">
        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-6 text-lg font-semibold">{{ translate('languages', 'ucfirst') }}</h1>
            <form
                class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-6 text-base font-medium"
            >
                <span class="col-span-3 p-float-label">
                    <AutoComplete
                        v-model="languageName" 
                        :suggestions="languagesList" 
                        optionLabel="name"
                        @complete="searchForLanguages"
                        :placeholder="`${translate('type to search')}...`"
                        :emptyMessage="translate('no results found', 'ucfirst')"
                        :emptySearchMessage="translate('no results found', 'ucfirst')"
                        :inputClass="'w-full'"
                        multiple
                        fluid  
                        dropdown
                        class="w-full rounded border border-iron autocomplete-w-full"
                    />
                </span>
                <hr class="iron pt-2 pb-2 col-span-3">
                <div class="col-span-3 flex flex-wrap">
                    <div v-for="(language, index) of languageName" :class="`w-full mb-3 md:pe-3 md:w-1/3 language-opt-${language.code}-${language.name.replace(' ', 'aaaa-aaaa-aaaa')}`" :key="index">
                        <span>{{ language.name }}</span>: 
                        <Dropdown
                            v-model="senioritiesCombo.selected[`${language.code}-${language.name.replace(' ', 'aaaa-aaaa-aaaa')}`]"
                            :options="senioritiesCombo.data"
                            @change="highlightDropDown(`${language.code}-${language.name.replace(' ', 'aaaa-aaaa-aaaa')}`, false);"
                            optionLabel="name"
                            :placeholder="translate('select a seniority', 'ucfirst')"
                            class="w-full rounded-full border border-iron dropdown-el"
                            required
                            filter
                            :id="`seniority-${language.code}-${language.name.replace(' ', 'aaaa-aaaa-aaaa')}`"
                        />
                    </div>
                </div>
            </form>
        </section>
    </div>
    <ActionCard 
        v-if="!tabLoading"
        :jobId="jobId"
        :jobStatus="props.jobStatus"
        :actionButtonText="translate('save job languages', 'ucfirst')"
        @emitClick="openConfirmationModal('languageSave')"
        class="col-span-3 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
    />
</template>

<script setup>
    import { ref, onMounted, watch, reactive, onBeforeMount } from "vue";
    import requestHandler from "@/composables/requestHandler";
    import AutoComplete from "primevue/autocomplete";
    import { translate } from "@/composables/translate";
    import { sleep } from "@/composables/sleep";
    import store from "@/store";
    import ActionCard from "@/components/Dashboard/Company/SaveJob/ActionCard"
    import ConfirmationModal from "@/components/Utils/ConfirmationModal";
    import Dropdown from "primevue/dropdown";
    import jobData from "@/composables/jobData";
    import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
    import { showToast } from "@/composables/toast";
    import { useToast } from "primevue/usetoast";
    import LoaderComponent from "@/components/Utils/LoaderComponent.vue";

    let toast = useToast();

    const props = defineProps({
        jobId: {
            type: String,
            required: false,
            default: null
        },
        jobStatus: {
            type: String,
            required: false,
            default: 'draft'
        }
    });

    let tabLoading = ref(true);
    let actionIsBeingExecuted = ref(false);
    let jobId = ref(null);
    onMounted(function(){
        handleAutoCompleteElements();
        getEssentialData();
    });

    onBeforeMount(function(){
        jobId.value = props.jobId;
        if(jobId.value == null)
            goToJobPage();
    });

    async function getEssentialData(){
        tabLoading.value = true;
        await feedSenioritiesCombo();
        await feedLanguagesCombo();
        await fetchAndFeedlanguages();
        tabLoading.value = false;
    }

    async function fetchAndFeedlanguages(){
        if(!jobId.value)
            return;
        let values = await jobData.searchJobLanguages(jobId.value);
        languageName.value = values.language;
        for(let key in values.seniority){
            senioritiesCombo.selected[key] = values.seniority[key];
        }
    }

    let senioritiesCombo = reactive({
        data: [],
        selected: [],
        loading: false
    })
    async function feedSenioritiesCombo(){
        senioritiesCombo.loading = true;
        const result = await jobData.getJobComboData('language_level');
        senioritiesCombo.data = result;
        senioritiesCombo.loading = false;
    }

    let languagesCombo = reactive({
        data: [],
        selected: [],
        loading: []
    })
    async function feedLanguagesCombo(){
        languagesCombo.loading = true;
        const result = await jobData.getJobComboData('languages');
        languagesCombo.data = result;
        languagesCombo.loading = false;
    }

    async function goToJobPage(){
        await requestHandler.redirect(`/dashboard/company/${store.getters.getUserSlug}/post-job`);
    }

    watch(() => props.jobId, (newValue) => {
      jobId.value = newValue;
      handleAutoCompleteElements();
    });

    
    let confirmationModalVisibility = ref(false);
    const confirmationData = {
        languageSave: {
            title: translate('are you sure?', 'ucfirst'),
            text: translate('do you wish to save these job required languages? This action will make your job unavaliable until it passes by our validation trials', 'ucfirst')
        }
    }
    let currentConfirmationData = reactive({
        title: '',
        text: '',
        action: ''
    })
    function openConfirmationModal(action = ''){
        currentConfirmationData.action = '';
        confirmationModalVisibility.value = false;
        if(action == '' || !['languageSave'].includes(action))
            return;
        currentConfirmationData.action = action;
        currentConfirmationData.title = confirmationData[action].title;
        currentConfirmationData.text  = confirmationData[action].text;
        confirmationModalVisibility.value = true;
    }

    let languageName = ref([]);
    let languagesList = ref([]);
    async function searchForLanguages(event){
      sleep(500);
      languagesList.value = [];
      let term = event.query.toLowerCase();
      let matches = [];
      for(let lang of languagesCombo.data){
        let undercase = lang.name.toLowerCase();
        if(undercase.indexOf(term) != -1){
            matches.push(lang);
        }
      }
      languagesList.value = matches;
    }

    async function handleAutoCompleteElements(){
      let tries = 0;
      let autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
      while(autocompleteEls.length < 1){
          autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
          tries++;
          await sleep(50);
          if(tries == 30){
            break;
          }
      }
      if(autocompleteEls.length < 1)
          return;
      autocompleteEls.forEach(el => {
        if(el.style.width == '')
          el.style.setProperty('width', '100%', 'important');
          const ulEl = el.querySelector('ul');
          if(ulEl){
            ulEl.style.setProperty('width', '100%', 'important');
            // ulEl.style.setProperty('border-radius', '9999px', 'important');
            let inputEl = ulEl.querySelector('input');
          if(inputEl){
            inputEl.style.setProperty('width', '100%', 'important');
            inputEl.style.setProperty('outline', 'unset', 'important');
          }
        }
      });
    }

    async function confirmationResponse(result){
        confirmationModalVisibility.value = false;
        if(result.confirmation){
            actionIsBeingExecuted.value = true;
            switch(currentConfirmationData.action){
                case 'languageSave':
                    await saveLanguages();
                break;
            }
            actionIsBeingExecuted.value = false;
        }
        currentConfirmationData.action = '';
    }

    async function saveLanguages(){
        let jobsIds   = [];
        let jobsNames = [];
        let jobSeniorities = [];
        for(let data of languageName.value){
            jobsIds.push(data['code']);
            jobsNames.push(data['name']);
            let id = `${data.code}-${data.name.replace(' ', 'aaaa-aaaa-aaaa')}`;
            if(senioritiesCombo.selected[id] == undefined){
                showToast(toast, translate('no seniority informed'), 'error');
                highlightDropDown(id);
                return;
            }
            highlightDropDown(id, false);
            jobSeniorities.push(senioritiesCombo.selected[id].code);
        }
        const response = await requestHandler.executeRequest(`api/joblist/managelanguage/${jobId.value}`, {
            action: 'sync', job_languages_ids: jobsIds, job_languages_names: jobsNames, job_languages_seniorities: jobSeniorities
        }, 'post');
        if(!response.success){
            let errorMessage = jobsIds.length > 1 ? translate('languages not saved') : translate('language not saved');
            showToast(toast, errorMessage, 'error');
            return;
        }
        let successMessage = jobsIds.length > 1 ? translate('languages saved') : translate('language saved');
        showToast(toast, successMessage);
        await jobData.updateMyCompanyJobs(jobId.value, response.data.data);
        store.dispatch('saveOnDataChest', {
            key: `save_job_${jobId.value}`,
            value: response.data.data
        });
    }

    function highlightDropDown(id = '', mark = true){
        id = id.replace(' ', 'aaaa-aaaa-aaaa');
        let el = document.querySelector(`.language-opt-${id}`);
        if(el != undefined && el.querySelector('.dropdown-el') != null){
            el.querySelector('.dropdown-el').style.border = mark ? '1px solid red' : '';
        }
    }
</script>