import Translation from '@/models/Translation';
import store from '@/store';
import { env } from '@/composables/config';

const languageData = {
    getAllLanguages(){
        if(store.getters.getLanguages !== null){
            return store.getters.getLanguages;
        }
        let languages = null;
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', '/data/languages.json', false);
            xhr.send();
            if (xhr.status === 200) {
                // const validLangs = ['en','pt','es'];
                languages = JSON.parse(xhr.responseText);
                let oficialLangs = {};
                for(let lang in languages){
                    // if(validLangs.includes(lang))
                    let values = languages[lang]
                    values['isoCode'] = lang;
                    oficialLangs[lang] = values;
                }
                languages = oficialLangs;
                store.dispatch('saveLanguages', languages);
            }
        } catch (error) {
            languages = null;
        }
        return languages;
    },

    listLanguages(langIso = 'en'){
        try {
            const response = this.getAllLanguages();
            let languagesArray = [];
            let languagesNameArray = [];
            for(let languageData of response.data){
                let langObj = new Translation(languageData);
                langObj.setTargetLanguageIso(langIso);
                languagesArray.push(langObj);
                languagesNameArray.push({name: langObj.targetLanguageIsoCode, id:langObj.llangue_id});
            }
            return {objects: languagesArray, names: languagesNameArray};
        } catch (error) {
            return [];
        }
    },

    getBannerUrl(langIso = ''){
        let path = env('APP_API_URL') + 'data/flag/';
        return langIso != '' ? path + langIso + '.svg' : path;
    },

    getOfficialLanguagesIsoCodes(){
        return [
            'en', 'pt', 'es'
        ];
    },

    getDefaultLanguageIsoCode(){
        return 'en';
    },

    async getLangNameByLanguageIso(languageIsoCode = 'en'){
        let languages = this.getAllLanguages();
        if(languages[languageIsoCode] == undefined || languages[languageIsoCode][languageIsoCode] == undefined){
            try {
                return languages[this.getDefaultLanguageIsoCode][this.getDefaultLanguageIsoCode];
            } catch (error) {
                return '';                
            }
        }
        return languages[languageIsoCode][languageIsoCode];
    }
}

export default languageData;