import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const professions = {
    async listProfessions() {
        try {
            let professionsCombo = store.getters.getOnComboChest('professions');
            if(professionsCombo !== null && professionsCombo !== undefined && !utils.isEmpty(professionsCombo))
                return professionsCombo;
            const result = await requestHandler.executeRequest('api/professions/list', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'professions',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default professions;