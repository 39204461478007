import requestHandler from '@/composables/requestHandler';
import Translation from '@/models/Translation';
import store from '@/store';

const companyService = {
    async listCompanyTypes() {
        try {
            const response = await requestHandler.executeRequest('api/company_types/getcompanytypes', {}, 'get');
            let companyTypes = [];
            let companyTypesArray = [];
            for (let languageData of response.data) {
                let companyTypesObj = new Translation(languageData);
                companyTypesObj.setTargetLanguageIso(store.getters.getUserLang);
                companyTypes.push(companyTypesObj);
                companyTypesArray.push({ name: companyTypesObj.targetLanguageIsoCode, id: companyTypesObj.company_type_id });
            }
            return { objects: companyTypes, names: companyTypesArray };
        } catch (error) {
            return [`Error: ${error}`]
        }
    },

    async listCompanies(limit = 10, page = 1){
        let params = {
            per_page: limit,
            page: page,
            user_lang: store.getters.getUserLang
        };
        return requestHandler.executeRequest('api/company/index', params, 'get');
    }
}

export default companyService;