<template>
  <div class="h-screen flex 6gap-4 6pt-5 overflow-hidden">
    <!--  -->
    <section
      class="w-full md:w-1/4 lg:w-96 py-14 px-6 rounded-l-2xl bg-white overflow-y-scroll border-r border-iron"
    >
      <ul class="flex flex-col justify-center">
        <li
          v-for="(configField, index) in configFields"
          :key="index"
          class="py-7 border-b border-iron"
        >
          <a
            href="#"
            @click.prevent="showSetting"
            class="text-[0.99rem] font-semibold text-oxford-blue"
          >
            {{ configField.name }}
          </a>
          <p class="text-sm font-medium">{{ configField.helperText }}</p>
        </li>
      </ul>
    </section>

    <!-- Seção campo de formulários -->
    <section class="w-full py-14 px-10 bg-white">
      <form class="grid grid-cols-12text-base font-medium">
        <span class="col-span-12 pb-6">
          <label for="value">Titulo da vaga</label>
          <InputText
            v-model.trim="settingsFrom.name"
            type="text"
            id="text"
            placeholder="Seu nome"
            class="w-full px-3 py-2 mt-2.5 rounded-full border border-iron"
          />
        </span>
        <span class="col-span-12 py-6">
          <label for="value">Tipo da vaga</label>
          <Dropdown
            v-model="settingsFrom.name"
            :options="jobtypes"
            optionLabel="name"
            placeholder="Seleciona um Tipo"
            class="w-full rounded-full border border-iron"
          />
        </span>
      </form>
    </section>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

// import { useToast } from "primevue/usetoast";

let settingsFrom = reactive({
  name: "",
  location: "",
});

let configFields = reactive([
  { name: "Minha Conta", helperText: "Informações da conta" },
  { name: "Privacidade", helperText: "Definições de privacidade" },
  { name: "Compartilhamento", helperText: "Definições de compartilhamento" },
]);

// ############ Criação de Métodos/Funções ############

function showSetting() {}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
</script>
