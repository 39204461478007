<template>
    <ProgressSpinner 
        :style="{'width': '50px','height': '50px'}"
        strokeWidth="8" 
        fill="transparent" 
        animationDuration=".9s"
        aria-label="Custom ProgressSpinner"
        :class="props.class"
    />
</template>
<script setup>
    import ProgressSpinner from 'primevue/progressspinner';

    const props = defineProps({
        class: {
            type: String,
            required: false,
            default: ''
        }
    });
</script>

<style>
    .p-progress-spinner-circle{
        stroke: rgb(83 195 196)!important;
    }
</style>