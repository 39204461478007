<template>
  <div>
    <div
      class="grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-x-6 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8"
    >
      <Steps
        :model="items"
        class="hidden col-span-1 md:col-span-3 xl:col-span-2 p-3 bg-gray-800 white shadow-md rounded-md border border-iron"
      />

      <!-- Seção dos campos formulário -->
      <div class="col-span-1 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5">
        <section
          class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron"
        >
          <h1 class="mb-7 text-lg font-semibold">Informações do Curriculo</h1>

          <form
            v-on:submit="createCurriculo"
            class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-7 text-base font-normal"
          >
            <span class="col-span-1 md:col-span-3 p-float-label">
              <InputText
                v-model.trim="applicantForm.ability"
                type="text"
                id="ability"
                class="w-full px-3 py-2 rounded-full border border-iron"
              />
              <label for="ability">Quais são as suas habilidades?</label>
            </span>

            <div class="col-span-1 md:col-span-3 grid grid-cols-1 md:grid-cols-2 gap-x-5">
              <span class="p-float-label">
                <Dropdown
                  v-model="applicantForm.counties"
                  :options="counties"
                  optionLabel="name"
                  placeholder="Selecione seu País"
                  class="w-full rounded-full border border-iron"
                />
                <label>País de Residência</label>
              </span>

              <span class="p-float-label">
                <InputText
                  v-model.trim="applicantForm.address"
                  type="text"
                  class="w-full px-3 py-2 rounded-full border border-iron"
                />
                <label>Cidade de Residência</label>
              </span>
            </div>

            <span class="col-span-3 p-float-label">
              <MultiSelect
                @click="visible = true"
                :options="languagesList"
                optionLabel="name"
                display="chip"
                placeholder="Selecione"
                :maxSelectedLabels="6"
                class="w-full rounded-full border border-iron"
              />
              <label>Quais Línguas você fala?</label>
            </span>

            <span class="col-span-1 md:col-span-3">
              <TextArea
                v-model.trim="applicantForm.about"
                placeholder="Descreva mais sobre você"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
            </span>
            <span class="col-span-1 md:col-span-3">
              <TextArea
                v-model.trim="applicantForm.about"
                placeholder="Informações Adicionais"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
            </span>
          </form>
        </section>

        <!-- Seção -->
        <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-5">
          <section class="w-full p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-4 text-lg font-semibold">Nível de Senioridade</h1>

            <MultiSelect
              v-model="applicantForm.seniority"
              :options="counties"
              optionLabel="name"
              display="chip"
              placeholder="Selecionar Nível"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />
          </section>
          <!-- Seçção -->

          <!-- Seçção -->
          <section class="w-full p-6 bg-white shadow-md rounded-md border border-iron">
            <h1 class="mb-4 text-lg font-semibold">Vistos</h1>
            <MultiSelect
              :options="counties"
              optionLabel="name"
              display="chip"
              placeholder="Selecionar os Vistos"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />
          </section>

          <!-- Seçção Fromação-->
          <section
            class="col-span-3 w-full grid grid-cols-2 p-6 gap-3 bg-white shadow-md rounded-md border border-iron"
          >
            <h1 class="col-span-2 mb-4 text-lg font-semibold">Formação</h1>
            <MultiSelect
              :options="counties"
              optionLabel="name"
              display="chip"
              placeholder="Curso Graduação"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />

            <MultiSelect
              :options="counties"
              optionLabel="name"
              display="chip"
              placeholder="Universidade"
              :maxSelectedLabels="4"
              class="w-full rounded-full border border-iron"
            />
          </section>

          <!-- Seçção Fromação-->
          <section
            class="col-span-3 w-full grid grid-cols-2 p-6 gap-3 bg-white shadow-md rounded-md border border-iron"
          >
            <h1 class="col-span-2 mb-4 text-lg font-semibold">Experiência de Trabalho</h1>
            <span class="col-span-1 md:col-span-3">
              <TextArea
                v-model.trim="applicantForm.about"
                placeholder="Informações Adicionais"
                class="w-full px-3 py-2 rounded-xl border border-iron"
              />
            </span>
          </section>
        </div>
      </div>

      <!-- Barra lateral direita -->
      <aside
        class="col-span-3 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]"
      >
        <Panel
          header="Publicar"
          :collapsed="false"
          toggleable
          class="shadow-md border border-iron"
        >
          <div class="m-0 relative">
            <div class="flex flex-wrap gap-y-3 justify-between items-center mb-4">
              <Button
                label="Salvar rascunho"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
              />
              <Button
                label="Preview"
                class="py-1 px-3 text-sm text-white rounded-md bg-sea-serpent"
              />
            </div>
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex items-center gap-2">
                <i class="pi pi-folder"></i>
                <p>Estado:</p>
                <strong>Rascunho</strong>
              </li>
              <li class="flex items-center gap-2">
                <i :class="`pi ${jobStatus == 1 ? 'pi-eye' : 'pi-eye-slash'}`"></i>
                <p>Visibilidade:</p>
                <strong>Público</strong>
              </li>
              <li class="flex items-center gap-2">
                <i class="pi pi-calendar"></i>
                <p>Publicar:</p>
                <strong>imediatamente</strong>
              </li>
            </ul>
          </div>
          <template #footer>
            <div class="flex justify-between items-center pt-3 border-t border-iron">
              <p class="text-red-800 cursor-pointer underline">Mover para lixeira</p>
              <Button
                type="submit"
                label="Publicar"
                class="py-1 px-3 text-white rounded-md bg-sea-serpent"
              />
            </div>
          </template>
        </Panel>
      </aside>
      <!-- Fim barra lateral direita -->

      <Dialog
        v-model:visible="visible"
        modal
        :style="{ width: '37rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <template #header>
          <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">Idioma e Proficiência</span>
          </div>
        </template>
        <div class="flex flex-wrap justify-between gap-4 mb-4 md:flex-nowrap">
          <Dropdown
            v-model="selected.language"
            :options="languagesList"
            optionLabel="name"
            placeholder="Selecione seu Idioma"
            class="w-full max-w-[38%] rounded-full border border-iron"
          />
          <Dropdown
            v-model="selected.proficience"
            :options="languagesLevel"
            optionLabel="name"
            placeholder="Selecione a Proficiência"
            class="w-full max-w-[38%] rounded-full border border-iron"
          />
          <Button
            @click="spokenLanguages"
            label="Adicionar"
            :disabled="
              selected.proficience != '' && selected.language != '' ? false : true
            "
            :class="
              selected.proficience != '' && selected.language != ''
                ? ''
                : 'cursor-not-allowed'
            "
            class="w-full md:w-1/6 text-white pl-2 pr-5 py-2 rounded-md bg-sea-serpent"
          />
        </div>
        <div></div>
        <DataTable
          v-if="applicantForm.languages.length"
          :value="applicantForm.languages"
          tableStyle="max-width: 100%; text-align:center;"
        >
          <Column field="language" header="Idioma"></Column>
          <Column
            field="proficience"
            header="Nível de Proficiência"
            style="text-align: start"
          ></Column>
          <Column header="Ação">
            <template #body="slotProps">
              <Button
                icon="pi pi-file-edit"
                size="small"
                class="text-white p-1.5 rounded-md bg-green-600 mr-3"
              />
              <Button
                @click="deleteLanguage(slotProps.data.language)"
                icon="pi pi-trash"
                size="small"
                class="text-white p-1.5 rounded-md bg-red-600"
              />
            </template>
          </Column>
        </DataTable>
        <template #footer>
          <Button
            v-if="applicantForm.languages.length"
            @click="visible = false"
            label="Concluir"
            icon="pi pi-check"
            class="text-white px-4 py-1.5 mt-5 rounded-md bg-sea-serpent"
          />
        </template>
      </Dialog>
    </div>
    <BaseToastComponent summary="Este é um teste" severity="danger" />
  </div>
</template>
<script setup>
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import Steps from "primevue/steps";
import TextArea from "primevue/textarea";
import { onMounted, reactive, ref } from "vue";

import BaseToastComponent from "@/components/BaseToastComponent.vue";
import useFetchData from "@/composables/fetchData.js";

// ############ Declaração de variáveis ############

const visible = ref(false);

const items = reactive([
  {
    label: "Personal Info",
  },
  {
    label: "Reservation",
  },
  {
    label: "Review",
  },
]);

let languagesList = reactive([
  { name: "Portugês" },
  { name: "Inglês" },
  { name: "Italiano" },
  { name: "Espanhol" },
  { name: "Turco" },
]);

let languagesLevel = reactive([
  { name: "A1" },
  { name: "A2" },
  { name: "B1" },
  { name: "B2" },
  { name: "C1" },
]);

let counties = reactive([
  { name: "Espanhã" },
  { name: "Angola" },
  { name: "Suila" },
  { name: "Portugal" },
  { name: "Alemanhã" },
]);

let applicantForm = reactive({
  title: "",
  jobFunction: "",
  offer: "",
  requirements: "",
  minSalary: 0,
  maxSalary: 0,
  location: "",
  languages: [],
  seniority: "",
  address: "",
});

let selected = reactive({
  proficience: "",
  language: "",
});

// const isFetching = ref();
let jobStatus = 1;

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############

// Adiciona os idiomas e os seus respectivos níveis de proficiências no Array de "applicantForm.languages"
function spokenLanguages() {
  const languagesIndex =
    applicantForm.languages.findIndex(
      (item) => item.language == selected.language.name
    ) != -1;

  //Verifica se o idioma já está na lista dos idiomas escolhido, caso não estiver, adiciona à lista
  if (!languagesIndex) {
    applicantForm.languages.push({
      id: 3,
      language: selected.language.name,
      proficience: selected.proficience.name,
    });
  }
}

// async function createCurriculo() {
//   isFetching.value = true;

//   await useFetchData
//     .post("/profissional/new", {
//       applicantForm,
//     })
//     .then((response) => {
//       console.log("Passou: ", response);
//     })
//     .catch((error) => {
//       console.log("erro: ", error);
//     })
//     .finally(async function () {
//       isFetching.value = false;
//     });
// }

async function deleteLanguage(languageId) {
  const deleteLangIndex = applicantForm.languages.findIndex(
    (item) => item.language == languageId
  );

  console.log("emilinar: ", languageId + " " + deleteLangIndex);

  // Eliminar se o idioma existir
  if (deleteLangIndex > -1) {
    await useFetchData
      .delete(`/language/delete/${languageId}`)
      .then((response) => {
        response;
        //logica
      })
      .catch((error) => {
        error;
        // logica
      })
      .finally(async function () {
        applicantForm.languages.splice(deleteLangIndex, 1);
        console.log("Idioma " + languageId + " emilinado com sucesso");
        // isFetching.value = false;
      });
  }
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onMounted(() => {});
</script>
