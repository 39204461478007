
<template>
    <div class="card flex justify-center max-z-index">
        <Toast />
        <div class="flex flex-wrap gap-2">
            <Button @click="showSticky" label="Sticky" />
            <Button label="Clear" severity="secondary" @click="clear()" />
        </div>
    </div>
</template>

<script setup>
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import { onMounted, watch } from "vue";
const toast = useToast();

// const severityTypes = ['success', 'info', 'warn', 'danger', 'secondary', 'contrast'];
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
        default: false
    },
    message: {
        type: String,
        required: true,
        default: ''
    },
    messageTitle: {
        type: String,
        required: false,
        default: process.env.APP_NAME
    },
    severity: {
        type: String,
        required: false,
        default: 'info'
    }
});

/**
 * Call alert close
 */
onMounted(function(){
    show();
})

/**
 * Watches changes at props.show in order to close if alert is oppened by defined time
 */
watch(props.show, async (newVal) => {
    if (newVal) {
        show();
    }
});

const show = () => {
    toast.add({ severity: props.severity, summary: props.messageTitle, detail: props.message});
};
</script>

<style scoped>
    .max-z-index{
        z-index: 1000000000px;
    }
</style>