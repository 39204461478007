<template>
    <Dialog 
        v-model:visible="visible" 
        modal 
        :header="translate('loading', 'ucfirst') + '...'" 
        :style="{ width: '25rem' }"
        :closable="false"
    >
        <ProgressSpinner 
            :style="{'width': '50px','height': '50px'}"
            strokeWidth="8" 
            fill="transparent" 
            animationDuration=".9s"
            aria-label="Custom ProgressSpinner"
        />
    </Dialog>
</template>

<script setup>
    import Dialog from "primevue/dialog";
    import ProgressSpinner from "primevue/progressspinner";
    import { translate } from "@/composables/translate";
    import {  ref } from "vue";

    let visible = ref(false);
</script>
<style >
    .p-progress-spinner-circle{
        stroke: rgb(83 195 196)!important;
    }
</style>

