import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const commonCurrencies = {
    async listCommonCurrencies() {
        try {
            let commonCurrencyCombo = store.getters.getOnComboChest('common_currency');
            if(commonCurrencyCombo !== null && commonCurrencyCombo !== undefined && !utils.isEmpty(commonCurrencyCombo))
                return commonCurrencyCombo;
            let currenciesFileData = await requestHandler.fetchOnPublicData('currencies.json');
            if(currenciesFileData){
                let results = this.orderByMainCurrencies(currenciesFileData);
                store.dispatch('saveOnComboChest', {
                    key: 'common_currency',
                    value: results
                });
                return results
            }
            const result = await requestHandler.executeRequest('api/common_currency/index', {}, 'get');
            let results = this.orderByMainCurrencies(result);
            store.dispatch('saveOnComboChest', {
                key: 'common_currency',
                value: results
            });
            return results;
        } catch (error) {
            return [`Error: ${error}`]
        }
    },

    orderByMainCurrencies(currencies = []){
        let allResults = [];
        let orderSequece = ['€','$','R$','CHF','£'];
        let marker = 0;
        for(let currency of currencies){
            if(orderSequece.includes(currency['currency_symbol'])){
                allResults.push(currency);
                marker++;
            }
            if(marker == orderSequece.length)
                break;
        }
        for(let currency of currencies){
            if(orderSequece.includes(currency['currency_symbol']))
                continue;
            allResults.push(currency);
        }
        return allResults;
    }
}

export default commonCurrencies;