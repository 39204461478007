<template>
  <div>
    <aside
      class="sticky top-24 h-[calc(100vh-theme(spacing.48))] pb-28 md:ml-6 pr-5 border-r-2 border-color-iron overflow-y-auto"
    >
      <h1 class="text-2xl mb-6 font-bold text-oxford-blue">{{ translate('filters', 'ucfirst') }}</h1>
      <div class="mb-5">
        <h1 class="mb-3 font-semibold text-lg text-oxford-blue">{{ translate('type of job', 'ucfirst') }}</h1>
        <ul class="flex flex-col gap-2">
          <li
            v-for="(type, index) in types"
            :key="index + '-' + type.id"
            class="flex align-items-center gap-3"
          >
            <Checkbox v-model="selected.types" :inputId="type.id" :value="type.id" />
            <label :for="type.id">{{ type.name }}</label>
          </li>
        </ul>
      </div>
      <div class="mb-5">
        <h1 class="mb-3 font-semibold text-lg text-oxford-blue">{{ translate('seniority level', 'ucfirst') }}</h1>
        <ul class="flex flex-col gap-2">
          <li
            v-for="(seniority, index) in seniorities"
            :key="index + '-' + seniority.id"
            class="flex align-items-center gap-3"
          >
            <Checkbox
              v-model="selected.seniorities"
              :inputId="seniority.id"
              :value="seniority.id"
            />
            <label :for="seniority.id">{{ seniority.name }}</label>
          </li>
        </ul>
      </div>
      <div class="mb-5">
        <h1 class="mb-3 font-semibold text-lg text-oxford-blue">{{ translate('languages', 'ucfirst') }}</h1>
        <ul class="flex flex-col gap-2">
          <li
            v-for="(idiom, index) in idioms"
            :key="index + '-' + idiom.id"
            class="flex align-items-center gap-3"
          >
            <Checkbox v-model="selected.idioms" :inputId="idiom.id" :value="idiom.id" />
            <label :for="idiom.id">{{ idiom.name }}</label>
          </li>
        </ul>
      </div>
      <div class="mb-5">
        <h1 class="mb-3 font-semibold text-lg text-oxford-blue">{{ translate('wage', 'ucfirst') }}</h1>
        <ul>
          <li>
            <div class="w-full flex justify-between mb-3">
              <span>${{ salaryRange[0] }}</span>
              <span>${{ salaryRange[1] }}</span>
            </div>
            <Slider @change="sendSalary" v-model="salaryRange" range class="w-full" />
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script setup>
import { reactive, watch } from "vue";
import Slider from "primevue/slider";
import Checkbox from "primevue/checkbox";
import { translate } from "@/composables/translate";

// ############ Emits e Props ############

const emit = defineEmits(["filters", "filterBySalary"]);

// ############ Declaração de variáveis ############

const salaryRange = reactive([40, 8000]);

const selected = reactive({
  types: [],
  seniorities: [],
  idioms: [],
});

const types = reactive([
  { name: "Full-Time", id: "FT" },
  { name: "Part-Time", id: "PT" },
  { name: "Remoto", id: "RT" },
  { name: "Contrato", id: "CT" },
  { name: "Treinamento", id: "TT" },
]);

const seniorities = reactive([
  { name: "Estudante", id: "st" },
  { name: "Júnior", id: "jn" },
  { name: "Médio", id: "md" },
  { name: "Sénior", id: "sn" },
  { name: "Diretor", id: "dt" },
]);

const idioms = reactive([
  { name: "Portugês", id: "pt" },
  { name: "English", id: "en" },
  { name: "Française", id: "fr" },
  { name: "Espãnol", id: "es" },
  { name: "Italian", id: "it" },
]);

// ############ Chamando componentes e composables ############

// ############ Watch ############

watch(
  () => selected,
  () => {
    emit("filters", selected);
  },
  { deep: true }
);

function sendSalary() {
  emit("filterBySalary", salaryRange);
}
</script>

<style scoped></style>
