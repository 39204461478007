import axios from 'axios';
import store from '@/store';
import router from "@/router";
import { translate } from './translate';
import authUser from './authUser';
import Cookies from 'js-cookie';

const requestHandler = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json"
    },
    withCredentials: true,
    withXSRFToken: true
});

requestHandler.interceptors.request.use(
    config => {
        return config;
      },
      error => {
        return Promise.reject(error);
    }
);

requestHandler.interceptors.response.use(
    response => response,
    error => {
        if (error.response == undefined) {
            return {
                success: false,
                data: {},
                message: translate('a problem occurred with your application, but do not worry, we are already looking into it', 'ucfirst'),
                status: 900,
            };
        }else{
            throw error;
        }
    }
);

/**
 * Functions
 */
requestHandler.baseUrl = function getBaseUrl(){
    return requestHandler.defaults.baseURL;
}
requestHandler.makeUrl = function makeUrl(uri){
    return requestHandler.defaults.baseURL + uri;
}
/**
 * Perform an AXIOS request
 * @param {String} url 
 * @param {Object} parameters 
 * @param {String} method - 'post', 'get', 'put' or 'delete'
 * @returns {
 *  success: Boolean,
 *  data: requestResult
 *  status: integer
 * }
 */
requestHandler.executeRequest = async function executeRequest(url, parameters = {}, method = 'post'){
    let resultFormat = {
        success: true,
        data: {},
        message: '',
        status: 200,
    };
    parameters['user_lang'] = store.getters.getUserLang;
    let parametersAsArray = Object.keys(parameters);
    try {
        let response = null;
        switch(method){
            case 'post':
                response = await this.post(url, parameters);
            break;
            case 'get':
                if(parametersAsArray.length > 0){
                    url += '?';
                    for(let key of parametersAsArray){
                    if(Array.isArray(parameters[key])){
                        let thisIndex = 0;
                        for(let paramsArr of parameters[key]){
                        url += `${key}[${thisIndex}]=${paramsArr}&`;
                        thisIndex++;
                        }
                    }else{
                        url += key + '=' + parameters[key] + '&';
                    }
                    }
                    url = url.slice(0, -1);
                }
                response = await this.get(url, parameters);
            break;
            case 'put':
                response = await this.put(url, parameters);
            break;
            case 'delete':
                response = await this.delete(url, parameters);
            break;
        }
        if(response.status == 900){
            store.dispatch('saveSessionMessage', {
                message: translate('a problem occurred with your application, but do not worry, we are already looking into it', 'ucfirst'), 
                messageType: 'error'
            });
            await this.redirect('/maintance');
            return {
                success: false,
                data: {},
                message: 'error',
                status: 900,
            };
        }
        resultFormat.data = response.data;
        resultFormat.status = response.status;
        if(response.data.message !== undefined)
            resultFormat.message = response.data.message;
        return resultFormat;
    } catch (error) {
        const defaultErrorMessage = translate('unexpected error, please try again later', 'ucfirst');
        resultFormat.success = false;
        resultFormat.data = "";
        resultFormat.status = 500;
        if(error !== undefined && error.response !== undefined){
            resultFormat.data = error.response.data;
            resultFormat.status = error.response.status;
            if(error.response.data.message !== undefined)
                resultFormat.message = error.response.data.message;
            if(resultFormat.status == 419){
                store.dispatch('saveSessionMessage', {message: translate('login expired, perform login again please'), messageType: 'error'});
                await this.redirect('/login');
            }
        }
        if(resultFormat.message == '')
            resultFormat.message = defaultErrorMessage;
        if(resultFormat.status == 401 && resultFormat.data.message != undefined && resultFormat.data.message == 'Unauthenticated.'){
            if(store.getters.isLoggedIn){
                store.dispatch('saveSessionMessage', {message: translate('authentication expired'), messageType: 'error'});
                await authUser.logout(true);
                await this.redirect('/login');
            }else{
                Cookies.remove('XSRF-TOKEN');
                Cookies.remove('jobifull_session');
                Cookies.remove('last_login_as');
                store.dispatch('cleanAuthData');
            }
        }
        return resultFormat;
    }
}
requestHandler.redirect = async function goTo(url = '/'){
    router.push({path: url});
}
requestHandler.redirectByProfile = async function redirectByProfile(){
    const routeToGo = store.getters.isLoggedIn && store.getters.isOnlyPersonProfile ? '/dashboard/profile' : '/dashboard/' + store.getters.getLoggedAs + '/' + store.getters.getUserSlug;
    requestHandler.redirect(routeToGo);
}
requestHandler.fetchOnPublicData = async function fetchOnPublicData(url = ''){
    if(url == '')
        return false;
    let gatheredData = false;
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `/data/${url}`, false);
        xhr.send();
        if (xhr.status === 200) {
            gatheredData = JSON.parse(xhr.responseText);
        }
    } catch (error) {
        return gatheredData;
    }
    return gatheredData;
}

export default requestHandler;