import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const workingVisas = {
    async listWorkingVisas() {
        try {
            let workingVisaCombo = store.getters.getOnComboChest('working_visa');
            if(workingVisaCombo !== null && workingVisaCombo !== undefined && !utils.isEmpty(workingVisaCombo))
                return workingVisaCombo;
            const result = await requestHandler.executeRequest('api/working_visa/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'working_visa',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default workingVisas;