import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/App.css' 
import PrimeVue from 'primevue/config';    
import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primeicons/primeicons.css'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice'; 
import Tooltip from 'primevue/tooltip'
import VueGtag from 'vue-gtag';
import { env } from "@/composables/config";

const app = createApp(App)
app.use(ToastService);
app.use(ConfirmationService); 
app.use(PrimeVue, {
    ripple: true
})
app.use(ToastService);
app.use(store)
app.use(VueGtag, {
    config: { id: env('APP_GOOGLE_ANALYTICS_ID') }
}, router);
app.use(router)
app.config.globalProperties.$axios = axios
app.directive('tooltip', Tooltip);
app.mount('#app')
