<template>
  <div>
    <!-- Cabeçalho -->
    <section class="pt-28 2xl:pt-36 pb-12 text-center bg-oxford-blue">
      <h1 class="text-3xl md:text-5xl text-white font-bold mb-10">
        Busca o profissional ideal <br />
        para sua empresa
      </h1>
    </section>

    <!-- Formulários de pesquisa -->
    <section
      class="hidden w-3/4 mx-auto bg-white rounded-lg shadow-md py-4 px-3 md:-mt-10"
    >
      <form @submit.prevent="searchOffers" class="flex">
        <InputGroup class="w-full">
          <InputGroupAddon>
            <i class="pi pi-search"></i>
          </InputGroupAddon>
          <InputText
            placeholder="Área de formação"
            class="w-full outline-none py-2 px-3"
          />
        </InputGroup>
        <InputGroup class="w-full">
          <InputGroupAddon>
            <i class="pi pi-map"></i>
          </InputGroupAddon>
          <InputText placeholder="Localização" class="w-full outline-none py-2 px-3" />
        </InputGroup>
        <InputGroup class="w-full">
          <InputGroupAddon>
            <i class="pi pi-sort-alpha-up"></i>
          </InputGroupAddon>
          <MultiSelect
            v-model="selectedCategories"
            :options="groupedCategories"
            optionLabel="name"
            :maxSelectedLabels="4"
            placeholder="Todas categorias"
            class="w-full outline-none"
          >
          </MultiSelect>
        </InputGroup>
        <Button
          type="submit"
          label="Buscar"
          class="ml-3 py-1 pl-3 pr-12 text-white rounded-md bg-sea-serpent"
        />
      </form>
    </section>

    <!-- seção principal -->
    <section class="flex py-20 md:pl-6 relative">
      <!-- Barra lateral de filtros  -->
      <SidebarProfessionals
        @filters="filtersTypes"
        @filter-by-salary="filterBySalary"
        class="hidden md:flex"
      />

      <!-- Lista dos profissionais -->
      <div class="px-5 xs:px-10 md:px-4 lg:px-6">
        <div
          class="flex flex-wrap md:flex-nowrap justify-between items-center xl:px-4 mb-8 md:mb-6"
        >
          <p class="text-lg">Mostrando <strong>3.479</strong> resultados</p>
          <div class="w-full md:w-auto">
            Organizar por:
            <Dropdown
              v-model="selectedSort"
              :options="optionsSort"
              optionLabel="name"
              optionValue="code"
              placeholder="Selecionar"
              class="w-full md:w-44"
            />
          </div>
        </div>

        <div class="flex flex-wrap justify-center gap-5 lg:gap-6">
          <!-- card profissionais -->
          <Card
            v-for="(professional, index) in professionals.data"
            :key="index"
            class="w-full md:w-[48%] lg:w-[31%] xl:w-[23%] text-center overflow-hidden"
          >
            <template #header>
              <img
                alt="user header"
                :src="professional.professional_cover"
                class="h-24 z-0"
              />
            </template>
            <template #title>
              <div
                alt="user"
                :style="{ backgroundImage: `url(${professional.professional_photo})` }"
                class="w-28 h-28 z-10 -mt-16 mx-auto rounded-full border-4 border-white profile-photo"
              ></div>

              <h1 class="mt-2.5 font-semibold text-xl text-oxford-blue">
                {{ professional.professional_firstname }}
                {{ professional.professional_lastname }}
              </h1>
              <h1 class="capitalize font-medium text-base">
                {{ professional.professional_title }}
              </h1>
            </template>
            <template #footer>
              <div class="w-full pt-2 -mb-3 border-t border-color-iron">
                <RouterLink
                  :to="`/applicants/${professional.professional_slug}`"
                  class="font-semibold text-oxford-blue"
                  >Ver Pefil</RouterLink
                >
              </div>
            </template>
          </Card>
        </div>

        <!-- Paginação -->
        <div class="w-full mt-9">
          <Paginator
            :rows="10"
            :totalRecords="120"
            :rowsPerPageOptions="[10, 20, 30]"
          ></Paginator>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import { RouterLink } from "vue-router";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Paginator from "primevue/paginator";
import InputText from "primevue/inputtext";

import useFetchData from "@/composables/fetchData.js";
import SidebarProfessionals from "@/components/Filters/SidebarProfessionals";

// ############ Declaração de variáveis ###########

const filters = reactive({
  salary: 8000,
  types: [],
  seniorities: [],
  idioms: [],
});

const professionals = ref([]);
const errors = ref([]);

const selectedCategories = reactive([]);
const groupedCategories = reactive([
  { name: "Tecnologia", id: "tn" },
  { name: "Saúde", id: "su" },
  { name: "Marketing Digital", id: "md" },
  { name: "Construção", id: "ct" },
  { name: "Gestão", id: "gt" },
  { name: "Esporte", id: "ep" },
]);

const selectedSort = ref("");
const optionsSort = ref([
  { name: "Mais recente", code: "mr" },
  { name: "Mais Antigo", code: "ma" },
  { name: "Salãrio Alto", code: "sa" },
  { name: "Salário Baixo", code: "sb" },
]);
// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############

// function searchOffers() {}

async function getApplicants() {
  await useFetchData
    .get("/professional/index")
    .then((response) => {
      professionals.value = response.data;
    })
    .catch((error) => {
      errors.value = error;
    });
}

function filterBySalary() {}

function filtersTypes() {
  filters.salary = 8000;
}

//############ Propriedades Computadas ############

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############

onBeforeMount(() => {
  getApplicants();
});
</script>

<style scoped>
.profile-photo {
  background-image: url("@/assets/images/img-cover.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
