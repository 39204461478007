const utils = {
    isEmpty(value){
        if(value === null)
            return true;
        let length = 0;
        let typeOf = typeof value;
        if(typeOf === 'object'){
            length = Object.keys(value).length;
        }else if(typeOf === 'array'){
            length = value.length;
        }else if(typeOf === 'string'){
            length = value.length; 
        }else{
            return true;
        }
        return length > 0 ? false : true;
    },

    parseToArray(value){
        let valueType = typeof value;
        let response = [];
        switch(valueType){
            case 'string':
                response.push(value);
            break;
            case 'object':
                response = Object.values(value);
            break;
            case 'array':
                response = [];
            break;
        }
        return response;
    },

    areObjectsEqual(obj1, obj2, ignoreKeys = []){
        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return false;
        }
        const keys1 = Object.keys(obj1).filter(key => !ignoreKeys.includes(key));
        const keys2 = Object.keys(obj2).filter(key => !ignoreKeys.includes(key));
        if (keys1.length !== keys2.length) return false;
        for (const key of keys1) {
            if (!keys2.includes(key) || !this.areObjectsEqual(obj1[key], obj2[key], ignoreKeys)) {
            return false;
            }
        }

        return true;
    },

    copyVariable(variableData = ''){
        return JSON.parse(JSON.stringify(variableData));
    },

    isInArray(array = [], value = '', watchKey = false){
        if(watchKey){
            return array.some(item => item[watchKey] === value);
        }else{
            return array.some(item => item === value);
        }
    }
}

export default utils;