<template>
  <div>
    <section class="flex justify-center items-center px-4 xs:px-12 pt-28 bg-oxford-blue">
      <img
        src="../assets/images/homem-com-a-barba-por-fazer-atordoado-1.png"
        class="w-44 xs:w-52 lg:w-60 lg:-ml-16"
      />
      <h1
        class="text-2xl xs:text-3xl md:text-4xl lg:text-5xl leading-7 lg:leading-[1.1] text-white font-bold -ml-6 lg:-ml-8 mt-5"
      >
        {{ translate('choose your plan and', 'ucfirst') }}<br class="hidden md:flex" />
        {{ translate('begin your journey') }}<br class="hidden md:flex" />
        {{ translate('with') }} {{ env('APP_NAME') }}
      </h1>
    </section>

    <section class="mb-20 mt-24 lg:mt-32">
      <!-- Tab das opções -->
      <div class="flex justify-center mb-8 md:mb-14">
        <span class="p-buttonset shadow-md rounded-md bg-gray-300">
          <Button
            @click="togglePlans('prof')"
            :label="translate('professionals', 'ucfirst')"
            :class="activePlan ? 'bg-white' : ''"
            class="px-4 py-2 rounded-md classTeste border border-color-iron"
          />
          <Button
            @click="togglePlans('recrut')"
            :label="translate('recruiters', 'ucfirst')"
            :class="!activePlan ? 'bg-white' : ''"
            class="px-4 py-2 rounded-md border border-color-iron"
          />
        </span>
      </div>

      <!-- Conteúdo da Tab Profissionais  -->
      <article
        v-show="activePlan"
        class="flex flex-wrap xs:flex-nowrap px-5 justify-center xs:gap-x-5 md:gap-x-10"
      >
        <Card
          :unstyled="true"
          class="w-full md:w-72 bg-white rounded-xl shadow-xl mb-6 py-5 px-6 overflow-hidden"
        >
          <template #header>
            <div class="text-center mt-5">
              <h3 class="text-lg font-medium mb-1 text-sea-serpent">{{ translate('basic', 'ucfirst') }}</h3>
              <h1 class="uppercase text-xl font-semibold text-oxford-blue">{{ translate('free', 'ucfirst') }}</h1>
              <small class="font-semibold">{{ translate('forever', 'ucfirst') }}</small>
            </div>
          </template>

          <template #content>
            <ul class="flex3 mt-6 mb-11 text-oxford-blue">
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-times" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
            </ul>
          </template>

          <template #footer>
            <RouterLink
              to="/"
              class="text-white text-center font-medium block mt-auto mb-3 px-4 py-2 rounded-lg bg-sea-serpent"
              >{{ translate('sign up now', 'ucfirst') }}</RouterLink
            >
          </template>
        </Card>

        <Card
          :unstyled="true"
          class="w-full md:w-72 bg-white rounded-xl shadow-xl mb-6 py-5 px-6 relative overflow-hidden"
        >
          <template #header>
            <div class="text-center mt-5">
              <h3 class="text-lg font-medium mb-1 text-sea-serpent">{{ translate('premium', 'ucfirst') }}</h3>
              <div class="flex justify-center items-center text-oxford-blue">
                <i
                  class="pi pi-euro mr-1.5"
                  style="color: #0b1c47; font-size: 0.95rem"
                ></i>
                <h1 class="uppercase text-xl font-semibold">1,00/</h1>
                <sub class="text-base font-normal">{{ translate('month', 'ucfirst') }}</sub>
              </div>
              <small class="font-semibold">{{ translate('16% off for 12 months', 'ucfirst') }}</small>
            </div>
            <p
              class="w-3/4 text-center text-white font-medium absolute top-6 -right-16 rotate-[43deg] pt-2 pb-1.5 bg-sea-serpent"
            >
              {{ translate('most popular', 'ucfirst') }}
            </p>
          </template>

          <template #content>
            <ul class="flex3 mt-6 mb-11 text-oxford-blue">
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-times" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
            </ul>
          </template>

          <template #footer>
            <RouterLink
              to="/"
              class="text-white text-center font-medium block mx-auto mb-3 px-4 py-2 rounded-lg bg-sea-serpent"
              >{{ translate('sign up now', 'ucfirst') }}</RouterLink
            >
          </template>
        </Card>
      </article>

      <!-- Conteúdo da Tab recrutadores -->
      <article
        v-show="!activePlan"
        class="flex flex-wrap xs:flex-nowrap px-5 justify-center xs:gap-x-5 md:gap-x-10"
      >
        <Card
          :unstyled="true"
          class="w-full md:w-72 bg-white rounded-xl shadow-xl mb-6 py-5 px-6 overflow-hidden"
        >
          <template #header>
            <div class="text-center mt-5">
              <h3 class="text-lg font-medium mb-1 text-sea-serpent">{{ translate('basic', 'ucfirst') }}</h3>
              <h1 class="uppercase text-xl font-semibold text-oxford-blue">{{ translate('free', 'ucfirst') }}</h1>
              <small class="font-semibold">{{ translate('forever', 'ucfirst') }}</small>
            </div>
          </template>

          <template #content>
            <ul class="flex3 mt-6 mb-11 text-oxford-blue">
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-times" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from free plan', 'ucfirst') }}</p>
              </li>
            </ul>
          </template>

          <template #footer>
            <RouterLink
              to="/"
              class="text-white text-center font-medium block mt-auto mb-3 px-4 py-2 rounded-lg bg-sea-serpent"
              >{{ translate('sign up now', 'ucfirst') }}</RouterLink
            >
          </template>
        </Card>

        <Card
          :unstyled="true"
          class="w-full md:w-72 bg-white rounded-xl shadow-xl mb-6 py-5 px-6 relative overflow-hidden"
        >
          <template #header>
            <div class="text-center mt-5">
              <h3 class="text-lg font-medium mb-1 text-sea-serpent">{{ translate('premium', 'ucfirst') }}</h3>
              <div class="flex justify-center items-center text-oxford-blue">
                <i
                  class="pi pi-euro mr-1.5"
                  style="color: #0b1c47; font-size: 0.95rem"
                ></i>
                <h1 class="uppercase text-xl font-semibold">2,00/</h1>
                <sub class="text-base font-normal">{{ translate('month', 'ucfirst') }}</sub>
              </div>
              <small class="font-semibold">{{ translate('20% off for 12 months', 'ucfirst') }}</small>
            </div>
            <p
              class="w-3/4 text-center text-white font-medium absolute top-6 -right-16 rotate-[43deg] pt-2 pb-1.5 bg-sea-serpent"
            >
              {{ translate('most popular', 'ucfirst') }}
            </p>
          </template>

          <template #content>
            <ul class="flex3 mt-6 mb-11 text-oxford-blue">
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-check" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
              <li class="flex justify-center items-center mt-3 gap-x-3">
                <i class="pi pi-times" style="color: #53c3c4; font-size: 1rem"></i>
                <p class="font-medium">{{ translate('item from premium plan', 'ucfirst') }}</p>
              </li>
            </ul>
          </template>

          <template #footer>
            <RouterLink
              to="/"
              class="text-white text-center font-medium block mx-auto mb-3 px-4 py-2 rounded-lg bg-sea-serpent"
              >{{ translate('sign up now', 'ucfirst') }}</RouterLink
            >
          </template>
        </Card>
      </article>

      <div class="w-full py-16 bg-white px-5 mt-24 lg:mt-32">
        <h1 class="base-title mb-8 lg:mb-12 text-center">
          {{ translate('characteristics of our plans', 'ucfirst') }}
        </h1>
        <DataTable
          :value="features"
          stripedRows
          tableClass="w-full md:w-5/6 lg:w-3/4 md:mx-auto bg-white rounded-lg shadow-lg my-3 4overflow-hidden border border-color-iron"
        >
          <Column field="desc" :header="translate('description', 'ucfirst')"></Column>
          <Column field="free" :header="translate('free', 'ucfirst')"></Column>
          <Column field="pay" :header="translate('premium', 'ucfirst')"></Column>
        </DataTable>
      </div>
    </section>

    <!-- Seção perguntas frequentes -->
    <section class="w-full md:w-3/4 container mx-auto px-5 mb-32 mt-24 lg:mt-32">
      <h1 class="base-title pb-12 text-center">{{ translate('frequent asked questions', 'ucfirst') }}</h1>
      <Accordion
        :multiple="true"
        :activeIndex="[0]"
        class="shadow-lg rounded-lg 5border border-color-iron"
      >
        <AccordionTab :header="translate('how does the Jobifull subscription works?', 'ucfirst')">
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
            in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </AccordionTab>
        <AccordionTab :header="translate('why do I need a plan if Jobifull is free?', 'ucfirst')">
          <p class="m-0">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
            ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
            consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
            Consectetur, adipisci velit, sed quia non numquam eius modi.
          </p>
        </AccordionTab>
        <AccordionTab :header="translate('can I cancel at any moment?', 'ucfirst')">
          <p class="m-0">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
            praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
            excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
            officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
            quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
            soluta nobis est eligendi optio cumque nihil impedit quo minus.
          </p>
        </AccordionTab>
      </Accordion>
    </section>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { RouterLink } from "vue-router";
import Button from "primevue/button";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { translate } from "@/composables/translate";
import { env } from "@/composables/config";
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";

// ############ Declaração de variáveis ############

const activePlan = ref(true);

const features = reactive([
  { desc: translate("featured on home", 'ucfirst'), free: translate('limited', 'ucfirst'), pay: translate('unlimited', 'ucfirst') },
  { desc: translate("multiple languages", 'ucfirst'), free: "5", pay: translate('unlimited', 'ucfirst') },
  { desc: translate("curriculumns in multiple languages", 'ucfirst'), free: translate('limited', 'ucfirst'), pay: translate('unlimited', 'ucfirst') },
]);

// ############ Criação de Métodos/Funções ############

// Faz a mudança entre as abas dos planos
function togglePlans(tab) {
  activePlan.value = tab == "recrut" ? false : true;
}
</script>
