import store from '@/store';
import countries from './country';
import languages from './languageData';
import Cookies from 'js-cookie';
import { decrypte, encrypte } from './crypto';

const ipHandler = {
    async detectIp() {
        if(store.getters.harvestedIpInformation || store.getters.alreadyChangedLanguageByIp)
            return;
        if(![null,'',undefined].includes(Cookies.get('userLang'))){
            this.changeLanguage(
                decrypte(Cookies.get('userLang')),
                decrypte(Cookies.get('langName'))
            );
            store.dispatch('setAlreadyChangedLanguageByIp', true);
            return;
        }
        try {
          const ipResponse = await fetch('https://api.ipify.org?format=json');
          const ipData = await ipResponse.json();
          const geoResponse = await fetch(`https://ipinfo.io/${ipData.ip}/json`);
          const geoData = await geoResponse.json();
          let dataToStore = {
            query: geoData.ip != undefined ? geoData.ip : '',
            countryCode: geoData.country != undefined ? geoData.country.toLowerCase() : '',
            additionalInfo: geoData.additionalInfo != undefined ? geoData : {}
          }
          store.dispatch('saveIpInformation', dataToStore);
          this.setUserLang(dataToStore.countryCode);
        } catch (error) {
          console.error('error detecting ip', error);
        }
    },

    async setUserLang(countryIso = ''){
        let userLang = languages.getDefaultLanguageIsoCode();
        if(countryIso != ''){
            let officialLangs = languages.getOfficialLanguagesIsoCodes();
            let countryData = await countries.listCountries(true);
            for(let country of countryData){
                if(country['lcountry_acronyn'] == undefined || country['spokenLanguages'] == undefined)
                    continue;
                if(countryIso == country['lcountry_acronyn']){
                    for(let langIsoCode of JSON.parse(country['spokenLanguages'])){
                        if(officialLangs.includes(langIsoCode)){
                            userLang = langIsoCode;
                        }
                    }
                    break;
                }
            }
        }
        let langName = await languages.getLangNameByLanguageIso(userLang);
        store.dispatch('setAlreadyChangedLanguageByIp', true);
        this.changeLanguage(userLang, langName);
    },
    
    changeLanguage(isoCode = '', langName = ''){
        if(isoCode == '' || langName == '')
            return;
        store.dispatch('changeLanguage', {langIso: isoCode, langName: langName});
        Cookies.set('userLang', encrypte(isoCode));
        Cookies.set('langName', encrypte(langName));
        store.dispatch('cleanDataChest', {});
        store.dispatch('cleanComboChest', {});
    }
}
export default ipHandler;
