import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const jobPayingType = {
    async listjobPayingType() {
        try {
            let jobPayingTypeCombo = store.getters.getOnComboChest('job_payment_type');
            if(jobPayingTypeCombo !== null && jobPayingTypeCombo !== undefined && !utils.isEmpty(jobPayingTypeCombo))
                return jobPayingTypeCombo;
            const result = await requestHandler.executeRequest('api/job_payment_type/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'job_payment_type',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default jobPayingType;