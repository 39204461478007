import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const jobCertifications = {
    async listjobCertifications() {
        try {
            let jobCertificationCombo = store.getters.getOnComboChest('job_certification');
            if(jobCertificationCombo !== null && jobCertificationCombo !== undefined && !utils.isEmpty(jobCertificationCombo))
                return jobCertificationCombo;
            const result = await requestHandler.executeRequest('api/job_certification/index', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'job_certification',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default jobCertifications;