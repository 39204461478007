import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import { sleep } from './sleep';

const routeHandler = {
    async reloadThisPage() {
        store.dispatch('saveExpectedRoute', window.location.pathname);
        await requestHandler.redirect('/');
        sleep(200);
        this.checkExpectedRoute();
    },

    hasExpectedRoute(){
        return store.getters.getExpectedRoute != null ? true : false;
    },

    async toExpectedRoute() {
        await requestHandler.redirect(store.getters.getExpectedRoute);
        store.dispatch('saveExpectedRoute', null);
    },

    async checkExpectedRoute(){
        if(!this.hasExpectedRoute())
            return false;
        await this.toExpectedRoute();
        return true;
    },

    async saveCurrentRoute(route = null){
        let currRoute = route != null ? route : window.location.pathname;
        if(currRoute == '/login')
            return;
        store.dispatch('saveExpectedRoute', currRoute);
    }

}

export default routeHandler;